/* Create a new shared CSS file for booking form styles */
.booking-section {
  padding: 6rem 0;
  background: linear-gradient(to bottom, #fff, #f8f9fa);
}

.booking-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 3rem;
  margin-top: 3rem;
}

.booking-form {
  background: white;
  padding: 2.5rem;
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0,0,0,0.1);
}

.booking-form h3 {
  margin-bottom: 2rem;
  color: #333;
  font-size: 1.8rem;
}

.form-group {
  margin-bottom: 1.5rem;
  position: relative;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 1rem;
  border: 2px solid #e1e1e1;
  border-radius: 10px;
  font-size: 1rem;
  color: #333;
  background-color: white;
  transition: all 0.3s ease;
  appearance: auto; /* Restore native select appearance */
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  border-color: var(--primary-color);
  outline: none;
  box-shadow: 0 0 0 3px rgba(255, 123, 37, 0.1);
}

.form-group select {
  cursor: pointer;
  padding-right: 2.5rem;
}

.form-group select option {
  color: #333;
  padding: 1rem;
}

.form-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.submit-btn {
  width: 100%;
  padding: 1.2rem;
  background: var(--primary-color);
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 1.1rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.submit-btn:hover {
  background: var(--primary-dark);
  transform: translateY(-2px);
}

.quick-contact {
  background: white;
  padding: 2.5rem;
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0,0,0,0.1);
  height: fit-content;
}

.quick-contact h3 {
  margin-bottom: 2rem;
  color: #333;
  font-size: 1.8rem;
}

.whatsapp-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  background: #25D366;
  color: white;
  padding: 1.2rem;
  border-radius: 10px;
  text-decoration: none;
  font-weight: 600;
  margin-bottom: 1.5rem;
  transition: all 0.3s ease;
}

.whatsapp-btn:hover {
  background: #128C7E;
  transform: translateY(-2px);
}

.contact-info p {
  margin: 1rem 0;
  color: #666;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

/* Form Validation Styles */
.form-group input:invalid,
.form-group select:invalid {
  border-color: #ff4444;
}

.form-group .validation-message {
  position: absolute;
  bottom: -20px;
  left: 0;
  color: #ff4444;
  font-size: 0.85rem;
}

/* Custom Select Styling */
.form-group.select-wrapper {
  position: relative;
}

.form-group.select-wrapper::after {
  content: '▼';
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: #666;
  pointer-events: none;
  font-size: 0.8rem;
}

/* Mobile Responsive */
@media (max-width: 768px) {
  .booking-container {
    grid-template-columns: 1fr;
  }

  .form-row {
    grid-template-columns: 1fr;
  }

  .booking-form,
  .quick-contact {
    padding: 1.5rem;
  }

  .form-group input,
  .form-group select,
  .form-group textarea {
    font-size: 16px; /* Prevent zoom on mobile */
  }
}

/* Dark Mode Support */
@media (prefers-color-scheme: dark) {
  .form-group input,
  .form-group select,
  .form-group textarea {
    background-color: #f8f9fa;
  }
} 