.park-gallery {
  padding: 4rem 0;
  background: var(--background-light);
}

.gallery-header {
  text-align: center;
  margin-bottom: 3rem;
}

.gallery-header h2 {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.gallery-header p {
  color: var(--text-light);
  font-size: 1.1rem;
}

.gallery-tabs {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 3rem;
  flex-wrap: wrap;
}

.gallery-tab {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  border: 2px solid transparent;
  background: var(--white);
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
}

.gallery-tab .tab-icon {
  font-size: 1.1rem;
}

.gallery-tab .image-count {
  background: var(--background-light);
  padding: 0.2rem 0.6rem;
  border-radius: 12px;
  font-size: 0.8rem;
  margin-left: 0.5rem;
}

.gallery-tab.active {
  background: var(--primary-color);
  color: var(--white);
}

.gallery-tab.active .image-count {
  background: rgba(255, 255, 255, 0.2);
}

.gallery-masonry {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
  padding: 0 1rem;
}

.gallery-item {
  break-inside: avoid;
  margin-bottom: 1.5rem;
}

.gallery-item.portrait .image-wrapper {
  padding-bottom: 150%;
}

.gallery-item.landscape .image-wrapper {
  padding-bottom: 75%;
}

.image-wrapper {
  position: relative;
  width: 100%;
  border-radius: var(--border-radius);
  overflow: hidden;
  box-shadow: var(--shadow-md);
  transition: transform 0.3s ease;
}

.image-wrapper:hover {
  transform: translateY(-5px);
}

.gallery-item img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.gallery-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to top, rgba(0,0,0,0.8), transparent);
  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  align-items: flex-end;
  padding: 1.5rem;
  color: var(--white);
}

.gallery-item:hover .gallery-overlay {
  opacity: 1;
}

.overlay-content {
  transform: translateY(20px);
  transition: transform 0.3s ease;
}

.gallery-item:hover .overlay-content {
  transform: translateY(0);
}

.image-category {
  display: inline-block;
  background: var(--primary-color);
  color: var(--white);
  padding: 0.25rem 0.75rem;
  border-radius: 15px;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
  text-transform: capitalize;
}

/* Image Viewer Styles */
.image-viewer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.viewer-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.95);
}

.viewer-content {
  position: relative;
  max-width: 90vw;
  max-height: 90vh;
  z-index: 1001;
}

.viewer-image {
  position: relative;
}

.viewer-image img {
  max-width: 90vw;
  max-height: 80vh;
  object-fit: contain;
  border-radius: var(--border-radius);
}

.image-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 2rem;
  background: linear-gradient(transparent, rgba(0,0,0,0.9));
  color: var(--white);
}

.caption-content {
  max-width: 600px;
}

.image-meta {
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
  font-size: 0.9rem;
  opacity: 0.8;
}

/* Navigation Buttons */
.close-button,
.nav-button {
  position: absolute;
  background: rgba(255,255,255,0.1);
  color: var(--white);
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  backdrop-filter: blur(5px);
}

.close-button {
  top: -25px;
  right: -25px;
}

.nav-button {
  top: 50%;
  transform: translateY(-50%);
}

.nav-button.prev {
  left: -80px;
}

.nav-button.next {
  right: -80px;
}

.close-button:hover,
.nav-button:hover {
  background: rgba(255,255,255,0.2);
  transform: scale(1.1);
}

.nav-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  transform: translateY(-50%) scale(1);
}

@media (max-width: 768px) {
  .gallery-masonry {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    padding: 0;
  }
  
  .nav-button {
    width: 40px;
    height: 40px;
  }
  
  .nav-button.prev {
    left: 10px;
  }
  
  .nav-button.next {
    right: 10px;
  }
  
  .close-button {
    top: 10px;
    right: 10px;
  }
} 

.viewer-controls {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 1rem;
  z-index: 1002;
}

.control-button {
  background: rgba(255,255,255,0.1);
  color: var(--white);
  border: none;
  border-radius: 20px;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  backdrop-filter: blur(5px);
}

.control-button:hover {
  background: rgba(255,255,255,0.2);
}

.gallery-masonry {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  padding: 0 1rem;
}

.gallery-item.portrait {
  grid-row: span 2;
}

.gallery-item.landscape {
  grid-column: span 2;
}

@media (max-width: 1024px) {
  .gallery-masonry {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .gallery-masonry {
    grid-template-columns: 1fr;
  }
  
  .gallery-item.landscape {
    grid-column: span 1;
  }
} 