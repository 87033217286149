.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;
  animation: fadeIn 0.3s ease-out;
}

.modal-content {
  background: white;
  border-radius: 16px;
  width: 100%;
  max-width: 1000px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  animation: slideIn 0.3s ease-out;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
}

.modal-content::-webkit-scrollbar {
  width: 8px;
}

.modal-content::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.modal-content::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.modal-content::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.modal-close {
  position: absolute;
  top: 20px;
  right: 20px;
  background: rgba(255, 255, 255, 0.95);
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #333;
  font-size: 1.2rem;
  transition: all 0.3s;
  z-index: 2;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.modal-close:hover {
  background: #fff;
  transform: rotate(90deg);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

.modal-header {
  position: relative;
}

.modal-image {
  position: relative;
  height: 400px;
  overflow: hidden;
}

.modal-image::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 70%;
  background: linear-gradient(to bottom, transparent, rgba(0,0,0,0.8));
  pointer-events: none;
}

.modal-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.modal-categories {
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  z-index: 1;
}

.modal-category {
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 8px 16px;
  border-radius: 25px;
  font-size: 0.9rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.modal-title {
  padding: 30px 40px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: white;
  z-index: 1;
}

.modal-title h2 {
  margin: 0 0 15px 0;
  font-size: 2.5rem;
  font-weight: 700;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  line-height: 1.2;
}

.modal-meta {
  display: flex;
  gap: 25px;
  flex-wrap: wrap;
  font-size: 1rem;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}

.modal-meta span {
  display: flex;
  align-items: center;
  gap: 8px;
  background: rgba(0, 0, 0, 0.6);
  padding: 6px 12px;
  border-radius: 20px;
}

.modal-meta svg {
  font-size: 1.1rem;
}

.modal-body {
  padding: 40px;
  background: #fff;
}

.modal-description {
  margin-bottom: 40px;
}

.modal-description h3,
.modal-highlights h3,
.modal-itinerary h3 {
  color: #222;
  margin-bottom: 20px;
  font-size: 1.8rem;
  font-weight: 600;
  position: relative;
  padding-bottom: 10px;
}

.modal-description h3::after,
.modal-highlights h3::after,
.modal-itinerary h3::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 3px;
  background: #4CAF50;
  border-radius: 2px;
}

.modal-description p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #444;
}

.modal-highlights ul {
  list-style: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
  margin-bottom: 40px;
}

.modal-highlights li {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 15px;
  background: #f8f9fa;
  border-radius: 10px;
  font-size: 1.05rem;
  color: #333;
  border: 1px solid #eee;
  transition: all 0.3s;
}

.modal-highlights li:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.modal-highlights li svg {
  color: #4CAF50;
  font-size: 1.2rem;
  flex-shrink: 0;
}

.itinerary-timeline {
  position: relative;
}

.itinerary-day {
  position: relative;
  padding: 25px;
  margin-bottom: 25px;
  background: #f8f9fa;
  border-radius: 12px;
  border-left: 4px solid #4CAF50;
  transition: all 0.3s;
}

.itinerary-day:hover {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transform: translateX(5px);
}

.day-header {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
}

.day-number {
  background: #4CAF50;
  color: white;
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 0.5px;
  box-shadow: 0 2px 6px rgba(76, 175, 80, 0.3);
}

.day-header h4 {
  margin: 0;
  color: #222;
  font-size: 1.4rem;
  font-weight: 600;
}

.day-description {
  color: #444;
  margin-bottom: 25px;
  font-size: 1.1rem;
  line-height: 1.6;
}

.day-activities,
.day-meals,
.day-accommodation,
.day-included {
  margin-top: 20px;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.day-activities h5,
.day-meals h5,
.day-accommodation h5,
.day-included h5 {
  color: #333;
  margin-bottom: 15px;
  font-size: 1.15rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 8px;
}

.day-activities ul,
.day-meals ul,
.day-included ul {
  list-style: none;
  padding: 0;
}

.day-activities li {
  padding: 12px 0;
  border-bottom: 1px solid #eee;
  color: #444;
  font-size: 1.05rem;
}

.day-activities li:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.day-meals li {
  padding: 8px 0;
  color: #444;
  font-size: 1.05rem;
}

.day-meals li strong {
  color: #333;
  text-transform: capitalize;
}

.day-accommodation p {
  color: #444;
  font-size: 1.05rem;
}

.day-included li {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 6px 0;
  color: #444;
  font-size: 1.05rem;
}

.day-included svg {
  color: #4CAF50;
  font-size: 1.1rem;
  flex-shrink: 0;
}

.modal-footer {
  padding: 25px 40px;
  background: #f8f9fa;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0 0 16px 16px;
  position: sticky;
  bottom: 0;
  backdrop-filter: blur(10px);
}

.price-section {
  display: flex;
  flex-direction: column;
}

.price-label {
  font-size: 1rem;
  color: #666;
  margin-bottom: 5px;
}

.price-amount {
  font-size: 2rem;
  font-weight: bold;
  color: #4CAF50;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.book-now-btn {
  background: #4CAF50;
  color: white;
  border: none;
  padding: 15px 40px;
  border-radius: 30px;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s;
  box-shadow: 0 4px 15px rgba(76, 175, 80, 0.3);
  letter-spacing: 0.5px;
}

.book-now-btn:hover {
  background: #45a049;
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(76, 175, 80, 0.4);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .modal-content {
    max-height: 95vh;
    margin: 0;
    border-radius: 0;
  }

  .modal-image {
    height: 300px;
  }

  .modal-title {
    padding: 20px;
  }

  .modal-title h2 {
    font-size: 1.8rem;
  }

  .modal-meta {
    gap: 10px;
    font-size: 0.9rem;
  }

  .modal-body {
    padding: 20px;
  }

  .modal-description h3,
  .modal-highlights h3,
  .modal-itinerary h3 {
    font-size: 1.5rem;
  }

  .modal-highlights ul {
    grid-template-columns: 1fr;
  }

  .day-header h4 {
    font-size: 1.2rem;
  }

  .modal-footer {
    flex-direction: column;
    gap: 20px;
    text-align: center;
    padding: 20px;
  }

  .price-amount {
    font-size: 1.8rem;
  }

  .book-now-btn {
    width: 100%;
    padding: 12px 30px;
  }
}

.logo-circle {
  position: sticky;
  top: 20px;
  margin-left: auto;
  margin-right: 80px;
  width: 60px;
  height: 60px;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 50%;
  padding: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
  z-index: 1001;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.logo-circle img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 50%;
}

.logo-circle:hover {
  transform: scale(1.1);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .logo-circle {
    width: 50px;
    height: 50px;
    margin-right: 70px;
  }
}

@media (max-width: 480px) {
  .logo-circle {
    width: 40px;
    height: 40px;
    margin-right: 60px;
    padding: 6px;
  }
}

/* Itinerary Tab Styling */
.itinerary-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.itinerary-day {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0,0,0,0.05);
  background-color: #fff;
}

.itinerary-day-header {
  background: linear-gradient(135deg, #ff7b25 0%, #ff5722 100%);
  padding: 18px 20px;
  color: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  position: relative;
  overflow: hidden;
  text-align: center;
}

.itinerary-day-header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 100%);
  transform: skewX(-15deg) translateX(-10%);
}

.itinerary-day-header h4 {
  margin: 0;
  font-size: 1.4rem;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.2);
  position: relative;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.itinerary-day-header h4::before {
  content: '';
  display: inline-block;
  width: 8px;
  height: 28px;
  background-color: white;
  margin-right: 15px;
  border-radius: 4px;
  box-shadow: 1px 1px 3px rgba(0,0,0,0.2);
}

.itinerary-day-content {
  padding: 20px;
}

.itinerary-row {
  margin-bottom: 20px;
}

.itinerary-row:last-child {
  margin-bottom: 0;
}

.itinerary-col.full-width {
  flex: 1 0 100%;
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 6px;
}

.itinerary-col h5 {
  margin-top: 0;
  margin-bottom: 10px;
  color: #2d665f;
  font-size: 1rem;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 8px;
}

.itinerary-col h5::before {
  content: '';
  display: inline-block;
  width: 6px;
  height: 20px;
  background-color: #ff7b25;
  margin-right: 10px;
  border-radius: 3px;
}

.itinerary-col p {
  margin: 0;
  line-height: 1.5;
}

.itinerary-col ul {
  margin: 0;
  padding-left: 20px;
}

.itinerary-col li {
  margin-bottom: 5px;
}

.description-col {
  flex: 2;
}

.included-col {
  flex: 1 0 100%;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .itinerary-row {
    flex-direction: column;
    gap: 15px;
  }
  
  .itinerary-col {
    min-width: 100%;
  }
  
  .description-col {
    flex: 1;
  }
}

/* Activities Grid Styling */
.activities-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 12px;
  margin-top: 5px;
}

.activity-item {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 12px 15px;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  transition: all 0.2s ease;
  border-left: 3px solid #ff7b25;
}

.activity-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.activity-icon {
  color: #ff7b25;
  font-size: 1.1rem;
  margin-right: 12px;
  min-width: 20px;
}

/* Responsive design for activities */
@media (max-width: 768px) {
  .activities-grid {
    grid-template-columns: 1fr;
  }
  
  .activity-item {
    margin-bottom: 8px;
  }
}

/* Add some variety with alternating colors */
.activity-item:nth-child(4n+1) {
  border-left-color: #ff7b25;
}

.activity-item:nth-child(4n+1) .activity-icon {
  color: #ff7b25;
}

.activity-item:nth-child(4n+2) {
  border-left-color: #2d665f;
}

.activity-item:nth-child(4n+2) .activity-icon {
  color: #2d665f;
}

.activity-item:nth-child(4n+3) {
  border-left-color: #fdb913;
}

.activity-item:nth-child(4n+3) .activity-icon {
  color: #fdb913;
}

.activity-item:nth-child(4n+4) {
  border-left-color: #4a90e2;
}

.activity-item:nth-child(4n+4) .activity-icon {
  color: #4a90e2;
}

/* Included Grid Styling */
.included-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 12px;
  margin-top: 5px;
}

.included-item {
  display: flex;
  align-items: center;
  background-color: #f1f9f7;
  padding: 12px 15px;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  transition: all 0.2s ease;
  border-left: 3px solid #2d665f;
}

.included-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.included-icon {
  color: #2d665f;
  font-size: 1.1rem;
  margin-right: 12px;
  min-width: 20px;
}

/* Responsive design for included items */
@media (max-width: 768px) {
  .included-grid {
    grid-template-columns: 1fr;
  }
  
  .included-item {
    margin-bottom: 8px;
  }
}

/* Add some variety with alternating colors */
.included-item:nth-child(4n+1) {
  border-left-color: #2d665f;
  background-color: #f1f9f7;
}

.included-item:nth-child(4n+1) .included-icon {
  color: #2d665f;
}

.included-item:nth-child(4n+2) {
  border-left-color: #3f8f3f;
  background-color: #f4faf4;
}

.included-item:nth-child(4n+2) .included-icon {
  color: #3f8f3f;
}

.included-item:nth-child(4n+3) {
  border-left-color: #4a90e2;
  background-color: #f4f8fd;
}

.included-item:nth-child(4n+3) .included-icon {
  color: #4a90e2;
}

.included-item:nth-child(4n+4) {
  border-left-color: #8e44ad;
  background-color: #f9f4fa;
}

.included-item:nth-child(4n+4) .included-icon {
  color: #8e44ad;
} 