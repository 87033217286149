.all-trips-page {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.trips-hero {
  text-align: center;
  margin-bottom: 3rem;
  padding: 4rem 0;
  background: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url("/public/images/tripBanner.jpeg");
  background-size: cover;
  background-position: center;
  color: white;
  border-radius: 1rem;
}

.trips-hero h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.custom-trip-btn {
  background: #ff6b6b;
  color: white;
  border: none;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  font-size: 1.1rem;
  cursor: pointer;
  transition: background 0.3s;
}

.custom-trip-btn:hover {
  background: #ff5252;
}

.trips-filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  gap: 1rem;
}

.category-filters {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.category-btn {
  padding: 0.5rem 1rem;
  border: 1px solid #ddd;
  border-radius: 2rem;
  background: white;
  cursor: pointer;
  transition: all 0.3s;
}

.category-btn.active {
  background: #4CAF50;
  color: white;
  border-color: #4CAF50;
}

.sort-options {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.sort-options select {
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
}

.trips-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
}

.trip-card {
  border: 1px solid #eee;
  border-radius: 1rem;
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
  background: white;
}

.trip-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0,0,0,0.1);
}

.trip-image {
  position: relative;
  height: 200px;
}

.trip-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.trip-categories {
  position: absolute;
  top: 1rem;
  left: 1rem;
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.trip-category {
  background: rgba(0,0,0,0.7);
  color: white;
  padding: 0.3rem 0.8rem;
  border-radius: 1rem;
  font-size: 0.8rem;
}

.trip-content {
  padding: 1.5rem;
}

.trip-content h3 {
  margin: 0 0 1rem;
  font-size: 1.2rem;
}

.trip-meta {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  font-size: 0.9rem;
  color: #666;
}

.trip-meta span {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.trip-description {
  margin-bottom: 1.5rem;
  color: #666;
  font-size: 0.9rem;
  line-height: 1.5;
}

.trip-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
}

.trip-price {
  font-size: 1.2rem;
  font-weight: bold;
  color: #4CAF50;
}

.view-trip-btn {
  background: #4CAF50;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  text-decoration: none;
  transition: background 0.3s;
}

.view-trip-btn:hover {
  background: #45a049;
}

@media (max-width: 768px) {
  .trips-filters {
    flex-direction: column;
    align-items: stretch;
  }
  
  .category-filters {
    justify-content: center;
  }
  
  .sort-options {
    justify-content: center;
  }
  
  .trips-grid {
    grid-template-columns: 1fr;
  }
} 