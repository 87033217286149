/* Partners Section Styling */
.partners-section {
  padding: 100px 0;
  background-color: #f9f9f9;
  position: relative;
  overflow: hidden;
}

.partners-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-image: radial-gradient(#ff7b25 1px, transparent 1px), radial-gradient(#2d665f 1px, transparent 1px);
  background-size: 30px 30px;
  background-position: 0 0, 15px 15px;
  opacity: 0.03;
  pointer-events: none;
}

.section-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.section-icon {
  font-size: 2.5rem;
  color: #ff7b25;
  margin-right: 15px;
}

.section-header h2 {
  font-size: 2.4rem;
  color: #2d665f;
  margin: 0;
}

.section-description {
  max-width: 1000px;
  margin: 0 auto 40px;
  text-align: center;
  font-size: 1.1rem;
  line-height: 1.6;
  color: #555;
}

/* Main Partner Styling */
.main-partner-container {
  max-width: 1400px;
  margin: 0 auto 50px;
  padding: 0 20px;
}

.main-partner-card {
  display: flex;
  background: linear-gradient(135deg, #ffffff 0%, #f9f9f9 100%);
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.05);
  position: relative;
}

.main-partner-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 8px;
  background: linear-gradient(to right, #ff7b25, #ff5722);
}

.main-partner-logo {
  flex: 0 0 300px;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.main-partner-logo img {
  max-width: 100%;
  max-height: 200px;
  object-fit: contain;
}

.main-partner-content {
  flex: 1;
  padding: 30px;
  display: flex;
  flex-direction: column;
}

.main-partner-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.main-partner-header h3 {
  font-size: 1.8rem;
  color: #2d665f;
  margin: 0;
}

.partner-type.featured {
  font-size: 0.85rem;
  background: linear-gradient(to right, #ff7b25, #ff5722);
  color: white;
  padding: 5px 15px;
  border-radius: 20px;
  font-weight: 600;
  box-shadow: 0 3px 10px rgba(255, 123, 37, 0.2);
}

.main-partner-content p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #555;
  margin-bottom: 25px;
}

.partner-link.featured {
  display: inline-flex;
  align-items: center;
  color: white;
  font-weight: 500;
  text-decoration: none;
  font-size: 1rem;
  padding: 10px 20px;
  border-radius: 25px;
  background: linear-gradient(to right, #ff7b25, #ff5722);
  box-shadow: 0 4px 15px rgba(255, 123, 37, 0.3);
  transition: all 0.3s ease;
  align-self: flex-start;
}

.partner-link.featured svg {
  margin-left: 8px;
  font-size: 0.9rem;
  transition: transform 0.3s;
}

.partner-link.featured:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 20px rgba(255, 123, 37, 0.4);
}

.partner-link.featured:hover svg {
  transform: translateX(3px);
}

/* Partners Subheading */
.partners-subheading {
  text-align: center;
  font-size: 1.6rem;
  color: #2d665f;
  margin: 0 0 30px;
  position: relative;
  padding-bottom: 15px;
}

.partners-subheading::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 3px;
  background-color: #ff7b25;
  border-radius: 2px;
}

/* Partners Carousel */
.partners-carousel-container {
  max-width: 1400px;
  margin: 0 auto;
  width: 100%;
  overflow: hidden;
  position: relative;
  padding: 20px 0;
}

.partners-carousel-container::before,
.partners-carousel-container::after {
  content: '';
  position: absolute;
  top: 0;
  width: 100px;
  height: 100%;
  z-index: 2;
  pointer-events: none;
}

.partners-carousel-container::before {
  left: 0;
  background: linear-gradient(to right, #f9f9f9, rgba(249, 249, 249, 0));
}

.partners-carousel-container::after {
  right: 0;
  background: linear-gradient(to left, #f9f9f9, rgba(249, 249, 249, 0));
}

.partners-carousel {
  display: flex;
  width: max-content;
  animation: scrollPartners 60s linear infinite;
}

.partners-carousel:hover {
  animation-play-state: paused;
}

@keyframes scrollPartners {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-152px * 11)); /* Adjusted for smaller card width + margin */
  }
}

.partner-card-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 12px; /* Reduced from 15px */
}

.partner-card {
  width: 140px; /* Reduced from 160px */
  height: 85px; /* Reduced from 100px */
  flex-shrink: 0;
  background-color: white;
  border-radius: 6px; /* Slightly reduced from 8px */
  padding: 10px; /* Reduced from 12px */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.05);
  position: relative;
  overflow: hidden;
}

.partner-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15), 0 0 15px rgba(255, 123, 37, 0.2);
}

.partner-card img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  filter: grayscale(20%);
  transition: filter 0.3s ease;
}

.partner-card:hover img {
  filter: grayscale(0%);
}

.partner-name {
  margin-top: 6px; /* Reduced from 8px */
  font-size: 0.75rem; /* Reduced from 0.85rem */
  font-weight: 600;
  color: #444;
  text-align: center;
  max-width: 140px; /* Match card width */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.partner-card-wrapper:hover .partner-name {
  color: #ff7b25;
  transform: translateY(-2px);
  text-shadow: 1px 1px 5px rgba(255, 123, 37, 0.2);
}

/* Responsive Adjustments */
@media (max-width: 992px) {
  .main-partner-card {
    flex-direction: column;
  }
  
  .main-partner-logo {
    flex: 0 0 auto;
    border-right: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }
}

@media (max-width: 768px) {
  .partners-section {
    padding: 50px 0;
  }
  
  .section-header {
    flex-direction: column;
    text-align: center;
  }
  
  .section-icon {
    margin-right: 0;
    margin-bottom: 10px;
  }
  
  .section-header h2 {
    font-size: 2rem;
  }
  
  .main-partner-header h3 {
    font-size: 1.5rem;
  }
  
  .main-partner-content p {
    font-size: 1rem;
  }
  
  .partner-card {
    width: 120px; /* Reduced from 140px */
    height: 75px; /* Reduced from 90px */
    padding: 8px;
  }
  
  .partner-name {
    font-size: 0.7rem;
    max-width: 120px;
  }
  
  .partner-card-wrapper {
    margin-right: 10px;
  }
  
  @keyframes scrollPartners {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-130px * 11)); /* Adjusted for smaller card width + margin */
    }
  }
} 