.terms-container {
  padding: 0;
  margin: 0;
  width: 100%;
}

.terms-hero {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    var(--hero-bg);
  background-size: cover;
  background-position: center;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  padding: 2rem;
  position: relative;
}

.terms-hero::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px;
  background: linear-gradient(to top, rgba(255, 255, 255, 1), transparent);
}

.terms-hero h1 {
  font-size: 4rem;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  font-weight: 700;
  letter-spacing: 1px;
}

.terms-hero p {
  font-size: 1.5rem;
  max-width: 800px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  opacity: 0.9;
}

.terms-content {
  max-width: 1200px;
  margin: 4rem auto;
  padding: 0 2rem;
  font-size: 1.1rem;
}

.terms-section {
  margin-bottom: 3rem;
  background: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.terms-section:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 25px rgba(0, 0, 0, 0.1);
}

.terms-section h2 {
  color: #333;
  font-size: 2.2rem;
  margin-bottom: 1.5rem;
  padding-bottom: 0.8rem;
  border-bottom: 3px solid #4CAF50;
  position: relative;
  display: inline-block;
}

.terms-section h3 {
  color: #444;
  font-size: 1.8rem;
  margin: 2rem 0 1rem;
  font-weight: 600;
}

.terms-section p {
  color: #555;
  line-height: 1.8;
  margin-bottom: 1.5rem;
  font-size: 1.15rem;
}

.terms-section ul {
  list-style: none;
  padding-left: 2rem;
  margin-bottom: 1.5rem;
}

.terms-section li {
  color: #555;
  margin-bottom: 1rem;
  line-height: 1.6;
  font-size: 1.15rem;
}

.terms-section li::before {
  content: '✓';
  color: #4CAF50;
  position: absolute;
  left: 0;
  font-weight: bold;
  font-size: 1.2rem;
}

.terms-note {
  background: #f8f9fa;
  padding: 2rem;
  border-radius: 15px;
  margin: 2rem 0;
  border-left: 5px solid #4CAF50;
  position: relative;
  overflow: hidden;
}

.terms-note::before {
  content: '"';
  position: absolute;
  top: -20px;
  right: 20px;
  font-size: 8rem;
  color: rgba(76, 175, 80, 0.1);
  font-family: serif;
}

.terms-note h4 {
  color: #2c3e50;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  font-weight: 600;
}

.terms-note p {
  color: #666;
  margin: 0;
  font-style: italic;
}

.contact-info {
  background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
  padding: 3rem;
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  margin-top: 4rem;
}

.contact-info::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--pattern-bg) repeat;
  opacity: 0.1;
}

.contact-info h2 {
  color: #333;
  font-size: 2.2rem;
  margin-bottom: 1rem;
  text-align: center;
}

.contact-intro {
  text-align: center;
  color: #666;
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.contact-form {
  max-width: 800px;
  margin: 0 auto 2rem;
  padding: 2rem;
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  color: #444;
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 1rem;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  font-size: 1.1rem;
  transition: border-color 0.3s ease;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #4CAF50;
}

.submit-button {
  display: block;
  width: 100%;
  padding: 1rem;
  background: #4CAF50;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1.2rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background: #388E3C;
}

.contact-details {
  max-width: 800px;
  margin: 2rem auto 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  text-align: center;
}

.contact-item {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-size: 1.1rem;
}

.contact-item i {
  color: #4CAF50;
  font-size: 1.2rem;
}

.contact-item a,
.contact-item span {
  color: #666;
  text-decoration: none;
  transition: color 0.3s ease;
}

.contact-item a:hover {
  color: #4CAF50;
}

@media (max-width: 768px) {
  .terms-hero h1 {
    font-size: 2.8rem;
  }

  .terms-hero p {
    font-size: 1.2rem;
  }

  .terms-section h2 {
    font-size: 1.8rem;
  }

  .terms-section h3 {
    font-size: 1.5rem;
  }

  .terms-section p,
  .terms-section li {
    font-size: 1rem;
  }

  .terms-content {
    padding: 1.5rem;
    margin-top: -30px;
  }

  .terms-section {
    padding: 1.5rem;
  }

  .contact-info {
    padding: 2rem 1rem;
  }

  .contact-details {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .terms-hero h1 {
    font-size: 2.2rem;
  }

  .terms-content {
    padding: 1rem;
  }

  .terms-section {
    padding: 1rem;
  }
} 