.team-page {
  padding-top: 80px;
  background: #f9f9f9;
  min-height: 100vh;
}

.team-hero {
  background: linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)),
              url('https://img.freepik.com/free-photo/boy-scouts-spending-time-nature_23-2149572885.jpg?semt=ais_hybrid') center/cover fixed;
  height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  padding: 2rem;
}

.team-hero h1 {
  font-size: 3.5rem;
  margin-bottom: 1rem;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.team-hero p {
  font-size: 1.2rem;
  max-width: 600px;
  line-height: 1.6;
}

.team-container {
  max-width: 1200px;
  margin: -50px auto 4rem;
  padding: 0 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 3rem;
  position: relative;
}

.team-member {
  background: white;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
  transition: transform 0.3s ease;
}

.team-member:hover {
  transform: translateY(-10px);
}

.member-image {
  position: relative;
  overflow: hidden;
  aspect-ratio: 1;
}

.member-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.team-member:hover .member-image img {
  transform: scale(1.1);
}

.social-links {
  position: absolute;
  bottom: -50px;
  left: 0;
  right: 0;
  background: rgba(0,0,0,0.7);
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
  transition: bottom 0.3s ease;
}

.team-member:hover .social-links {
  bottom: 0;
}

.social-links a {
  color: white;
  font-size: 1.2rem;
  transition: color 0.3s ease;
}

.social-links a:hover {
  color: var(--primary-color);
}

.member-info {
  padding: 1.5rem;
  text-align: center;
}

.member-info h3 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.member-info h4 {
  font-size: 1rem;
  color: var(--primary-color);
  margin-bottom: 0.5rem;
}

.member-info p {
  font-size: 0.9rem;
  color: #666;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .team-hero h1 {
    font-size: 2.5rem;
  }
  
  .team-container {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 2rem;
    padding: 0 1rem;
  }
}

@media (max-width: 480px) {
  .team-hero {
    height: 30vh;
  }
  
  .team-hero h1 {
    font-size: 2rem;
  }
  
  .team-hero p {
    font-size: 1rem;
  }
}

.team-intro {
  background: white;
  padding: 4rem 0;
  text-align: center;
}

.team-description {
  max-width: 800px;
  margin: 0 auto 3rem;
  color: #666;
  line-height: 1.8;
  font-size: 1.1rem;
}

.team-stats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
  max-width: 1000px;
  margin: 0 auto;
}

.stat-item {
  padding: 2rem;
  background: linear-gradient(135deg, #fff, #f8f9fa);
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0,0,0,0.1);
  transition: transform 0.3s ease;
}

.stat-item:hover {
  transform: translateY(-10px);
}

.stat-item svg {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.stat-item h3 {
  font-size: 2.5rem;
  color: var(--primary-dark);
  margin-bottom: 0.5rem;
}

/* Team Values Section */
.team-values {
  padding: 6rem 0;
  background: linear-gradient(135deg, #f8f9fa, #fff);
}

.values-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 3rem;
}

.value-card {
  padding: 2.5rem;
  background: white;
  border-radius: 15px;
  text-align: center;
  box-shadow: 0 10px 30px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
}

.value-card:hover {
  transform: translateY(-10px);
}

.value-card svg {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 1.5rem;
}

.value-card h3 {
  color: var(--primary-dark);
  margin-bottom: 1rem;
}

/* Testimonials Section */
.team-testimonials {
  padding: 6rem 0;
  background: #fff;
}

.testimonials-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 3rem;
}

.testimonial-card {
  padding: 2rem;
  background: linear-gradient(135deg, #f8f9fa, #fff);
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0,0,0,0.1);
  position: relative;
}

.quote-icon {
  font-size: 2rem;
  color: var(--primary-color);
  opacity: 0.2;
  position: absolute;
  top: 2rem;
  right: 2rem;
}

.testimonial-author {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid #eee;
}

.testimonial-author img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}

/* Join Team Section */
.join-team {
  padding: 6rem 0;
  background: linear-gradient(rgba(0,0,0,0.8), rgba(0,0,0,0.8)),
              url('https://img.freepik.com/free-photo/woman-tying-her-shoelaces-by-her-tent_53876-63347.jpg?semt=ais_hybrid') center/cover fixed;
  color: white;
  text-align: center;
}

.career-benefits {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin: 3rem 0;
}

.benefit-item {
  padding: 2rem;
  background: rgba(255,255,255,0.1);
  border-radius: 15px;
  backdrop-filter: blur(10px);
  transition: transform 0.3s ease;
}

.benefit-item:hover {
  transform: translateY(-10px);
}

.benefit-item svg {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 1.5rem;
}

.join-button {
  display: inline-block;
  padding: 1rem 2.5rem;
  background: var(--primary-color);
  color: white;
  text-decoration: none;
  border-radius: 30px;
  font-weight: 600;
  transition: all 0.3s ease;
  margin-top: 2rem;
}

.join-button:hover {
  background: var(--primary-dark);
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0,0,0,0.2);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .team-stats,
  .values-grid,
  .testimonials-grid,
  .career-benefits {
    grid-template-columns: 1fr;
  }
  
  .value-card,
  .testimonial-card,
  .benefit-item {
    padding: 1.5rem;
  }
} 