:root {
  /* Primary Colors */
  --primary-color: #ff7b25;
  --primary-dark: #e66000;
  --primary-light: #ffa366;
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  background: transparent;
  z-index: 9999;
  transition: all 0.3s ease;
}

.navbar.scrolled {
  background: var(--primary-color);
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
}

.nav-content {
  max-width: 1800px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding: 0 4rem;
  justify-content: space-between;
  position: relative;
  z-index: 9999;
}

/* Logo */
.logo {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  font-size: 1.8rem;
  font-weight: 800;
  color: var(--primary-color);
  text-decoration: none;
  transition: all 0.3s ease;
}

/* Navigation Links */
.nav-links {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.nav-item {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
}

.nav-link {
  color: var(--primary-color);
  text-decoration: none;
  font-size: 0.85rem;
  font-weight: 600;
  padding: 0.5rem 0.8rem;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

/* Scrolled state */
.navbar.scrolled .nav-link,
.navbar.scrolled .logo {
  color: white;
  font-weight: 700;
}

.navbar.scrolled .nav-link:hover {
  background: rgba(255, 255, 255, 0.1);
}

/* Dropdown Styling */
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 250px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 8px 30px rgba(0,0,0,0.12);
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  padding: 1rem 0;
  z-index: 10000;
  border: 1px solid rgba(0,0,0,0.05);
}

/* Nested dropdown styling */
.nested-dropdown {
  position: absolute;
  left: 100%;
  top: 0;
  min-width: 250px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 8px 30px rgba(0,0,0,0.12);
  opacity: 0;
  visibility: hidden;
  transform: translateX(10px);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  padding: 1rem 0;
  border: 1px solid rgba(0,0,0,0.05);
}

/* Show dropdowns on hover with animation */
.nav-item:hover > .dropdown-menu {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.dropdown-item:hover > .nested-dropdown {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
}

/* Dropdown item styling */
.dropdown-item {
  position: relative;
  display: block;
  padding: 0.5rem 0;
  transition: all 0.2s ease;
}

.dropdown-item a {
  color: #333;
  font-size: 0.9rem;
  font-weight: 500;
  padding: 0.5rem 1.5rem;
  display: block;
  text-decoration: none;
  transition: all 0.2s ease;
}

.dropdown-item:hover > a {
  color: var(--primary-color);
  background: rgba(255, 123, 37, 0.05);
}

/* Dropdown item content styling */
.dropdown-item-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1.5rem;
  color: #333;
  font-size: 0.9rem;
  font-weight: 500;
  transition: all 0.2s ease;
}

.dropdown-item-content:hover {
  color: var(--primary-color);
  background: rgba(255, 123, 37, 0.05);
}

.dropdown-item-content a {
  color: inherit;
  text-decoration: none;
  flex-grow: 1;
}

/* Dropdown icons styling */
.dropdown-icon {
  font-size: 0.8rem;
  margin-left: 0.5rem;
  transition: transform 0.2s ease;
}

.nested-arrow {
  font-size: 0.8rem;
  margin-left: 0.5rem;
  color: #666;
  transition: transform 0.2s ease;
}

.dropdown-item:hover .nested-arrow {
  transform: translateX(3px);
  color: var(--primary-color);
}

/* Scrolled state dropdown styling */
.navbar.scrolled .dropdown-menu,
.navbar.scrolled .nested-dropdown {
  background: white;
  border: none;
}

.navbar.scrolled .dropdown-item a {
  color: #333;
}

.navbar.scrolled .dropdown-item:hover > a {
  color: var(--primary-color);
  background: rgba(255, 123, 37, 0.05);
}

/* Active states */
.dropdown-item a.active {
  color: var(--primary-color);
  font-weight: 600;
  background: rgba(255, 123, 37, 0.08);
}

/* Mobile dropdown adjustments */
@media (max-width: 992px) {
  .dropdown-menu,
  .nested-dropdown {
    position: static;
    box-shadow: none;
    transform: none;
    border-radius: 4px;
    background: rgba(255, 123, 37, 0.03);
    border: none;
    padding: 0.5rem 0;
    margin: 0.5rem 0;
  }

  .dropdown-item-content,
  .dropdown-item a {
    padding: 0.75rem 1.5rem;
  }

  .nested-dropdown {
    margin-left: 1.5rem;
    border-left: 2px solid rgba(255, 123, 37, 0.2);
  }
}

/* Mobile menu button */
.menu-button {
  display: none;
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
}

.navbar.scrolled .menu-button {
  color: #333;
}

/* Mobile styles */
@media (max-width: 992px) {
  .nav-content {
    padding: 0 2rem;
  }

  .menu-button {
    display: block;
  }

  .nav-links {
    position: fixed;
    top: 80px;
    left: 0;
    right: 0;
    background: white;
    padding: 1rem;
    flex-direction: column;
    gap: 1rem;
    display: none;
  }

  .nav-links.active {
    display: flex;
  }

  .nav-link {
    color: #333;
    width: 100%;
  }

  .dropdown-menu,
  .nested-dropdown {
    position: static;
    box-shadow: none;
    opacity: 1;
    visibility: visible;
    display: none;
    padding-left: 1.5rem;
  }

  .nav-item:hover > .dropdown-menu,
  .dropdown-item:hover > .nested-dropdown {
    display: block;
  }

  .nested-dropdown {
    position: static;
    box-shadow: none;
    padding-left: 1rem;
    margin-top: 0.5rem;
  }
}

/* jaribu ongezea */

.nav-link.active {
  color: white;
  background: var(--primary-color);
  border-radius: 4px;
  font-weight: 700;
}

/* When scrolled, update active tab style */
.navbar.scrolled .nav-link.active {
  color: var(--primary-color);
  background: white;
  font-weight: 700;
}

/* Hover effects for active tab */
.nav-link.active:hover {
  transform: translateY(-1px);
}

.navbar.scrolled .nav-link.active:hover {
  background: rgba(255, 255, 255, 0.9);
}

/* Update dropdown active states */
.dropdown-item a.active {
  color: var(--primary-color);
  font-weight: 700;
  background: rgba(255, 123, 37, 0.1);
}

.navbar.scrolled .dropdown-item a.active {
  color: white;
  background: rgba(255, 255, 255, 0.1);
}

/* Add these styles for active/selected tab */
.nav-link.active {
  color: white;
  background: var(--primary-color);
  border-radius: 4px;
  font-weight: 700;
}

/* When scrolled, update active tab style */
.navbar.scrolled .nav-link.active {
  color: var(--primary-color);
  background: white;
  font-weight: 700;
}

/* Hover effects for active tab */
.nav-link.active:hover {
  transform: translateY(-1px);
}

.navbar.scrolled .nav-link.active:hover {
  background: rgba(255, 255, 255, 0.9);
}

/* Update dropdown active states */
.dropdown-item a.active {
  color: var(--primary-color);
  font-weight: 700;
  background: rgba(255, 123, 37, 0.1);
}

.navbar.scrolled .dropdown-item a.active {
  color: white;
  background: rgba(255, 255, 255, 0.1);
}

.nav-link:hover {
  color: var(--primary-color);
}

.nav-link.active {
  color: var(--primary-color);
}

.dropdown-menu a:hover {
  background-color: var(--primary-color);
}