/* Variables */
:root {
  --primary-color: #2d5a27;
  --secondary-color: #6b4423;
  --accent-color: #ffa41b;
  --text-color: #333;
  --light-text: #fff;
  --background-light: #f5f5f5;
  --background-dark: #1a1a1a;
  --shadow-sm: 0 2px 4px rgba(0,0,0,0.1);
  --shadow-md: 0 4px 6px rgba(0,0,0,0.1);
  --shadow-lg: 0 10px 15px rgba(0,0,0,0.1);
  --border-radius: 8px;
  --transition: all 0.3s ease;
}

/* Global Styles */
.park-page {
  color: var(--text-color);
  line-height: 1.6;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Hero Section */
.park-hero {
  height: 80vh;
  background-image: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)),
    url('https://images.unsplash.com/photo-mount-meru-hero');
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--light-text);
}

.hero-content h1 {
  font-size: 4rem;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
}

.hero-content p {
  font-size: 1.5rem;
  max-width: 600px;
  margin: 0 auto;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
}

/* Park Overview */
.park-overview {
  padding: 80px 0;
  background-color: var(--background-light);
}

.overview-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  align-items: center;
}

.overview-content h2 {
  color: var(--primary-color);
  margin-bottom: 1.5rem;
}

.key-stats {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 2rem;
}

.stat-item {
  background: white;
  padding: 20px;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-sm);
  text-align: center;
}

.stat-number {
  font-size: 2rem;
  color: var(--primary-color);
  font-weight: bold;
}

.stat-label {
  color: var(--secondary-color);
  margin: 0.5rem 0;
  font-weight: 500;
}

.overview-image img {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-lg);
}

/* Wildlife Section */
.wildlife-section {
  padding: 80px 0;
}

.wildlife-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-top: 40px;
}

.wildlife-card {
  background: white;
  border-radius: var(--border-radius);
  overflow: hidden;
  box-shadow: var(--shadow-md);
  transition: var(--transition);
}

.wildlife-card:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-lg);
}

.wildlife-image {
  height: 250px;
  overflow: hidden;
}

.wildlife-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: var(--transition);
}

.wildlife-info {
  padding: 20px;
}

.wildlife-info h3 {
  color: var(--primary-color);
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 1rem;
}

.best-time {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--secondary-color);
  margin-top: 1rem;
  font-size: 0.9rem;
}

/* Activities Section */
.activities-section {
  padding: 80px 0;
  background-color: var(--background-light);
}

.activity-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-top: 40px;
}

.activity-card {
  background: white;
  border-radius: var(--border-radius);
  overflow: hidden;
  box-shadow: var(--shadow-md);
  transition: var(--transition);
  height: 100%;
  display: flex;
  flex-direction: column;
}

.activity-card:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-lg);
}

.activity-image {
  height: 250px;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.activity-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: var(--transition);
  display: block;
}

.activity-content {
  padding: 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.activity-content h3 {
  color: var(--primary-color);
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

.activity-content p {
  margin-bottom: 1rem;
  flex-grow: 1;
  line-height: 1.5;
}

.activity-details {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  color: var(--secondary-color);
  font-size: 0.9rem;
}

.activity-details span {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 4px 8px;
  background: var(--background-light);
  border-radius: var(--border-radius);
}

/* Responsive adjustments for activities */
@media (max-width: 768px) {
  .activity-cards {
    grid-template-columns: 1fr;
  }

  .activity-image {
    height: 200px;
  }
}

/* Booking Section */
.booking-section {
  padding: 80px 0;
  background-color: var(--background-light);
}

.booking-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 40px;
}

.booking-form {
  background: white;
  padding: 30px;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-md);
}

.form-group {
  margin-bottom: 20px;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: var(--border-radius);
  font-size: 1rem;
}

.form-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.submit-btn {
  background: var(--primary-color);
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: var(--border-radius);
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: var(--transition);
}

.submit-btn:hover {
  background: var(--secondary-color);
}

.quick-contact {
  background: white;
  padding: 30px;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-md);
}

.whatsapp-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background: #25D366;
  color: white;
  padding: 12px;
  border-radius: var(--border-radius);
  text-decoration: none;
  margin-bottom: 20px;
  transition: var(--transition);
}

.whatsapp-btn:hover {
  background: #128C7E;
}

.contact-info p {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero-content h1 {
    font-size: 3rem;
  }
  
  .overview-grid {
    grid-template-columns: 1fr;
  }
  
  .booking-container {
    grid-template-columns: 1fr;
  }
  
  .key-stats {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .hero-content h1 {
    font-size: 2.5rem;
  }
  
  .form-row {
    grid-template-columns: 1fr;
  }
  
  .activity-cards,
  .wildlife-grid {
    grid-template-columns: 1fr;
  }
}

/* Ecosystems Section */
.ecosystems-section {
  padding: 80px 0;
}

.ecosystem-tabs {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 40px;
}

.ecosystem-tab {
  padding: 12px 24px;
  border: none;
  background: var(--background-light);
  color: var(--text-color);
  border-radius: var(--border-radius);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: var(--transition);
}

.ecosystem-tab.active {
  background: var(--primary-color);
  color: white;
}

.ecosystem-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  align-items: center;
}

.ecosystem-info {
  padding: 30px;
  background: white;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-md);
}

.ecosystem-info h3 {
  color: var(--primary-color);
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 1.5rem;
}

.ecosystem-features {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  margin-top: 20px;
}

.ecosystem-feature {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--secondary-color);
}

.ecosystem-image {
  height: 400px;
  border-radius: var(--border-radius);
  overflow: hidden;
  box-shadow: var(--shadow-lg);
}

.ecosystem-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Cultural Experiences */
.cultural-section {
  padding: 80px 0;
  background-color: var(--background-light);
}

.cultural-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
}

.cultural-card {
  background: white;
  padding: 30px;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-md);
  transition: var(--transition);
}

.cultural-card:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-lg);
}

.cultural-card h3 {
  color: var(--primary-color);
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 1rem;
}

.cultural-activities {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  margin-top: 20px;
}

.cultural-activity {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--secondary-color);
  font-size: 0.9rem;
}

/* Conservation Section */
.conservation-section {
  padding: 80px 0;
}

.conservation-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
}

.conservation-card {
  background: white;
  padding: 30px;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-md);
  transition: var(--transition);
}

.conservation-card:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-lg);
}

.conservation-card h3 {
  color: var(--primary-color);
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 1rem;
}

.achievements {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #eee;
  color: var(--secondary-color);
  font-style: italic;
}

/* Photo Gallery */
.gallery-section {
  padding: 80px 0;
  background-color: var(--background-light);
}

.gallery-container {
  position: relative;
}

.gallery-main {
  position: relative;
  height: 500px;
  border-radius: var(--border-radius);
  overflow: hidden;
  box-shadow: var(--shadow-lg);
}

.gallery-main img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gallery-controls {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.gallery-btn {
  background: rgba(255, 255, 255, 0.8);
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: var(--transition);
}

.gallery-btn:hover {
  background: white;
}

.gallery-expand {
  position: absolute;
  top: 20px;
  right: 20px;
}

.gallery-thumbnails {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 15px;
  margin-top: 20px;
}

.gallery-thumbnail {
  height: 80px;
  border-radius: var(--border-radius);
  overflow: hidden;
  cursor: pointer;
  opacity: 0.6;
  transition: var(--transition);
}

.gallery-thumbnail.active {
  opacity: 1;
  box-shadow: var(--shadow-md);
}

.gallery-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Seasonal Weather */
.weather-section {
  padding: 80px 0;
}

.season-tabs {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 40px;
}

.season-tab {
  padding: 12px 24px;
  border: none;
  background: var(--background-light);
  color: var(--text-color);
  border-radius: var(--border-radius);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: var(--transition);
}

.season-tab.active {
  background: var(--primary-color);
  color: white;
}

.weather-content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
}

.weather-card {
  background: white;
  padding: 30px;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-md);
}

.weather-stat {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
  color: var(--secondary-color);
}

.weather-highlights,
.weather-tips {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #eee;
}

.highlight-item,
.tip-item {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 10px;
  color: var(--text-color);
}

/* Responsive adjustments for new sections */
@media (max-width: 768px) {
  .ecosystem-content {
    grid-template-columns: 1fr;
  }

  .ecosystem-tabs,
  .season-tabs {
    flex-wrap: wrap;
  }

  .gallery-main {
    height: 300px;
  }

  .cultural-activities {
    grid-template-columns: 1fr;
  }
}

/* Practical Information */
.practical-section {
  padding: 80px 0;
  background-color: var(--background-light);
}

.practical-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
}

.info-card {
  background: white;
  padding: 30px;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-md);
}

.info-card h3 {
  color: var(--primary-color);
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 1.5rem;
}

.fee-list,
.transport-list,
.requirements-list {
  display: grid;
  gap: 15px;
}

.fee-item,
.transport-item,
.requirement-item {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.contact-list {
  display: grid;
  gap: 15px;
}

.contact-item {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--secondary-color);
}

/* Trip Planning */
.trip-planning {
  padding: 80px 0;
}

.trip-type-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  margin-bottom: 60px;
}

.trip-type-card {
  background: white;
  padding: 30px;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-md);
  cursor: pointer;
  transition: var(--transition);
}

.trip-type-card:hover,
.trip-type-card.active {
  transform: translateY(-5px);
  box-shadow: var(--shadow-lg);
}

.trip-type-card.active {
  border: 2px solid var(--primary-color);
}

.trip-type-card h3 {
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.trip-duration {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--secondary-color);
  margin: 10px 0;
}

.trip-highlights {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #eee;
}

.highlight-list {
  display: grid;
  gap: 10px;
}

.highlight-list li {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--text-color);
}

/* Essential Items */
.essentials-section {
  padding: 80px 0;
  background-color: var(--background-light);
}

.essentials-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
}

.essentials-card {
  background: white;
  padding: 30px;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-md);
}

.essentials-card h3 {
  color: var(--primary-color);
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 1.5rem;
}

.items-list {
  display: grid;
  gap: 12px;
}

.item {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--text-color);
}

/* Budget Estimates */
.budget-section {
  padding: 80px 0;
}

.budget-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
}

.budget-card {
  background: white;
  padding: 30px;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-md);
}

.budget-card h3 {
  color: var(--primary-color);
  margin-bottom: 1.5rem;
  text-align: center;
}

.budget-items {
  display: grid;
  gap: 15px;
}

.budget-item {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.budget-item span:last-child {
  color: var(--secondary-color);
  font-weight: 500;
}

/* Call to Action */
.cta-section {
  padding: 80px 0;
  background: linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)),
    url('https://images.unsplash.com/photo-mount-meru-sunset');
  background-size: cover;
  background-position: center;
  color: white;
  text-align: center;
}

.cta-content {
  max-width: 800px;
  margin: 0 auto;
}

.cta-content h2 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
}

.cta-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
}

.cta-btn {
  padding: 15px 30px;
  border-radius: var(--border-radius);
  font-size: 1.1rem;
  text-decoration: none;
  transition: var(--transition);
  display: flex;
  align-items: center;
  gap: 8px;
}

.cta-btn.primary {
  background: var(--primary-color);
  color: white;
}

.cta-btn.secondary {
  background: transparent;
  color: white;
  border: 2px solid white;
}

.cta-btn:hover {
  transform: translateY(-3px);
  box-shadow: var(--shadow-lg);
}

/* Additional Responsive Adjustments */
@media (max-width: 768px) {
  .practical-grid,
  .trip-type-grid,
  .essentials-grid,
  .budget-grid {
    grid-template-columns: 1fr;
  }

  .cta-buttons {
    flex-direction: column;
  }

  .cta-content h2 {
    font-size: 2rem;
  }
}

@media (max-width: 480px) {
  .fee-item,
  .transport-item,
  .budget-item {
    flex-direction: column;
    gap: 5px;
  }

  .cta-content h2 {
    font-size: 1.75rem;
  }
}

/* Hiking Trails Section */
.trails-section {
  padding: 80px 0;
  background-color: var(--background-light);
}

.trails-tabs {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 40px;
  flex-wrap: wrap;
}

.trail-tab {
  padding: 12px 24px;
  border: none;
  background: white;
  color: var(--text-color);
  border-radius: var(--border-radius);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: var(--transition);
  box-shadow: var(--shadow-sm);
}

.trail-tab.active {
  background: var(--primary-color);
  color: white;
  box-shadow: var(--shadow-md);
}

.trail-content {
  background: white;
  padding: 30px;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-md);
}

.trail-info h3 {
  color: var(--primary-color);
  margin-bottom: 1.5rem;
}

.trail-stats {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.trail-stats span {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--secondary-color);
}

.trail-highlights,
.trail-tips {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #eee;
}

.trail-highlights h4,
.trail-tips h4 {
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.trail-highlights ul,
.trail-tips ul {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 15px;
}

.trail-highlights li,
.trail-tips li {
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Park History Section */
.history-section {
  padding: 80px 0;
}

.history-tabs {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 40px;
  flex-wrap: wrap;
}

.history-tab {
  padding: 12px 24px;
  border: none;
  background: var(--background-light);
  color: var(--text-color);
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: var(--transition);
}

.history-tab.active {
  background: var(--primary-color);
  color: white;
}

.history-content {
  background: white;
  padding: 30px;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-md);
}

.establishment-content h3 {
  color: var(--primary-color);
  margin-bottom: 1.5rem;
}

.key-events {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #eee;
}

.key-events h4 {
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.key-events ul {
  display: grid;
  gap: 15px;
}

.key-events li {
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Flora Guide Section */
.flora-section {
  padding: 80px 0;
  background-color: var(--background-light);
}

.flora-zones {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
}

.flora-zone-card {
  background: white;
  padding: 30px;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-md);
}

.flora-zone-card h3 {
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.elevation {
  color: var(--secondary-color);
  margin-bottom: 1.5rem;
  font-style: italic;
}

.species-list {
  display: grid;
  gap: 20px;
}

.species-card {
  padding: 15px;
  background: var(--background-light);
  border-radius: var(--border-radius);
}

.species-card h4 {
  color: var(--primary-color);
  margin-bottom: 0.5rem;
}

.scientific-name {
  color: var(--secondary-color);
  font-style: italic;
  margin-bottom: 0.5rem;
}

/* Geological Features Section */
.geology-section {
  padding: 80px 0;
}

.geology-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
}

.geology-card {
  background: white;
  padding: 25px;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-md);
  display: flex;
  flex-direction: column;
  height: 100%;
}

.geology-card h3 {
  color: var(--primary-color);
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.feature-details {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.feature-meta {
  display: flex;
  gap: 15px;
  margin-bottom: 1rem;
  flex-wrap: wrap;
}

.feature-type,
.feature-age {
  color: var(--secondary-color);
  font-size: 0.9rem;
  background: var(--background-light);
  padding: 4px 12px;
  border-radius: 20px;
  display: inline-flex;
  align-items: center;
}

.feature-description {
  margin-bottom: 1rem;
  font-size: 0.95rem;
  line-height: 1.5;
}

.feature-list {
  margin-top: auto;
}

.feature-list h4 {
  color: var(--primary-color);
  margin-bottom: 0.75rem;
  font-size: 1.1rem;
}

.feature-list ul {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
}

.feature-list li {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 0.9rem;
  padding: 4px 8px;
  
  background: var(--background-light);
  border-radius: var(--border-radius);
  transition: var(--transition);
}

.feature-list li:hover {
  background: var(--primary-color);
  color: white;
}

/* Responsive adjustments for geology section */
@media (max-width: 768px) {
  .geology-card {
    padding: 20px;
  }

  .feature-list ul {
    grid-template-columns: 1fr;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .feature-list ul {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Research and Education Section */
.research-section {
  padding: 80px 0;
  background-color: var(--background-light);
}

.research-programs,
.educational-programs {
  margin-bottom: 60px;
}

.research-programs h3,
.educational-programs h3 {
  color: var(--primary-color);
  margin-bottom: 2rem;
}

.programs-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
}

.program-card {
  background: white;
  padding: 30px;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-md);
}

.program-card h4 {
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.duration,
.focus {
  color: var(--secondary-color);
  margin-bottom: 1rem;
}

.partners,
.activities-list {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #eee;
}

.partners h5,
.activities-list h5 {
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.partners ul,
.activities-list ul {
  display: grid;
  gap: 10px;
}

.activities-list li {
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Safety Guidelines Section */
.safety-section {
  padding: 80px 0;
}

.safety-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
}

.safety-card,
.emergency-card {
  background: white;
  padding: 30px;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-md);
}

.safety-card h3,
.emergency-card h3 {
  color: var(--primary-color);
  margin-bottom: 1.5rem;
}

.safety-rules {
  display: grid;
  gap: 15px;
}

.safety-rules li {
  display: flex;
  align-items: center;
  gap: 8px;
}

.emergency-contacts {
  margin-bottom: 20px;
}

.contact-item {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
}

.contact-type {
  color: var(--secondary-color);
}

.emergency-procedures {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #eee;
}

.emergency-procedures h4 {
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.emergency-procedures ul {
  display: grid;
  gap: 15px;
}

.emergency-procedures li {
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Additional Responsive Adjustments */
@media (max-width: 768px) {
  .trails-tabs,
  .history-tabs {
    flex-direction: column;
  }

  .trail-stats {
    flex-direction: column;
    gap: 10px;
  }

  .flora-zones,
  .geology-grid,
  .programs-grid,
  .safety-grid {
    grid-template-columns: 1fr;
  }
}

/* Park History Section - Additional Styles */
.conservation-content,
.cultural-content {
  padding: 20px;
}

.conservation-content h3,
.cultural-content h3 {
  color: var(--primary-color);
  margin-bottom: 1.5rem;
}

.conservation-timeline {
  display: grid;
  gap: 25px;
  padding: 20px 0;
}

.timeline-item {
  background: var(--background-light);
  padding: 20px;
  border-radius: var(--border-radius);
  position: relative;
  transition: var(--transition);
}

.timeline-item:hover {
  transform: translateX(10px);
  box-shadow: var(--shadow-md);
}

.timeline-item h4 {
  color: var(--secondary-color);
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 10px;
  font-size: 1.1rem;
}

.timeline-item p {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--text-color);
  margin-left: 20px;
}

.cultural-content p {
  margin-bottom: 1.5rem;
  line-height: 1.6;
}

.cultural-significance {
  background: var(--background-light);
  padding: 20px;
  border-radius: var(--border-radius);
}

.cultural-significance h4 {
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.cultural-significance ul {
  display: grid;
  gap: 15px;
}

.cultural-significance li {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  background: white;
  border-radius: var(--border-radius);
  transition: var(--transition);
}

.cultural-significance li:hover {
  transform: translateX(10px);
  box-shadow: var(--shadow-sm);
}

.cultural-significance li svg {
  color: var(--accent-color);
}

/* Responsive adjustments for history section */
@media (max-width: 768px) {
  .conservation-timeline {
    gap: 15px;
  }

  .timeline-item,
  .cultural-significance li {
    transform: none;
  }

  .timeline-item:hover,
  .cultural-significance li:hover {
    transform: none;
  }
} 