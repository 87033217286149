.fleets-container {
  padding: 0;
  margin: 0;
  width: 100%;
}

.fleets-hero {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    var(--hero-bg);
  background-size: cover;
  background-position: center;
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  padding: 2rem;
}

.fleets-hero h1 {
  font-size: 3.5rem;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.fleets-hero p {
  font-size: 1.5rem;
  max-width: 800px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.fleet-intro {
  padding: 4rem 2rem;
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
}

.fleet-intro h2 {
  color: #333;
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
}

.fleet-intro p {
  font-size: 1.2rem;
  color: #666;
  line-height: 1.6;
}

.fleet-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 2rem;
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
}

.fleet-card {
  background: white;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.fleet-image {
  height: 250px;
  overflow: hidden;
}

.fleet-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.fleet-card:hover .fleet-image img {
  transform: scale(1.05);
}

.fleet-content {
  padding: 1.5rem;
}

.fleet-content h3 {
  color: #333;
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

.fleet-content p {
  color: #666;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.fleet-features {
  background: #f9f9f9;
  padding: 1.5rem;
  border-radius: 10px;
}

.fleet-features h4 {
  color: #333;
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

.fleet-features ul {
  list-style: none;
  padding: 0;
}

.fleet-features li {
  color: #555;
  padding: 0.5rem 0;
  padding-left: 1.5rem;
  position: relative;
}

.fleet-features li::before {
  content: '✓';
  color: #4CAF50;
  position: absolute;
  left: 0;
}

.fleet-maintenance {
  background: #f5f5f5;
  padding: 4rem 2rem;
  margin-top: 4rem;
}

.fleet-maintenance h2 {
  text-align: center;
  color: #333;
  font-size: 2.5rem;
  margin-bottom: 3rem;
}

.maintenance-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  max-width: 1200px;
  margin: 0 auto;
  align-items: center;
}

.maintenance-text {
  color: #666;
}

.maintenance-text p {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.maintenance-text ul {
  list-style: none;
  padding: 0;
}

.maintenance-text li {
  padding: 0.5rem 0;
  padding-left: 1.5rem;
  position: relative;
}

.maintenance-text li::before {
  content: '•';
  color: #4CAF50;
  position: absolute;
  left: 0;
  font-size: 1.5rem;
  line-height: 1;
}

.maintenance-image {
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.maintenance-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 768px) {
  .fleets-hero h1 {
    font-size: 2.5rem;
  }

  .fleets-hero p {
    font-size: 1.2rem;
  }

  .fleet-grid {
    grid-template-columns: 1fr;
  }

  .maintenance-content {
    grid-template-columns: 1fr;
  }

  .maintenance-image {
    order: -1;
  }
} 