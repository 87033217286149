.faqs-container {
  --primary-color: #ff7b25;
  --secondary-color: #2d665f;
  --accent-color: #fdb913;
  --text-color: #333;
  --light-bg: #f5f5f5;
  --white: #ffffff;
  --border-radius: 8px;
  --box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  
  font-family: 'Poppins', sans-serif;
  color: var(--text-color);
}

.faqs-hero {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)), var(--hero-bg);
  background-size: cover;
  background-position: center;
  color: white;
  text-align: center;
  padding: 80px 20px;
  position: relative;
}

.faqs-hero h1 {
  font-size: 2.8rem;
  margin-bottom: 20px;
  font-weight: 700;
}

.faqs-hero p {
  font-size: 1.2rem;
  max-width: 700px;
  margin: 0 auto 30px;
  opacity: 0.9;
}

.faq-stats {
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-top: 30px;
}

.faq-stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.faq-stat-number {
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--accent-color);
}

.faq-stat-label {
  font-size: 1rem;
  opacity: 0.9;
}

.popular-question-banner {
  background-color: var(--secondary-color);
  padding: 20px;
  margin: -20px auto 30px;
  max-width: 900px;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  position: relative;
  top: -40px;
}

.popular-question-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: white;
}

.popular-tag {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  background-color: var(--accent-color);
  color: #333;
  padding: 4px 10px;
  border-radius: 50px;
  font-size: 0.8rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.popular-question-content h3 {
  margin: 10px 0;
  font-size: 1.3rem;
}

.view-answer-btn {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  padding: 8px 15px;
  border-radius: 50px;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;
  margin-top: 10px;
}

.view-answer-btn:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.faqs-content {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
}

.faqs-search {
  margin-bottom: 30px;
}

.search-input-container {
  position: relative;
  max-width: 600px;
  margin: 0 auto;
}

.search-icon {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #999;
}

.faqs-search input {
  width: 100%;
  padding: 15px 15px 15px 45px;
  border: 1px solid #ddd;
  border-radius: var(--border-radius);
  font-size: 1rem;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.faqs-search input:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 2px 8px rgba(255, 123, 37, 0.2);
}

.faq-categories {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 40px;
  justify-content: center;
}

.category-button {
  background-color: var(--light-bg);
  border: none;
  padding: 10px 20px;
  border-radius: 50px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 8px;
}

.category-button.active {
  background-color: var(--primary-color);
  color: white;
}

.category-count {
  background-color: rgba(0, 0, 0, 0.1);
  padding: 2px 8px;
  border-radius: 50px;
  font-size: 0.8rem;
}

.category-button.active .category-count {
  background-color: rgba(255, 255, 255, 0.2);
}

.faq-section {
  margin-bottom: 40px;
  border-radius: var(--border-radius);
  overflow: hidden;
  background-color: white;
  box-shadow: var(--box-shadow);
}

.faq-section h2 {
  padding: 20px;
  margin: 0;
  background-color: var(--secondary-color);
  color: white;
  font-size: 1.5rem;
}

.faq-item {
  border-bottom: 1px solid #eee;
}

.faq-item:last-child {
  border-bottom: none;
}

.faq-question {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.faq-question:hover {
  background-color: #f9f9f9;
}

.faq-question h3 {
  margin: 0;
  font-size: 1.1rem;
  font-weight: 500;
  flex: 1;
}

.toggle-icon {
  color: var(--primary-color);
  font-size: 1.2rem;
  transition: transform 0.3s ease;
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease, padding 0.5s ease;
  background-color: #f9f9f9;
}

.faq-answer.open {
  max-height: 1000px;
  padding: 20px;
}

.faq-answer p {
  margin: 0;
  line-height: 1.6;
}

.no-results {
  text-align: center;
  padding: 40px 20px;
  background-color: white;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  margin-bottom: 40px;
}

.no-results h3 {
  color: var(--secondary-color);
  margin-bottom: 10px;
}

.contact-section {
  text-align: center;
  padding: 40px 20px;
  background-color: white;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
}

.contact-section h2 {
  color: var(--secondary-color);
  margin-bottom: 15px;
}

.contact-options {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
  margin-top: 25px;
}

.contact-button {
  display: inline-block;
  padding: 12px 25px;
  background-color: var(--primary-color);
  color: white;
  text-decoration: none;
  border-radius: 50px;
  font-weight: 500;
  transition: all 0.3s ease;
}

.contact-button:hover {
  background-color: #e86a15;
  transform: translateY(-2px);
}

.contact-option {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 12px 20px;
  background-color: var(--light-bg);
  color: var(--text-color);
  text-decoration: none;
  border-radius: 50px;
  font-weight: 500;
  transition: all 0.3s ease;
}

.contact-option:hover {
  background-color: #eaeaea;
}

@media (max-width: 768px) {
  .faqs-hero h1 {
    font-size: 2rem;
  }
  
  .faq-stats {
    flex-direction: column;
    gap: 15px;
  }
  
  .faq-categories {
    flex-direction: column;
    align-items: stretch;
  }
  
  .category-button {
    text-align: left;
  }
  
  .contact-options {
    flex-direction: column;
  }
} 