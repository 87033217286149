.not-found-container {
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background: #f8f9fa; /* Fallback color */
  background: linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)),
              url('../../public/images/logo.png') center/cover;
}

.not-found-content {
  max-width: 600px;
  text-align: center;
  padding: 2rem;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.not-found-logo {
  width: 200px;
  height: auto;
  margin-bottom: 2rem;
}

.not-found-content h1 {
  color: #2c3e50;
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.not-found-message {
  color: #666;
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 2rem;
}

.not-found-suggestions {
  text-align: left;
  margin: 2rem 0;
  padding: 1rem;
  background: #f8f9fa;
  border-radius: 8px;
}

.not-found-suggestions h2 {
  color: #2c3e50;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.not-found-suggestions ul {
  list-style: none;
  padding: 0;
}

.not-found-suggestions li {
  color: #666;
  margin: 0.5rem 0;
  padding-left: 1.5rem;
  position: relative;
}

.not-found-suggestions li::before {
  content: "•";
  color: #e67e22;
  position: absolute;
  left: 0;
}

.not-found-buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 2rem;
}

.primary-button,
.secondary-button {
  padding: 0.8rem 1.5rem;
  border-radius: 6px;
  text-decoration: none;
  font-weight: 600;
  transition: all 0.3s ease;
}

.primary-button {
  background-color: #e67e22;
  color: white;
}

.primary-button:hover {
  background-color: #d35400;
}

.secondary-button {
  background-color: transparent;
  color: #e67e22;
  border: 2px solid #e67e22;
}

.secondary-button:hover {
  background-color: #e67e22;
  color: white;
}

@media (max-width: 768px) {
  .not-found-content {
    padding: 1.5rem;
  }

  .not-found-content h1 {
    font-size: 2rem;
  }

  .not-found-buttons {
    flex-direction: column;
  }

  .primary-button,
  .secondary-button {
    width: 100%;
    text-align: center;
    margin: 0.5rem 0;
  }
} 