:root {
  --primary-color: #ff7b25;
  --primary-dark: #e66000;
  --primary-light: #ffa366;
}

.home {
  padding-top: 0;
}

/* Enhanced Hero Section */
.hero {
  height: 75vh;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  padding: 0 20px;
  margin-top: 0;
  padding-top: 60px;
  margin-bottom: -4rem;
}

.hero-video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
}

.hero-video-container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
}

.hero-video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translateX(-50%) translateY(-50%);
  object-fit: cover;
}

.hero-content {
  max-width: 800px;
  padding: 0 2rem;
  z-index: 2;
  position: relative;
  z-index: 10;
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  background: rgba(115, 106, 106, 0.151);
  padding: 3rem;
  border-radius: 15px;
  backdrop-filter: blur(8px);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  border: 1px solid var(--primary-light);
  box-shadow: 0 8px 32px var(--primary-color);
}

.hero h1,
.hero p,
.hero .cta-button {
  position: relative;
  z-index: 3;
}

.hero h1 {
  font-size: 3.8rem;
  margin-bottom: 1rem;
  font-weight: 800;
  letter-spacing: 2px;
  line-height: 1.2;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.3);
  color: whitesmoke;
  opacity: 1;
}

.hero p {
  font-size: 1.6rem;
  max-width: 800px;
  line-height: 1.6;
  margin: 0 auto;
  padding: 0 1rem;
  color: white;
  opacity: 1;
}

/* Enhanced Featured Tours Section */
.featured-tours,
.why-choose-us {
  position: relative;
  background: #fff;
  z-index: 3;
  padding: 2rem 3rem 6rem;
}

.section-title {
  text-align: center;
  margin-bottom: 2rem;
  color: var(--primary-dark);
  font-size: 2.5rem;
  position: relative;
}

.section-title::after {
  content: '';
  display: block;
  width: 100px;
  height: 3px;
  background: var(--primary-color);
  margin: 1rem auto;
}

.tour-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 2.5rem;
  margin-top: 2rem;
}

.tour-card {
  background: white;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.tour-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 25px rgba(0,0,0,0.2);
}

.tour-card img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.tour-card:hover img {
  transform: scale(1.1);
}

.tour-info {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  border-top: 1px solid #eee;
  padding-top: 1rem;
}

.price {
  font-size: 1.8rem;
  font-weight: 700;
  color: var(--primary-color);
  display: block;
  margin-bottom: 0.5rem;
}

.tour-button {
  display: inline-block;
  width: auto;
  min-width: 120px;
  padding: 0.8rem 1.5rem;
  background: var(--primary-color);
  color: white;
  text-decoration: none;
  border-radius: 25px;
  font-weight: 600;
  transition: all 0.3s ease;
  text-align: center;
}

.tour-button:hover {
  background: var(--primary-dark);
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(255,123,37,0.3);
}

.tour-content {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  min-height: 300px;
}

.tour-duration {
  color: var(--primary-color);
  font-weight: 600;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.tour-content h3 {
  font-size: 1.4rem;
  margin-bottom: 1rem;
  color: #333;
}

.tour-content p {
  color: #666;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.tour-card h3 {
  color: var(--primary-dark);
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.tour-card p {
  color: #666;
  line-height: 1.6;
}

.tour-card .price {
  color: var(--primary-color);
  font-size: 1.25rem;
  font-weight: bold;
  margin-top: 1rem;
}

/* Features Section */
.features {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2.5rem;
  margin-top: 3rem;
  padding: 0 2rem;
}

.feature {
  text-align: center;
  padding: 2.5rem;
  background: white;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
  transition: transform 0.3s ease;
}

.feature:hover {
  transform: translateY(-5px);
}

.feature i {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 1.5rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero {
    height: 55vh;
    padding-top: 50px;
    margin-bottom: -2rem;
  }
  
  .hero-content {
    padding: 2rem;
    width: 95%;
  }

  .hero h1 {
    font-size: 2.5rem;
  }
  
  .hero p {
    font-size: 1.2rem;
  }
  
  .featured-tours,
  .why-choose-us {
    padding: 1.5rem 1.5rem 4rem;
  }

  .section-title {
    margin-bottom: 1.5rem;
  }
} 

/* CTA Button in Hero Section */
.cta-button {
  margin-top: 1rem;
  display: inline-block;
  padding: 1rem 2.5rem;
  background: var(--primary-color);
  color: white;
  text-decoration: none;
  border-radius: 50px;
  font-size: 1.2rem;
  font-weight: 600;
  border: 2px solid transparent;
  transition: all 0.3s ease;
  opacity: 1;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.cta-button:hover {
  background: white;
  color: var(--primary-color);
  border-color: var(--primary-color);
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0,0,0,0.2);
}

/* Testimonials Section */
.testimonials {
  padding: 6rem 2rem;
  background: #f9f9f9;
}

.testimonial-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2.5rem;
  max-width: 1400px;
  margin: 0 auto;
}

.testimonial-card {
  background: white;
  border-radius: 15px;
  padding: 2rem;
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
  transition: transform 0.3s ease;
}

.testimonial-card:hover {
  transform: translateY(-5px);
}

.testimonial-content p {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #555;
  margin-bottom: 1.5rem;
}

.testimonial-author {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.testimonial-author img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}

.testimonial-author h4 {
  color: var(--primary-dark);
  margin: 0;
}

.testimonial-author p {
  color: #666;
  margin: 0;
  font-size: 0.9rem;
}

/* CTA Section */
.cta-section {
  padding: 6rem 2rem;
  background: linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)),
              url('https://img.freepik.com/premium-photo/beautiful-tropical-island-zanzibar-aerial-view-sea-zanzibar-beach-tanzania_87394-11016.jpg?semt=ais_hybrid') center/cover fixed;
  color: white;
  text-align: center;
}

.cta-content {
  max-width: 800px;
  margin: 0 auto;
}

.cta-content h2 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.cta-content p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.cta-buttons {
  display: flex;
  gap: 1.5rem;
  justify-content: center;
  flex-wrap: wrap;
}

.cta-button {
  min-width: 200px;
  padding: 1rem 2rem;
  border-radius: 50px;
  font-size: 1.1rem;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
  text-align: center;
  display: inline-block;
}

.cta-button.primary {
  background: var(--primary-color);
  color: white;
  border: 2px solid var(--primary-color);
}

.cta-button.secondary {
  background: transparent;
  color: white;
  border: 2px solid white;
}

.cta-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0,0,0,0.2);
}

.cta-button.primary:hover {
  background: var(--primary-dark);
  border-color: var(--primary-dark);
}

.cta-button.secondary:hover {
  background: white;
  color: var(--primary-color);
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .cta-section {
    padding: 4rem 1rem;
  }

  .cta-content h2 {
    font-size: 2.2rem;
  }

  .cta-content p {
    font-size: 1.1rem;
    padding: 0 1rem;
  }

  .cta-buttons {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 0 1rem;
  }

  .cta-button {
    width: 100%;
    max-width: 300px;
    font-size: 1rem;
    padding: 0.9rem 1.5rem;
  }
}

/* Small screens */
@media (max-width: 480px) {
  .cta-content h2 {
    font-size: 1.8rem;
  }

  .cta-button {
    max-width: 250px;
  }
}

/* Adventure Types Section */
.adventure-types {
  padding: 6rem 2rem;
  background: #fff;
  position: relative;
}

.adventure-grid {
  display: flex;
  gap: 2rem;
  max-width: 1400px;
  margin: 0 auto;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding: 1rem 0;
}

.adventure-grid::-webkit-scrollbar {
  display: none;
}

.adventure-card {
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
  min-width: 350px;
  scroll-snap-align: start;
}

.adventure-card img {
  width: 100%;
  height: 400px;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.adventure-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 2rem;
  background: linear-gradient(to top, rgba(0,0,0,0.9), transparent);
  color: white;
  transform: translateY(100px);
  transition: transform 0.3s ease;
}

.adventure-card:hover .adventure-content {
  transform: translateY(0);
}

.adventure-card:hover img {
  transform: scale(1.1);
}

.adventure-content h3 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  font-weight: 700;
}

.adventure-content p {
  font-size: 1.1rem;
  margin-bottom: 1.5rem;
  opacity: 0.9;
}

.adventure-link {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  color: white;
  text-decoration: none;
  font-weight: 600;
  transition: all 0.3s ease;
}

.adventure-link:hover {
  color: var(--primary-color);
  gap: 1rem;
}

.adventure-types .scroll-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: white;
  border: none;
  box-shadow: 0 4px 15px rgba(0,0,0,0.15);
  cursor: pointer;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-color);
  font-size: 1.5rem;
  transition: all 0.3s ease;
}

.adventure-types .scroll-button:hover {
  background: var(--primary-color);
  color: white;
  transform: translateY(-50%) scale(1.1);
}

.adventure-types .scroll-left {
  left: 1rem;
}

.adventure-types .scroll-right {
  right: 1rem;
}

@media (max-width: 768px) {
  .adventure-card {
    min-width: 300px;
  }
  
  .adventure-content {
    transform: translateY(0);
  }
  
  .adventure-types .scroll-button {
    display: none;
  }
}

/* Stats Section */
.stats-section {
  padding: 6rem 2rem;
  background: linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)),
              url('/public/images/tripBanner.jpeg') center/cover fixed;
  color: white;
  position: relative;
  z-index: 2;
}

.stats-container {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  text-align: center;
}

.stat-item {
  padding: 2.5rem 2rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: all 0.3s ease;
}

.stat-item:hover {
  transform: translateY(-10px);
  background: rgba(255, 255, 255, 0.15);
  border-color: var(--primary-color);
}

.stat-icon {
  font-size: 3rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.stat-item h3 {
  font-size: 2.8rem;
  margin-bottom: 0.5rem;
  color: white;
  font-weight: 800;
}

.stat-item p {
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.9);
  font-weight: 500;
}

@media (max-width: 992px) {
  .stats-container {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
  }
}

@media (max-width: 480px) {
  .stats-container {
    grid-template-columns: 1fr;
  }
  
  .stat-item {
    padding: 2rem 1.5rem;
  }
  
  .stat-item h3 {
    font-size: 2.4rem;
  }
  
  .stat-item p {
    font-size: 1.1rem;
  }
}

/* Gallery Section */
.gallery-section {
  padding: 6rem 2rem;
  background: #f9f9f9;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
  max-width: 1400px;
  margin: 0 auto;
}

.gallery-item {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  aspect-ratio: 1;
}

.gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.gallery-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1.5rem;
  background: linear-gradient(to top, rgba(0,0,0,0.8), transparent);
  color: white;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.gallery-item:hover .gallery-overlay {
  opacity: 1;
}

.gallery-item:hover img {
  transform: scale(1.1);
}

/* Popular Safari Packages Section */
.featured-tours {
  padding: 8rem 2rem;
  background: linear-gradient(to bottom, #fff, #f9f9f9);
}

.tour-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
  max-width: 1400px;
  margin: 0 auto;
}

.tour-card {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 15px 35px rgba(0,0,0,0.1);
  transition: all 0.4s ease;
  background: white;
}

.tour-card:hover {
  transform: translateY(-15px);
  box-shadow: 0 20px 40px rgba(255, 123, 37, 0.15);
}

/* Why Choose Us Section */
.why-choose-us {
  padding: 8rem 2rem;
  background: linear-gradient(45deg, rgba(255,123,37,0.05), rgba(255,123,37,0.1));
  position: relative;
  overflow: hidden;
}

.features {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  max-width: 1400px;
  margin: 0 auto;
}

.feature {
  background: white;
  padding: 3rem 2rem;
  border-radius: 20px;
  text-align: center;
  transition: all 0.3s ease;
  position: relative;
  z-index: 1;
}

.feature::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(45deg, var(--primary-color), var(--primary-light));
  border-radius: 20px;
  opacity: 0;
  z-index: -1;
  transition: opacity 0.3s ease;
}

.feature:hover::before {
  opacity: 1;
}

.feature:hover {
  transform: translateY(-10px);
  color: white;
}

.feature:hover .feature-icon {
  color: white;
  transform: scale(1.2);
}

.feature-icon {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 1.5rem;
  transition: all 0.3s ease;
}

/* Top Destinations Section */
.destinations {
  padding: 8rem 2rem;
  background: #fff;
}

.destination-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 1.5rem;
  max-width: 1400px;
  margin: 0 auto;
}

.destination-card:nth-child(1) {
  grid-column: span 6;
  grid-row: span 2;
}

.destination-card:nth-child(2) {
  grid-column: span 6;
}

.destination-card:nth-child(3),
.destination-card:nth-child(4) {
  grid-column: span 3;
}

/* Testimonials Section */
.testimonials {
  padding: 8rem 2rem;
  background: linear-gradient(to right, #f9f9f9, #fff);
  position: relative;
}

.testimonial-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
  max-width: 1200px;
  margin: 0 auto;
}

.testimonial-card {
  background: white;
  padding: 3rem;
  border-radius: 20px;
  position: relative;
  transition: all 0.3s ease;
}

.testimonial-card::before {
  content: '"';
  position: absolute;
  top: 2rem;
  left: 2rem;
  font-size: 5rem;
  color: var(--primary-color);
  opacity: 0.1;
  font-family: serif;
}

.testimonial-content p {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #444;
  margin-bottom: 2rem;
  position: relative;
  z-index: 1;
}

.testimonial-author {
  display: flex;
  align-items: center;
  gap: 1rem;
  border-top: 1px solid #eee;
  padding-top: 1.5rem;
}

.testimonial-author img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid var(--primary-light);
}

/* Responsive Design */
@media (max-width: 1200px) {
  .tour-grid,
  .features {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .destination-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .destination-card:nth-child(1) {
    grid-column: span 2;
  }
  
  .destination-card:nth-child(2),
  .destination-card:nth-child(3),
  .destination-card:nth-child(4) {
    grid-column: span 1;
  }
}

@media (max-width: 768px) {
  .tour-grid,
  .features,
  .testimonial-grid {
    grid-template-columns: 1fr;
  }
  
  .destination-grid {
    grid-template-columns: 1fr;
  }
  
  .destination-card:nth-child(n) {
    grid-column: span 1;
  }
} 

/* Large Screens */
@media (min-width: 1400px) {
  .tour-grid,
  .features,
  .destination-grid,
  .testimonial-grid {
    max-width: 1400px;
    margin: 0 auto;
  }
}

/* Medium-Large Screens */
@media (max-width: 1200px) {
  .hero h1 {
    font-size: 4rem;
  }

  .tour-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .features {
    grid-template-columns: repeat(2, 1fr);
  }

  .destination-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .testimonial-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Tablets */
@media (max-width: 992px) {
  .hero {
    height: 80vh;
  }

  .hero h1 {
    font-size: 3.5rem;
    padding: 0 1rem;
  }

  .hero p {
    font-size: 1.4rem;
    padding: 0 2rem;
  }

  .section-title {
    font-size: 2.2rem;
  }

  .featured-tours,
  .why-choose-us,
  .destinations,
  .testimonials {
    padding: 4rem 1.5rem;
  }
}

/* Large Mobile */
@media (max-width: 768px) {
  .hero h1 {
    font-size: 2.8rem;
  }

  .hero p {
    font-size: 1.2rem;
  }

  .tour-grid,
  .features,
  .destination-grid,
  .testimonial-grid {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .destination-card:nth-child(n) {
    grid-column: span 1;
  }

  .cta-buttons {
    flex-direction: column;
    gap: 1rem;
  }

  .cta-button {
    width: 100%;
    text-align: center;
  }

  .stats-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Small Mobile */
@media (max-width: 480px) {
  .hero h1 {
    font-size: 2.2rem;
  }

  .hero p {
    font-size: 1rem;
  }

  .section-title {
    font-size: 1.8rem;
  }

  .stats-container {
    grid-template-columns: 1fr;
  }

  .testimonial-card {
    padding: 1.5rem;
  }

  .featured-tours,
  .why-choose-us,
  .destinations,
  .testimonials {
    padding: 3rem 1rem;
  }

  .tour-card,
  .destination-card,
  .testimonial-card {
    margin: 0 0.5rem;
  }
}

/* Height-based media queries */
@media (max-height: 700px) {
  .hero {
    height: 100vh;
  }

  .hero h1 {
    font-size: 2.5rem;
  }
}

/* Landscape mode */
@media (max-height: 500px) and (orientation: landscape) {
  .hero {
    height: 120vh;
  }

  .hero-content {
    padding: 2rem 0;
  }
}

/* High-resolution screens */
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .hero,
  .cta-section {
    background-size: cover;
    background-position: center;
  }
}

/* Enhanced Package Modal Styles */
.package-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.85);
  backdrop-filter: blur(8px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 20px;
  overflow-y: auto;
  animation: fadeIn 0.3s ease-out;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.package-modal {
  background: white;
  border-radius: 20px;
  width: 100%;
  max-width: 1100px;
  max-height: 90vh;
  overflow: hidden;
  display: grid;
  grid-template-columns: 45% 55%;
  position: relative;
  animation: scaleIn 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  box-shadow: 0 30px 70px rgba(0,0,0,0.5);
  border: 1px solid rgba(255,255,255,0.1);
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(0.9) translateY(30px);
  }
  to {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

.modal-close {
  position: absolute;
  top: 20px;
  right: 25px;
  background: rgba(255,255,255,0.2);
  border: none;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  transition: all 0.3s;
  color: white;
  box-shadow: 0 5px 15px rgba(0,0,0,0.2);
}

.modal-close:hover {
  background: var(--primary-color);
  transform: rotate(90deg) scale(1.1);
}

.modal-image {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.modal-image::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0,0,0,0.2), rgba(0,0,0,0.7));
  z-index: 1;
  pointer-events: none;
}

.modal-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px 0 0 20px;
  transition: transform 15s ease;
}

.package-modal:hover .modal-image img {
  transform: scale(1.15);
}

.modal-badges {
  position: absolute;
  top: 25px;
  left: 25px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  z-index: 2;
}

.package-duration-badge,
.package-featured-badge {
  background: rgba(255,255,255,0.95);
  color: var(--accent-color);
  padding: 10px 20px;
  border-radius: 50px;
  font-weight: 700;
  font-size: 0.95rem;
  display: flex;
  align-items: center;
  gap: 8px;
  box-shadow: 0 8px 20px rgba(0,0,0,0.2);
  animation: fadeInRight 0.6s ease forwards;
  animation-delay: 0.2s;
  opacity: 0;
  transform: translateX(-20px);
}

.package-featured-badge {
  background: linear-gradient(135deg, var(--primary-color), var(--primary-dark));
  color: white;
  animation-delay: 0.4s;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(-30px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.modal-content {
  padding: 50px;
  overflow-y: auto;
  background: linear-gradient(to bottom, #fff, #f9f9f9);
  position: relative;
}

.modal-content::-webkit-scrollbar {
  width: 8px;
}

.modal-content::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.modal-content::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 4px;
}

.modal-content::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}

.modal-content h2 {
  font-size: 2.6rem;
  color: var(--accent-color);
  margin-bottom: 30px;
  position: relative;
  padding-bottom: 20px;
  font-weight: 800;
  animation: fadeInUp 0.5s ease forwards;
  animation-delay: 0.1s;
  opacity: 0;
  transform: translateY(20px);
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.modal-content h2::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100px;
  height: 5px;
  background: linear-gradient(to right, var(--primary-color), var(--primary-light));
  border-radius: 5px;
}

.modal-content h3 {
  font-size: 1.6rem;
  color: var(--accent-color);
  margin-bottom: 20px;
  margin-top: 40px;
  display: flex;
  align-items: center;
  gap: 12px;
  animation: fadeInUp 0.5s ease forwards;
  opacity: 0;
}

.modal-description h3 { animation-delay: 0.2s; }
.modal-highlights h3 { animation-delay: 0.3s; }
.modal-includes h3 { animation-delay: 0.4s; }

.modal-content h3::before {
  content: '';
  display: block;
  width: 8px;
  height: 25px;
  background: var(--primary-color);
  border-radius: 4px;
}

.modal-description p {
  line-height: 1.9;
  color: #555;
  margin-bottom: 20px;
  font-size: 1.05rem;
  animation: fadeInUp 0.5s ease forwards;
  animation-delay: 0.25s;
  opacity: 0;
}

.modal-highlights ul,
.modal-includes ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.modal-highlights ul {
  animation: fadeInUp 0.5s ease forwards;
  animation-delay: 0.35s;
  opacity: 0;
}

.modal-includes ul {
  animation: fadeInUp 0.5s ease forwards;
  animation-delay: 0.45s;
  opacity: 0;
}

.modal-highlights li,
.modal-includes li {
  position: relative;
  padding-left: 35px;
  margin-bottom: 15px;
  line-height: 1.7;
  color: #555;
  transition: all 0.3s;
}

.modal-highlights li:hover,
.modal-includes li:hover {
  transform: translateX(5px);
  color: var(--accent-color);
}

.modal-highlights li::before,
.modal-includes li::before {
  content: '✓';
  position: absolute;
  left: 0;
  top: 0;
  color: var(--primary-color);
  font-weight: bold;
  font-size: 1.2rem;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 45px;
  padding-top: 30px;
  border-top: 1px solid #eee;
  animation: fadeInUp 0.5s ease forwards;
  animation-delay: 0.55s;
  opacity: 0;
}

.modal-price {
  display: flex;
  flex-direction: column;
}

.modal-price span:first-child {
  font-size: 2.2rem;
  font-weight: 800;
  color: var(--primary-color);
  line-height: 1;
  text-shadow: 0 2px 5px rgba(255, 123, 37, 0.2);
}

.modal-price .price-per {
  font-size: 1rem;
  color: #777;
  margin-top: 5px;
}

.modal-buttons {
  display: flex;
  gap: 15px;
}

.explore-btn,
.book-now-btn {
  padding: 14px 28px;
  border-radius: 50px;
  text-decoration: none;
  font-weight: 600;
  font-size: 1.05rem;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.explore-btn {
  background: transparent;
  color: var(--accent-color);
  border: 2px solid var(--accent-color);
  z-index: 1;
}

.explore-btn::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: var(--accent-color);
  transition: all 0.4s;
  z-index: -1;
}

.explore-btn:hover {
  color: white;
  transform: translateY(-5px);
}

.explore-btn:hover::after {
  width: 100%;
}

.book-now-btn {
  background: linear-gradient(135deg, var(--primary-color), var(--primary-dark));
  color: white;
  box-shadow: 0 8px 20px rgba(255, 123, 37, 0.3);
  z-index: 1;
}

.book-now-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, var(--primary-dark), var(--primary-color));
  transition: all 0.4s;
  z-index: -1;
}

.book-now-btn:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 30px rgba(255, 123, 37, 0.4);
}

.book-now-btn:hover::before {
  left: 0;
}

.book-now-btn::after {
  content: '→';
  margin-left: 8px;
  transition: transform 0.3s;
}

.book-now-btn:hover::after {
  transform: translateX(5px);
}

/* Responsive modal */
@media (max-width: 992px) {
  .package-modal {
    grid-template-columns: 1fr;
    max-width: 650px;
  }
  
  .modal-image {
    height: 350px;
  }
  
  .modal-image img {
    border-radius: 20px 20px 0 0;
  }
  
  .modal-content {
    padding: 35px;
  }
}

@media (max-width: 576px) {
  .package-modal-overlay {
    padding: 15px;
  }
  
  .modal-image {
    height: 250px;
  }
  
  .modal-content {
    padding: 25px 20px;
  }
  
  .modal-content h2 {
    font-size: 1.8rem;
    margin-bottom: 20px;
    padding-bottom: 15px;
  }
  
  .modal-content h3 {
    font-size: 1.3rem;
    margin-top: 25px;
  }
  
  .modal-footer {
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;
  }
  
  .modal-price {
    align-items: center;
  }
  
  .modal-buttons {
    width: 100%;
    gap: 10px;
  }
  
  .explore-btn,
  .book-now-btn {
    flex: 1;
    text-align: center;
    padding: 12px 15px;
    font-size: 0.95rem;
  }
} 

/* Enhanced Package Card Styling */
.package-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;
  padding-top: 1.2rem;
  border-top: 1px solid rgba(0,0,0,0.08);
  position: relative;
}

.package-meta::before {
  content: '';
  position: absolute;
  top: -1px;
  left: 0;
  width: 50px;
  height: 3px;
  background: linear-gradient(to right, var(--primary-color), var(--primary-light));
  border-radius: 3px;
  transition: width 0.4s ease;
}

.package-card:hover .package-meta::before {
  width: 100%;
}

.package-price {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-left: 10px;
}

.package-price::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 3px;
  height: 70%;
  background: var(--primary-color);
  border-radius: 3px;
}

.package-price span {
  font-size: 0.85rem;
  color: #777;
  margin-bottom: 2px;
  font-weight: 500;
}

.package-price strong {
  font-size: 1.8rem;
  color: var(--accent-color);
  font-weight: 800;
  line-height: 1;
  background: linear-gradient(135deg, var(--accent-color), #555);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: -0.5px;
}

.view-details-btn {
  background: transparent;
  color: #333;
  border: 2px solid var(--primary-color);
  padding: 10px 20px;
  border-radius: 50px;
  font-weight: 700;
  font-size: 0.95rem;
  cursor: pointer;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  position: relative;
  overflow: hidden;
  z-index: 1;
  display: flex;
  align-items: center;
  gap: 8px;
  letter-spacing: 0.5px;
}

.view-details-btn::after {
  content: '→';
  font-size: 1.1rem;
  transition: transform 0.3s ease;
  margin-left: 2px;
}

.view-details-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: var(--primary-color);
  transition: all 0.4s ease;
  z-index: -1;
}

.view-details-btn:hover {
  color: white;
  transform: translateY(-3px);
  box-shadow: 0 8px 20px rgba(255, 123, 37, 0.3);
}

.view-details-btn:hover::before {
  width: 100%;
}

.view-details-btn:hover::after {
  transform: translateX(5px);
}

.package-card:hover .view-details-btn {
  border-color: var(--primary-color);
}

/* Add this animation for the price when hovering over the card */
.package-card:hover .package-price strong {
  background: linear-gradient(135deg, var(--primary-color), var(--primary-dark));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

/* Responsive adjustments */
@media (max-width: 576px) {
  .package-meta {
    flex-direction: column;
    gap: 15px;
    align-items: flex-start;
  }
  
  .package-price {
    padding-left: 0;
  }
  
  .package-price::before {
    display: none;
  }
  
  .view-details-btn {
    width: 100%;
    justify-content: center;
  }
} 

/* Updated Google Reviews Section */
.google-reviews-section {
  padding: 6rem 2rem;
  background: linear-gradient(to bottom, #f9f9f9, #fff);
  position: relative;
  overflow: hidden;
}

.google-reviews-section::before {
  content: '';
  position: absolute;
  top: -150px;
  right: -150px;
  width: 300px;
  height: 300px;
  background: var(--primary-light);
  opacity: 0.05;
  border-radius: 50%;
}

.reviews-container {
  max-width: 1400px;
  margin: 2rem auto 0;
  padding: 2rem;
  border-radius: 15px;
  background: white;
  box-shadow: 
    0 15px 40px rgba(0,0,0,0.08),
    0 0 20px rgba(255, 123, 37, 0.1),
    0 0 80px rgba(255, 123, 37, 0.05);
  overflow: hidden;
  position: relative;
  border: 1px solid rgba(255, 123, 37, 0.1);
}

/* Add shine effect */
.reviews-container::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  transform: rotate(30deg);
  animation: shine 8s infinite linear;
  pointer-events: none;
}

@keyframes shine {
  0% {
    transform: rotate(30deg) translateX(-100%);
  }
  100% {
    transform: rotate(30deg) translateX(100%);
  }
}

/* Add a subtle glow pulse */
.google-reviews-section {
  position: relative;
}

.google-reviews-section::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  background: radial-gradient(circle, rgba(255, 123, 37, 0.05) 0%, rgba(255, 123, 37, 0) 70%);
  z-index: -1;
  animation: pulse-glow 4s infinite alternate ease-in-out;
}

@keyframes pulse-glow {
  0% {
    opacity: 0.5;
    transform: translate(-50%, -50%) scale(0.8);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1.2);
  }
}

/* Add this to ensure the Elfsight widget has enough space */
.elfsight-app-83a82b91-c1f2-471f-89cf-d1d12175f139 {
  width: 100%;
  min-height: 500px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .google-reviews-section {
    padding: 4rem 1.5rem;
  }
}

@media (max-width: 576px) {
  .google-reviews-section {
    padding: 3rem 1rem;
  }
  
  .reviews-container {
    padding: 1rem;
  }
} 

/* Partners section on home page */
.home-partners-section {
  padding: 60px 0;
  background-color: #fff;
  position: relative;
}

.home-partners-section .partners-section {
  padding-top: 0;
}

/* Adjust spacing for home page layout */
.home-partners-section .main-partner-container {
  margin-bottom: 40px;
}

/* Make sure the container has proper width */
.home-partners-section .container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
}

/* Add some additional styling to make it visually consistent */
.home-partners-section::before {
  content: '';
  position: absolute;
  top: -150px;
  right: -150px;
  width: 300px;
  height: 300px;
  background: var(--primary-light);
  opacity: 0.05;
  border-radius: 50%;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .home-partners-section {
    padding: 40px 0;
  }
} 
