/* Professional styling for Tarangire National Park page */

:root {
  --primary-color: #2c5530;
  --secondary-color: #8b7355;
  --accent-color: #e6b17e;
  --text-color: #333;
  --light-text: #666;
  --white: #ffffff;
  --light-bg: #f8f9fa;
  --border-radius: 8px;
  --box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  --transition: all 0.3s ease;
}

.park-page {
  color: var(--text-color);
  font-family: 'Open Sans', sans-serif;
  line-height: 1.6;
}

/* Hero Section */
.park-hero {
  height: 80vh;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
              url('https://img.freepik.com/free-photo/tarangire-landscape_181624-48209.jpg');
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--white);
  margin-bottom: 4rem;
}

.hero-content h1 {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.hero-content p {
  font-size: 1.5rem;
  max-width: 800px;
  margin: 0 auto;
}

/* Section Styling */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

section {
  padding: 4rem 0;
}

section h2 {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 2rem;
  text-align: center;
  position: relative;
}

section h2::after {
  content: '';
  display: block;
  width: 60px;
  height: 3px;
  background: var(--accent-color);
  margin: 1rem auto;
}

/* Key Features */
.key-features {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 3rem;
}

.feature {
  background: var(--white);
  padding: 2rem;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  text-align: center;
  transition: var(--transition);
}

.feature:hover {
  transform: translateY(-5px);
}

.feature svg {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

/* Wildlife Cards */
.wildlife-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.wildlife-card {
  background: var(--white);
  border-radius: var(--border-radius);
  overflow: hidden;
  box-shadow: var(--box-shadow);
  transition: var(--transition);
}

.wildlife-card:hover {
  transform: translateY(-5px);
}

.wildlife-image {
  height: 250px;
  overflow: hidden;
}

.wildlife-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: var(--transition);
}

.wildlife-card:hover .wildlife-image img {
  transform: scale(1.05);
}

.wildlife-content {
  padding: 1.5rem;
}

/* Tabs */
.tab-navigation {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  gap: 1rem;
}

.tab-btn {
  padding: 0.75rem 1.5rem;
  border: none;
  background: var(--light-bg);
  color: var(--text-color);
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: var(--transition);
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.tab-btn.active {
  background: var(--primary-color);
  color: var(--white);
}

.tab-btn svg {
  font-size: 1.2rem;
}

/* Booking Form */
.booking-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 2rem;
  background: var(--white);
  padding: 2rem;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: var(--border-radius);
  font-size: 1rem;
}

.submit-btn {
  background: var(--primary-color);
  color: var(--white);
  padding: 1rem 2rem;
  border: none;
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: var(--transition);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  justify-content: center;
}

.submit-btn:hover {
  background: var(--secondary-color);
}

/* Interactive Elements */
.map-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 3rem;
  margin-top: 3rem;
  position: relative;
}

.map-interactive {
  position: relative;
  height: 800px;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  background: var(--white);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
}

.map-interactive img {
  width: auto;
  height: 150%;
  max-width: none;
  object-fit: contain;
  border-radius: 20px;
  transition: transform 1.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform-origin: left center;
  mix-blend-mode: multiply;
  transform: scale(1.3) translateX(-10%);
  margin-right: -15%;
}

.map-interactive:hover img {
  transform: scale(1.5) translateX(-10%);
}

.map-hotspot {
  position: absolute;
  width: 24px;
  height: 24px;
  transform: translate(-50%, -50%) scale(0.77);
  cursor: pointer;
  z-index: 2;
  transition: transform 1.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.map-interactive:hover .map-hotspot {
  transform: translate(-50%, -50%) scale(0.67);
}

.map-hotspot::before {
  content: '';
  position: absolute;
  width: 12px;
  height: 12px;
  background: var(--accent-color);
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease;
}

.map-hotspot::after {
  content: '';
  position: absolute;
  width: 24px;
  height: 24px;
  border: 2px solid var(--accent-color);
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: pulse-map 2s infinite;
}

@keyframes pulse-map {
  0% {
    transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(2);
    opacity: 0;
  }
}

.hotspot-content {
  position: absolute;
  bottom: calc(100% + 15px);
  left: 50%;
  transform: translateX(-50%);
  width: 280px;
  background: var(--white);
  padding: 1.5rem;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease, transform 1.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  pointer-events: none;
  transform-origin: bottom center;
}

.hotspot-content::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid var(--white);
}

.map-hotspot:hover::before {
  transform: translate(-50%, -50%) scale(1.5);
  background: var(--primary-color);
}

.map-hotspot:hover .hotspot-content {
  opacity: 1;
  visibility: visible;
  transform: translateX(-50%) translateY(-10px) scale(0.87);
}

.hotspot-content h3 {
  color: var(--primary-color);
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.hotspot-content p {
  color: var(--text-color);
  font-size: 0.95rem;
  line-height: 1.5;
}

/* Timeline */
.timeline {
  position: relative;
  max-width: 800px;
  margin: 0 auto;
}

.timeline-item {
  display: grid;
  grid-template-columns: 100px 1fr;
  gap: 2rem;
  margin-bottom: 2rem;
  opacity: 0;
  transform: translateY(20px);
  transition: var(--transition);
}

.timeline-item.animate-in {
  opacity: 1;
  transform: translateY(0);
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero-content h1 {
    font-size: 2.5rem;
  }

  .booking-container {
    grid-template-columns: 1fr;
  }

  .timeline-item {
    grid-template-columns: 1fr;
    text-align: center;
  }
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-in {
  animation: fadeIn 0.6s ease forwards;
}

/* Scroll Progress Bar */
.scroll-progress {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  z-index: 1000;
}

.progress-bar {
  height: 100%;
  background: var(--primary-color);
  width: 0;
  transition: width 0.3s ease;
}

/* Gallery View */
.gallery-view {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: var(--transition);
}

.gallery-view.active {
  opacity: 1;
  visibility: visible;
}

.gallery-image {
  max-width: 90%;
  max-height: 90vh;
  object-fit: contain;
}

.close-gallery {
  position: absolute;
  top: 2rem;
  right: 2rem;
  background: none;
  border: none;
  color: var(--white);
  font-size: 2rem;
  cursor: pointer;
}

/* Wildlife Spots */
.wildlife-spot {
  position: absolute;
  cursor: pointer;
}

.spot-indicator {
  width: 12px;
  height: 12px;
  background: var(--accent-color);
  border-radius: 50%;
  position: relative;
}

.spot-indicator::after {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  border: 2px solid var(--accent-color);
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(2);
    opacity: 0;
  }
}

/* Activity Levels */
.activity-level {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.5rem;
}

.activity-indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.activity-high { background: #28a745; }
.activity-moderate { background: #ffc107; }
.activity-low { background: #dc3545; }

/* Virtual Tour */
.virtual-viewer {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  margin-bottom: 2rem;
}

.virtual-viewer iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--border-radius);
}

/* Accommodation Section */
.accommodation-section {
  background: var(--light-bg);
  padding: 6rem 0;
}

.accommodation-grid {
  display: grid;
  gap: 4rem;
}

.accommodation-category h3 {
  font-size: 2rem;
  color: var(--primary-color);
  margin-bottom: 2rem;
  text-align: center;
}

.options-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 2.5rem;
}

.accommodation-card {
  background: var(--white);
  border-radius: var(--border-radius);
  overflow: hidden;
  box-shadow: var(--box-shadow);
  transition: var(--transition);
  position: relative;
}

.accommodation-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
}

.accommodation-image {
  height: 250px;
  overflow: hidden;
  position: relative;
}

.accommodation-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: var(--transition);
}

.accommodation-card:hover .accommodation-image img {
  transform: scale(1.1);
}

.accommodation-content {
  padding: 2rem;
}

.accommodation-content h4 {
  font-size: 1.5rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.accommodation-content p {
  color: var(--light-text);
  margin-bottom: 1.5rem;
  line-height: 1.6;
}

.features-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
  margin: 1.5rem 0;
}

.feature-tag {
  background: var(--light-bg);
  color: var(--primary-color);
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: var(--transition);
}

.feature-tag:hover {
  background: var(--primary-color);
  color: var(--white);
  transform: translateY(-2px);
}

.feature-tag svg {
  font-size: 1rem;
}

.price-category {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid var(--light-bg);
  color: var(--primary-color);
  font-weight: 600;
}

.price-category svg {
  color: var(--accent-color);
}

@media (max-width: 992px) {
  .seasons-grid {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .options-grid {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}

@media (max-width: 768px) {
  .season-card {
    padding: 2rem;
  }

  .accommodation-image {
    height: 200px;
  }

  .accommodation-content {
    padding: 1.5rem;
  }
}

/* Cultural Heritage Section */
.cultural-section {
  background: linear-gradient(rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.05));
  padding: 6rem 0;
  position: relative;
}

.cultural-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 200px;
  background: linear-gradient(to bottom, var(--light-bg), transparent);
}

.section-intro {
  text-align: center;
  max-width: 800px;
  margin: 0 auto 4rem;
  color: var(--light-text);
  font-size: 1.2rem;
  line-height: 1.8;
}

.cultural-activities {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
  gap: 3rem;
  position: relative;
  z-index: 1;
}

.cultural-card {
  background: var(--white);
  border-radius: var(--border-radius);
  overflow: hidden;
  box-shadow: var(--box-shadow);
  transition: var(--transition);
  position: relative;
  display: flex;
  flex-direction: column;
}

.cultural-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
}

.cultural-image {
  height: 280px;
  position: relative;
  overflow: hidden;
}

.cultural-image::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50%;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.7));
}

.cultural-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: var(--transition);
}

.cultural-card:hover .cultural-image img {
  transform: scale(1.1);
}

.cultural-content {
  padding: 2rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.cultural-content h3 {
  font-size: 1.8rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
  position: relative;
  padding-bottom: 1rem;
}

.cultural-content h3::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 3px;
  background: var(--accent-color);
}

.cultural-content p {
  color: var(--light-text);
  margin-bottom: 1.5rem;
  line-height: 1.7;
}

.duration {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--primary-color);
  font-weight: 500;
  margin-bottom: 1.5rem;
  padding: 0.5rem 1rem;
  background: var(--light-bg);
  border-radius: 20px;
  width: fit-content;
}

.duration svg {
  color: var(--accent-color);
}

.cultural-highlights {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1rem;
  margin-top: auto;
}

.highlight-badge {
  background: var(--light-bg);
  padding: 0.8rem 1rem;
  border-radius: var(--border-radius);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.95rem;
  color: var(--primary-color);
  transition: var(--transition);
}

.highlight-badge:hover {
  background: var(--primary-color);
  color: var(--white);
  transform: translateY(-2px);
}

.highlight-badge svg {
  color: var(--accent-color);
  font-size: 1.1rem;
  flex-shrink: 0;
}

.cultural-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background: linear-gradient(to right, var(--primary-color), var(--accent-color));
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.3s ease;
}

.cultural-card:hover::before {
  transform: scaleX(1);
}

@media (max-width: 992px) {
  .cultural-activities {
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  }
}

@media (max-width: 768px) {
  .cultural-image {
    height: 240px;
  }

  .cultural-content {
    padding: 1.5rem;
  }

  .cultural-content h3 {
    font-size: 1.5rem;
  }

  .cultural-highlights {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 480px) {
  .cultural-highlights {
    grid-template-columns: 1fr;
  }

  .section-intro {
    font-size: 1.1rem;
    padding: 0 1rem;
  }
}

/* Park Highlights Section */
.park-highlights {
  background: linear-gradient(to right, rgba(44, 85, 48, 0.05), rgba(139, 115, 85, 0.05));
  padding: 8rem 0;
  position: relative;
  overflow: hidden;
}

.park-highlights::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 300px;
  background: linear-gradient(135deg, var(--primary-color) 0%, transparent 100%);
  opacity: 0.05;
  transform: skewY(-5deg);
  transform-origin: top left;
}

.park-highlights::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 300px;
  background: linear-gradient(-135deg, var(--secondary-color) 0%, transparent 100%);
  opacity: 0.05;
  transform: skewY(5deg);
  transform-origin: bottom right;
}

.highlights-slider {
  position: relative;
  max-width: 1400px;
  margin: 0 auto;
  padding: 2rem;
}

.highlight-slide {
  display: none;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  background: var(--white);
  border-radius: 20px;
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
}

.highlight-slide.active {
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  gap: 0;
  opacity: 1;
  transform: translateY(0);
}

.highlight-image {
  position: relative;
  height: 500px;
  overflow: hidden;
}

.highlight-image::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to right, rgba(0,0,0,0.4), transparent);
  z-index: 1;
}

.highlight-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.8s ease;
}

.highlight-slide:hover .highlight-image img {
  transform: scale(1.1);
}

.highlight-content {
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  background: linear-gradient(135deg, var(--white) 0%, rgba(255,255,255,0.95) 100%);
}

.highlight-content::before {
  content: '';
  position: absolute;
  top: 2rem;
  left: 0;
  width: 5px;
  height: calc(100% - 4rem);
  background: linear-gradient(to bottom, var(--primary-color), var(--accent-color));
  border-radius: 0 3px 3px 0;
}

.highlight-content h3 {
  font-size: 2.2rem;
  color: var(--primary-color);
  margin-bottom: 1.5rem;
  position: relative;
  padding-bottom: 1rem;
  font-weight: 700;
}

.highlight-content h3::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 80px;
  height: 4px;
  background: linear-gradient(to right, var(--accent-color), transparent);
}

.highlight-content p {
  font-size: 1.1rem;
  line-height: 1.8;
  color: var(--text-color);
  margin-bottom: 2rem;
}

.highlight-fact {
  background: linear-gradient(to right, rgba(44, 85, 48, 0.1), rgba(230, 177, 126, 0.1));
  padding: 1.5rem;
  border-radius: 15px;
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: auto;
}

.highlight-fact svg {
  font-size: 1.8rem;
  color: var(--primary-color);
  flex-shrink: 0;
}

.highlight-fact span {
  font-size: 1.1rem;
  color: var(--text-color);
  line-height: 1.6;
}

.highlight-navigation {
  position: absolute;
  bottom: -3rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 1rem;
  z-index: 2;
}

.highlight-nav-btn {
  background: var(--white);
  border: none;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.highlight-nav-btn:hover {
  background: var(--primary-color);
  color: var(--white);
  transform: translateY(-3px);
}

.highlight-indicators {
  position: absolute;
  bottom: -2rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 0.8rem;
  z-index: 2;
}

.highlight-indicator {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: var(--light-bg);
  cursor: pointer;
  transition: all 0.3s ease;
}

.highlight-indicator.active {
  background: var(--primary-color);
  transform: scale(1.2);
}

.highlight-slide::after {
  content: '';
  position: absolute;
  top: 2rem;
  right: 2rem;
  width: 80px;
  height: 80px;
  background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%232c5530" opacity="0.1"><path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"/></svg>');
  background-size: contain;
  opacity: 0.1;
  z-index: 1;
}

@media (max-width: 1200px) {
  .highlight-slide.active {
    grid-template-columns: 1fr;
  }

  .highlight-image {
    height: 300px;
  }

  .highlight-content {
    padding: 2rem;
  }
}

@media (max-width: 768px) {
  .park-highlights {
    padding: 6rem 0;
  }

  .highlight-content h3 {
    font-size: 1.8rem;
  }

  .highlight-fact {
  padding: 1rem;
}

  .highlight-fact svg {
    font-size: 1.5rem;
  }

  .highlight-navigation {
    bottom: -2rem;
  }
}

@media (max-width: 480px) {
  .highlight-content {
    padding: 1.5rem;
  }

  .highlight-image {
    height: 250px;
  }

  .highlight-nav-btn {
    width: 40px;
    height: 40px;
  }
}

/* Park Zones Section */
.park-zones-section {
  background: linear-gradient(135deg, rgba(44, 85, 48, 0.03) 0%, rgba(139, 115, 85, 0.03) 100%);
  padding: 8rem 0;
  position: relative;
  overflow: hidden;
}

.park-zones-section::before {
  content: '';
  position: absolute;
  top: -100px;
  right: -100px;
  width: 400px;
  height: 400px;
  background: radial-gradient(circle, var(--accent-color) 0%, transparent 70%);
  opacity: 0.05;
  border-radius: 50%;
}

.park-zones-section::after {
  content: '';
  position: absolute;
  bottom: -100px;
  left: -100px;
  width: 400px;
  height: 400px;
  background: radial-gradient(circle, var(--primary-color) 0%, transparent 70%);
  opacity: 0.05;
  border-radius: 50%;
}

.zones-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
  gap: 2.5rem;
  position: relative;
  z-index: 1;
}

.zone-card {
  background: var(--white);
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
}

.zone-card:hover {
  transform: translateY(-10px) scale(1.02);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.12);
}

.zone-header {
  background: linear-gradient(to right, rgba(44, 85, 48, 0.05), rgba(139, 115, 85, 0.05));
  padding: 2rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  position: relative;
}

.zone-header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background: linear-gradient(to right, var(--primary-color), var(--accent-color));
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.4s ease;
}

.zone-card:hover .zone-header::before {
  transform: scaleX(1);
}

.zone-header h3 {
  font-size: 1.8rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.zone-header h3 svg {
  color: var(--accent-color);
}

.zone-description {
  color: var(--light-text);
  font-size: 1.1rem;
  line-height: 1.6;
}

.zone-details {
  padding: 2rem;
}

.wildlife-list, .activities-list {
  margin-bottom: 2rem;
}

.wildlife-list h4, .activities-list h4 {
  color: var(--primary-color);
  font-size: 1.2rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.wildlife-list h4 svg, .activities-list h4 svg {
  color: var(--accent-color);
}

.wildlife-list ul, .activities-list ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
}

.wildlife-list li, .activities-list li {
  background: linear-gradient(to right, rgba(44, 85, 48, 0.08), rgba(139, 115, 85, 0.08));
  padding: 0.6rem 1.2rem;
  border-radius: 20px;
  font-size: 0.95rem;
  color: var(--text-color);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.3s ease;
}

.wildlife-list li:hover, .activities-list li:hover {
  transform: translateY(-2px);
  background: linear-gradient(to right, rgba(44, 85, 48, 0.12), rgba(139, 115, 85, 0.12));
}

.zone-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
  margin-top: 2rem;
  padding-top: 1.5rem;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.zone-info p {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  color: var(--text-color);
  font-size: 1rem;
}

.zone-info svg {
  color: var(--accent-color);
  font-size: 1.2rem;
}

.zone-card.active {
  border: 2px solid var(--primary-color);
  transform: scale(1.02);
}

.zone-card.active::before {
  content: '';
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 30px;
  height: 30px;
  background: var(--primary-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
  font-size: 1rem;
  z-index: 2;
}

@media (max-width: 1200px) {
  .zones-grid {
    grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
  }
}

@media (max-width: 768px) {
  .park-zones-section {
    padding: 6rem 0;
  }

  .zone-header {
    padding: 1.5rem;
  }

  .zone-header h3 {
    font-size: 1.5rem;
  }

  .zone-details {
    padding: 1.5rem;
  }

  .zone-info {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
  }

@media (max-width: 480px) {
  .zones-grid {
    grid-template-columns: 1fr;
  }

  .wildlife-list ul, .activities-list ul {
    gap: 0.5rem;
  }

  .wildlife-list li, .activities-list li {
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  }
}

/* Park History & Conservation Section */
.park-history {
  background: linear-gradient(135deg, rgba(44, 85, 48, 0.02) 0%, rgba(139, 115, 85, 0.02) 100%);
  padding: 8rem 0;
  position: relative;
  overflow: hidden;
}

.park-history::before,
.park-history::after {
  content: '';
  position: absolute;
  width: 600px;
  height: 600px;
  border-radius: 50%;
  z-index: 0;
}

.park-history::before {
  top: -300px;
  right: -200px;
  background: radial-gradient(circle, var(--accent-color) 0%, transparent 70%);
  opacity: 0.03;
  animation: float 20s infinite alternate ease-in-out;
}

.park-history::after {
  bottom: -300px;
  left: -200px;
  background: radial-gradient(circle, var(--primary-color) 0%, transparent 70%);
  opacity: 0.03;
  animation: float 25s infinite alternate-reverse ease-in-out;
}

@keyframes float {
  0% { transform: translate(0, 0) rotate(0deg); }
  100% { transform: translate(50px, 50px) rotate(10deg); }
}

.timeline {
  position: relative;
  max-width: 1000px;
  margin: 4rem auto;
  padding: 0 2rem;
}

.timeline::before {
  content: '';
  position: absolute;
  left: calc(50% - 2px);
  top: 0;
  height: 100%;
  width: 4px;
  background: linear-gradient(to bottom, 
    transparent,
    var(--primary-color) 10%,
    var(--accent-color) 90%,
    transparent
  );
  border-radius: 2px;
}

.timeline-item {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 2rem;
  margin-bottom: 4rem;
  position: relative;
    opacity: 0;
  transform: translateY(30px);
  transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  }

.timeline-item.animate-in {
    opacity: 1;
    transform: translateY(0);
  }

.timeline-year {
  text-align: right;
  font-size: 2.5rem;
  font-weight: 700;
  color: var(--primary-color);
  opacity: 0.8;
  padding-right: 3rem;
  position: relative;
  align-self: center;
}

.timeline-year::after {
  content: '';
  position: absolute;
  right: -2rem;
  top: 50%;
  width: 20px;
  height: 20px;
  background: var(--white);
  border: 4px solid var(--accent-color);
  border-radius: 50%;
  transform: translateY(-50%);
  box-shadow: 0 0 0 6px rgba(230, 177, 126, 0.2);
  z-index: 1;
}

.timeline-content {
  background: var(--white);
  padding: 2rem;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
  position: relative;
  transition: transform 0.3s ease;
}

.timeline-content:hover {
  transform: translateY(-5px);
}

.timeline-content h3 {
  color: var(--primary-color);
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 600;
}

.timeline-content p {
  color: var(--light-text);
  line-height: 1.7;
}

.conservation-initiatives {
  margin-top: 6rem;
  position: relative;
  z-index: 1;
}

.conservation-initiatives h3 {
  text-align: center;
  font-size: 2.2rem;
  color: var(--primary-color);
  margin-bottom: 3rem;
  position: relative;
}

.conservation-initiatives h3::after {
  content: '';
  position: absolute;
  bottom: -1rem;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 4px;
  background: linear-gradient(to right, var(--primary-color), var(--accent-color));
  border-radius: 2px;
}

.initiatives-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2.5rem;
  padding: 0 2rem;
}

.initiative-card {
  background: var(--white);
  border-radius: 20px;
  padding: 2.5rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
  position: relative;
  overflow: hidden;
  transition: all 0.4s ease;
}

.initiative-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.12);
}

.initiative-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(to right, var(--primary-color), var(--accent-color));
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.4s ease;
}

.initiative-card:hover::before {
  transform: scaleX(1);
}

.initiative-card h4 {
  font-size: 1.4rem;
  color: var(--primary-color);
  margin-bottom: 1.5rem;
  position: relative;
  padding-left: 2rem;
}

.initiative-card h4::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 12px;
  height: 12px;
  background: var(--accent-color);
  border-radius: 50%;
}

.initiative-card p {
  color: var(--light-text);
  line-height: 1.7;
  margin-bottom: 2rem;
}

.impact-badge {
  background: linear-gradient(to right, rgba(44, 85, 48, 0.1), rgba(230, 177, 126, 0.1));
  padding: 1rem 1.5rem;
  border-radius: 15px;
  display: flex;
  align-items: center;
    gap: 1rem;
  }

.impact-badge svg {
  color: var(--primary-color);
  font-size: 1.2rem;
}

.impact-badge span {
  color: var(--text-color);
  font-weight: 500;
}

@media (max-width: 992px) {
  .timeline-item {
    grid-template-columns: 1fr;
    gap: 1rem;
    padding-left: 3rem;
  }

  .timeline::before {
    left: 1rem;
  }

  .timeline-year {
    text-align: left;
    padding-right: 0;
    padding-left: 3rem;
  }

  .timeline-year::after {
    left: -1rem;
    right: auto;
  }
}

@media (max-width: 768px) {
  .park-history {
    padding: 6rem 0;
  }

  .timeline-year {
    font-size: 2rem;
  }

  .initiatives-grid {
    gap: 2rem;
    padding: 0 1rem;
  }

  .initiative-card {
    padding: 2rem;
  }
}

@media (max-width: 480px) {
  .timeline {
    margin: 3rem auto;
  }

  .timeline-content {
    padding: 1.5rem;
  }

  .initiative-card h4 {
    font-size: 1.2rem;
  }
}

/* Park Map & Key Areas Section */
.interactive-map-section {
  background: linear-gradient(135deg, rgba(44, 85, 48, 0.03) 0%, rgba(139, 115, 85, 0.03) 100%);
  padding: 8rem 0;
  position: relative;
  overflow: hidden;
}

.interactive-map-section::before {
  content: '';
  position: absolute;
  top: -200px;
  right: -200px;
  width: 500px;
  height: 500px;
  background: radial-gradient(circle, var(--accent-color) 0%, transparent 70%);
  opacity: 0.05;
  border-radius: 50%;
  animation: float-slow 30s infinite alternate ease-in-out;
}

@keyframes float-slow {
  0% { transform: translate(0, 0) rotate(0deg); }
  100% { transform: translate(-50px, 50px) rotate(-10deg); }
}

.map-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 3rem;
  margin-top: 3rem;
  position: relative;
}

.map-interactive {
  position: relative;
  height: 800px;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  background: var(--white);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
}

.map-interactive img {
  width: auto;
  height: 150%;
  max-width: none;
  object-fit: contain;
  border-radius: 20px;
  transition: transform 1.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform-origin: left center;
  mix-blend-mode: multiply;
  transform: scale(1.3) translateX(-10%);
  margin-right: -15%;
}

.map-interactive:hover img {
  transform: scale(1.5) translateX(-10%);
}

.map-hotspot {
  position: absolute;
  width: 24px;
  height: 24px;
  transform: translate(-50%, -50%) scale(0.77);
  cursor: pointer;
  z-index: 2;
  transition: transform 1.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.map-interactive:hover .map-hotspot {
  transform: translate(-50%, -50%) scale(0.67);
}

.map-hotspot::before {
  content: '';
  position: absolute;
  width: 12px;
  height: 12px;
  background: var(--accent-color);
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease;
}

.map-hotspot::after {
  content: '';
  position: absolute;
  width: 24px;
  height: 24px;
  border: 2px solid var(--accent-color);
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: pulse-map 2s infinite;
}

@keyframes pulse-map {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(2);
    opacity: 0;
  }
}

.hotspot-content {
  position: absolute;
  bottom: calc(100% + 15px);
  left: 50%;
  transform: translateX(-50%);
  width: 280px;
  background: var(--white);
  padding: 1.5rem;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease, transform 1.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  pointer-events: none;
  transform-origin: bottom center;
}

.hotspot-content::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid var(--white);
}

.map-hotspot:hover::before {
  transform: translate(-50%, -50%) scale(1.5);
  background: var(--primary-color);
}

.map-hotspot:hover .hotspot-content {
  opacity: 1;
  visibility: visible;
  transform: translateX(-50%) translateY(-10px) scale(0.87);
}

.hotspot-content h3 {
  color: var(--primary-color);
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.hotspot-content p {
  color: var(--text-color);
  font-size: 0.95rem;
  line-height: 1.5;
}

.map-sidebar {
  background: var(--white);
  border-radius: 20px;
  padding: 2.5rem;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  height: 800px;
}

.map-sidebar h3 {
  color: var(--primary-color);
  font-size: 1.8rem;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 3px solid var(--accent-color);
  position: relative;
  flex-shrink: 0;
}

.location-list {
  list-style: none;
  padding: 0 1rem 0 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  flex-grow: 1;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--accent-color) transparent;
}

.location-list::-webkit-scrollbar {
  width: 6px;
}

.location-list::-webkit-scrollbar-track {
  background: transparent;
}

.location-list::-webkit-scrollbar-thumb {
  background-color: var(--accent-color);
  border-radius: 20px;
  border: 2px solid transparent;
}

.location-list li {
  background: linear-gradient(to right, rgba(44, 85, 48, 0.05), rgba(139, 115, 85, 0.05));
  padding: 1.5rem;
  border-radius: 15px;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid transparent;
  flex-shrink: 0;
}

.location-list li:hover,
.location-list li.active {
  transform: translateX(10px);
  background: linear-gradient(to right, rgba(44, 85, 48, 0.08), rgba(139, 115, 85, 0.08));
  border-color: var(--accent-color);
}

.location-list li h4 {
  color: var(--primary-color);
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.location-list li h4::before {
  content: '';
  width: 8px;
  height: 8px;
  background: var(--accent-color);
  border-radius: 50%;
  display: inline-block;
  transition: transform 0.3s ease;
}

.location-list li:hover h4::before,
.location-list li.active h4::before {
  transform: scale(1.5);
}

.location-list li p {
  color: var(--light-text);
  font-size: 0.95rem;
  line-height: 1.6;
  margin-left: 1.6rem;
}

@media (max-width: 1200px) {
  .map-container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .map-interactive {
    height: 700px;
  }
  
  .map-interactive img {
    height: 150%;
  }
}

@media (max-width: 768px) {
.interactive-map-section {
  padding: 6rem 0;
  }

  .map-interactive {
    height: 600px;
  }
  
  .map-interactive img {
    height: 140%;
  }

  .map-sidebar {
    padding: 2rem;
  }

  .hotspot-content {
    width: 240px;
    padding: 1.2rem;
  }
}

@media (max-width: 480px) {
  .map-interactive {
  height: 500px;
  }
  
  .map-interactive img {
    height: 130%;
  }

  .map-sidebar {
    padding: 1.5rem;
  }

  .location-list li {
    padding: 1.2rem;
  }

  .hotspot-content {
    width: 200px;
  }
}

/* Live Wildlife Tracking Section */
.wildlife-tracking-section {
  background: linear-gradient(135deg, rgba(44, 85, 48, 0.04) 0%, rgba(139, 115, 85, 0.04) 100%);
  padding: 8rem 0;
  position: relative;
  overflow: hidden;
}

.wildlife-tracking-section::before {
  content: '';
  position: absolute;
  top: -50%;
  right: -50%;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, var(--accent-color) 0%, transparent 70%);
  opacity: 0.03;
  animation: rotate 40s linear infinite;
}

.wildlife-tracking-section::after {
  content: '';
  position: absolute;
  bottom: -50%;
  left: -50%;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, var(--primary-color) 0%, transparent 70%);
  opacity: 0.03;
  animation: rotate-reverse 45s linear infinite;
}

@keyframes rotate {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@keyframes rotate-reverse {
  from { transform: rotate(360deg); }
  to { transform: rotate(0deg); }
}

.tracking-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  position: relative;
  z-index: 1;
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;
}

.tracking-card {
  background: var(--white);
  border-radius: 25px;
  padding: 0;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.6s forwards;
  min-width: 320px;
  max-width: 400px;
}

@keyframes fadeInUp {
  to {
    opacity: 1;
  transform: translateY(0);
  }
}

.tracking-card:hover {
  transform: translateY(-15px) scale(1.02);
  box-shadow: 0 30px 60px rgba(0, 0, 0, 0.15);
}

.species-info {
  padding: 2.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  position: relative;
  background: linear-gradient(to right, rgba(44, 85, 48, 0.03), rgba(139, 115, 85, 0.03));
}

.species-info::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(to right, var(--primary-color), var(--accent-color));
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.4s ease;
}

.tracking-card:hover .species-info::before {
  transform: scaleX(1);
}

.species-info h3 {
  font-size: 2rem;
  color: var(--primary-color);
  margin-bottom: 1.8rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  font-weight: 700;
  position: relative;
}

.species-info h3::after {
  content: '';
  position: absolute;
  bottom: -0.8rem;
  left: 0;
  width: 40px;
  height: 3px;
  background: var(--accent-color);
  transition: width 0.3s ease;
}

.tracking-card:hover .species-info h3::after {
  width: 80px;
}

.last-seen,
.frequency {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: var(--text-color);
  font-size: 1.1rem;
  padding: 1rem 1.5rem;
  border-radius: 15px;
  transition: all 0.3s ease;
  margin-bottom: 1rem;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
}

.last-seen {
  border-left: 4px solid var(--primary-color);
}

.frequency {
  border-left: 4px solid var(--accent-color);
}

.last-seen:hover,
.frequency:hover {
  transform: translateX(10px);
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.08);
}

.last-seen svg,
.frequency svg {
  font-size: 1.2rem;
  color: var(--primary-color);
  transition: transform 0.3s ease;
}

.tracking-card:hover .last-seen svg,
.tracking-card:hover .frequency svg {
  transform: scale(1.2);
}

.tracking-map {
  height: 220px;
  position: relative;
  overflow: hidden;
  background: linear-gradient(135deg, 
    rgba(44, 85, 48, 0.05) 0%,
    rgba(139, 115, 85, 0.05) 100%
  );
  padding: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.tracking-map img {
  width: 160%;
  height: 160%;
  object-fit: cover;
  border-radius: 12px;
  opacity: 0.9;
  mix-blend-mode: multiply;
  transform-origin: left center;
  transition: transform 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
  margin-right: -25%;
}

/* Zoom positions for different species */
.tracking-map.elephant-view img {
  transform: scale(1.8) translate(-20%, -5%);
}

.tracking-map.lion-view img {
  transform: scale(1.8) translate(-30%, -15%);
}

.tracking-map.leopard-view img {
  transform: scale(1.8) translate(-25%, -10%);
}

.sighting-marker {
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 2;
  transition: all 0.3s ease;
}

.sighting-marker::after {
  content: '';
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: pulse-sighting 2s infinite;
}

/* Recent sighting styles */
.sighting-marker.recent {
  background: #28a745;
}

.sighting-marker.recent::after {
  border: 2px solid #28a745;
}

/* Today sighting styles */
.sighting-marker.today {
  background: #17a2b8;
}

.sighting-marker.today::after {
  border: 2px solid #17a2b8;
}

/* Older sighting styles */
.sighting-marker.older {
  background: #ffc107;
}

.sighting-marker.older::after {
  border: 2px solid #ffc107;
}

.sighting-info {
  position: absolute;
  bottom: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  background: var(--white);
  padding: 0.8rem 1.2rem;
  border-radius: 8px;
  font-size: 0.9rem;
  white-space: nowrap;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  pointer-events: none;
  z-index: 3;
}

.sighting-info::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 8px;
  height: 8px;
  background: var(--white);
}

.sighting-marker:hover .sighting-info {
  opacity: 1;
  visibility: visible;
  transform: translateX(-50%) translateY(-5px);
}

.tracking-legend {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  background: rgba(255, 255, 255, 0.95);
  padding: 0.8rem;
  border-radius: 8px;
  font-size: 0.8rem;
  display: flex;
  gap: 1rem;
  z-index: 3;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.legend-item {
  display: flex;
  align-items: center;
  gap: 0.6rem;
}

.legend-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.recent-dot {
  background: #28a745;
}

.today-dot {
  background: #17a2b8;
}

.older-dot {
  background: #ffc107;
}

@media (max-width: 1400px) {
.tracking-grid {
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
  }
}

@media (max-width: 900px) {
  .tracking-grid {
    grid-template-columns: 1fr;
    justify-items: center;
}

.tracking-card {
    max-width: 450px;
  width: 100%;
  }
}

@media (max-width: 768px) {
  .wildlife-tracking-section {
  padding: 6rem 0;
  }

  .species-info {
    padding: 2rem;
  }

  .species-info h3 {
    font-size: 1.8rem;
  }

  .tracking-map {
    height: 180px;
  }

  .last-seen,
  .frequency {
    padding: 0.8rem 1.2rem;
  }
}

@media (max-width: 480px) {
  .tracking-grid {
    padding: 1rem;
    gap: 1.5rem;
  }

  .species-info {
    padding: 1.5rem;
  }

  .species-info h3 {
    font-size: 1.6rem;
  }

  .last-seen,
  .frequency {
    padding: 0.8rem 1rem;
    font-size: 1rem;
  }
}

/* Photography Guide Section */
.photography-guide-section {
  background: linear-gradient(135deg, rgba(44, 85, 48, 0.03) 0%, rgba(139, 115, 85, 0.03) 100%);
  padding: 8rem 0;
  position: relative;
  overflow: hidden;
}

.photography-guide-section::before,
.photography-guide-section::after {
  content: '';
  position: absolute;
  width: 600px;
  height: 600px;
  border-radius: 50%;
  z-index: 0;
}

.photography-guide-section::before {
  top: -200px;
  right: -100px;
  background: radial-gradient(circle, var(--accent-color) 0%, transparent 70%);
  opacity: 0.04;
  animation: float-photo 25s infinite alternate ease-in-out;
}

.photography-guide-section::after {
  bottom: -200px;
  left: -100px;
  background: radial-gradient(circle, var(--primary-color) 0%, transparent 70%);
  opacity: 0.04;
  animation: float-photo 30s infinite alternate-reverse ease-in-out;
}

@keyframes float-photo {
  0% { transform: translate(0, 0) rotate(0deg); }
  100% { transform: translate(30px, 30px) rotate(5deg); }
}

.photo-tips-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
  margin-bottom: 4rem;
  position: relative;
  z-index: 1;
}

.photo-tip-card {
  background: var(--white);
  padding: 2.5rem;
  border-radius: 20px;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.08);
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
}

.photo-tip-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 25px 45px rgba(0, 0, 0, 0.12);
}

.photo-tip-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(to right, var(--primary-color), var(--accent-color));
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.4s ease;
}

.photo-tip-card:hover::before {
  transform: scaleX(1);
}

.tip-icon {
  width: 60px;
  height: 60px;
  background: linear-gradient(135deg, var(--primary-color), var(--accent-color));
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
  color: var(--white);
  font-size: 1.8rem;
  transition: all 0.3s ease;
}

.photo-tip-card:hover .tip-icon {
  transform: scale(1.1) rotate(5deg);
}

.photo-tip-card h4 {
  color: var(--primary-color);
  font-size: 1.4rem;
  margin-bottom: 1rem;
  font-weight: 700;
}

.photo-tip-card p {
  color: var(--light-text);
  line-height: 1.7;
  font-size: 1.1rem;
}

.location-specific-tips {
  position: relative;
  z-index: 1;
  margin-top: 4rem;
}

.location-specific-tips h3 {
  text-align: center;
  font-size: 2.2rem;
  color: var(--primary-color);
  margin-bottom: 3rem;
  position: relative;
}

.location-specific-tips h3::after {
  content: '';
  position: absolute;
  bottom: -1rem;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 4px;
  background: linear-gradient(to right, var(--primary-color), var(--accent-color));
  border-radius: 2px;
}

.locations-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.location-card {
  background: var(--white);
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.location-card:hover {
  transform: translateY(-5px) scale(1.02);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.12);
}

.location-card h4 {
  color: var(--primary-color);
  font-size: 1.3rem;
  margin-bottom: 1.2rem;
  padding-bottom: 0.8rem;
  border-bottom: 2px solid var(--accent-color);
}

.location-card p {
  color: var(--light-text);
  margin-bottom: 1rem;
  line-height: 1.6;
}

.location-card p strong {
  color: var(--primary-color);
  font-weight: 600;
}

@media (max-width: 1200px) {
  .photo-tips-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .photography-guide-section {
    padding: 6rem 0;
  }

  .photo-tips-grid {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .photo-tip-card {
    padding: 2rem;
  }

  .location-card {
    padding: 1.5rem;
  }
}

@media (max-width: 480px) {
  .photo-tips-grid {
    grid-template-columns: 1fr;
  }

  .location-card {
    padding: 1.5rem;
  }

  .location-card h4 {
    font-size: 1.2rem;
  }
}

.seasonal-highlights {
  background: linear-gradient(135deg, rgba(44, 85, 48, 0.03) 0%, rgba(139, 115, 85, 0.03) 100%);
  padding: 8rem 0;
  position: relative;
  overflow: hidden;
}

.seasonal-highlights::before,
.seasonal-highlights::after {
  content: '';
  position: absolute;
  width: 600px;
  height: 600px;
  border-radius: 50%;
  z-index: 0;
}

.seasonal-highlights::before {
  top: -200px;
  right: -100px;
  background: radial-gradient(circle, var(--accent-color) 0%, transparent 70%);
  opacity: 0.04;
  animation: float-season 25s infinite alternate ease-in-out;
}

.seasonal-highlights::after {
  bottom: -200px;
  left: -100px;
  background: radial-gradient(circle, var(--primary-color) 0%, transparent 70%);
  opacity: 0.04;
  animation: float-season 30s infinite alternate-reverse ease-in-out;
}

@keyframes float-season {
  0% { transform: translate(0, 0) rotate(0deg); }
  100% { transform: translate(30px, 30px) rotate(5deg); }
}

.season-tabs {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 4rem;
  position: relative;
  z-index: 1;
}

.season-tab {
  padding: 1rem 2.5rem;
  background: var(--white);
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
  border: 2px solid transparent;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.season-tab.active {
  border-color: var(--accent-color);
  transform: translateY(-5px);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.12);
}

.season-tab:hover {
  transform: translateY(-5px);
}

.season-tab svg {
  font-size: 1.5rem;
  color: var(--accent-color);
}

.season-content {
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  gap: 4rem;
  align-items: center;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.6s ease;
  position: relative;
  z-index: 1;
}

.season-content.active {
  opacity: 1;
  transform: translateY(0);
}

.season-info {
  padding: 3rem;
  background: var(--white);
  border-radius: 25px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
}

.season-info::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(to right, var(--primary-color), var(--accent-color));
  transform: scaleX(0);
  transform-origin: left;
  transition: transform 0.4s ease;
}

.season-info:hover::before {
  transform: scaleX(1);
}

.season-info h3 {
  font-size: 2.2rem;
  color: var(--primary-color);
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  font-weight: 700;
  position: relative;
}

.highlights-list {
  margin: 2rem 0;
}

.highlight-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1.2rem;
  padding: 1rem;
  background: linear-gradient(to right, rgba(44, 85, 48, 0.05), rgba(139, 115, 85, 0.05));
  border-radius: 12px;
  transition: all 0.3s ease;
}

.highlight-item:hover {
  transform: translateX(10px);
  background: linear-gradient(to right, rgba(44, 85, 48, 0.08), rgba(139, 115, 85, 0.08));
}

.highlight-item svg {
  color: var(--accent-color);
  font-size: 1.2rem;
  flex-shrink: 0;
}

.season-image {
  position: relative;
  height: 600px;
  border-radius: 25px;
  overflow: hidden;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
}

.season-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.8s ease;
}

.season-image:hover img {
  transform: scale(1.1);
}

.season-details {
  margin-top: 2.5rem;
}

.details-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

.detail-box {
  background: linear-gradient(to right, rgba(44, 85, 48, 0.05), rgba(139, 115, 85, 0.05));
  padding: 1.5rem;
  border-radius: 15px;
  transition: all 0.3s ease;
}

.detail-box:hover {
  transform: translateY(-5px);
  background: linear-gradient(to right, rgba(44, 85, 48, 0.08), rgba(139, 115, 85, 0.08));
}

.detail-box h4 {
  color: var(--primary-color);
  font-size: 1.2rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.detail-box ul {
  list-style: none;
  padding: 0;
}

.detail-box li {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  margin-bottom: 0.8rem;
  color: var(--text-color);
}

.detail-box li::before {
  content: '';
  width: 6px;
  height: 6px;
  background: var(--accent-color);
  border-radius: 50%;
  flex-shrink: 0;
}

@media (max-width: 1200px) {
  .season-content {
    grid-template-columns: 1fr;
    gap: 3rem;
  }

  .season-image {
    height: 500px;
  }
}

@media (max-width: 768px) {
  .seasonal-highlights {
    padding: 6rem 0;
  }

  .season-tabs {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .season-info {
    padding: 2rem;
  }

  .season-image {
    height: 400px;
  }

  .details-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .season-content {
    padding: 1.5rem;
  }

  .season-image {
    height: 250px;
  }

  .details-grid {
    gap: 1rem;
  }
}

.wildlife-facts {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.facts-slider {
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
}

.fact-slide {
  display: none;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);
}

.fact-slide.active {
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  gap: 0;
  opacity: 1;
  transform: translateY(0);
}

.fact-image {
  position: relative;
  height: 500px;
  overflow: hidden;
}

.fact-image::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to right, rgba(0,0,0,0.4), transparent);
  z-index: 1;
}

.fact-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.8s ease;
}

.fact-slide:hover .fact-image img {
  transform: scale(1.1);
}

.fact-content {
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  background: linear-gradient(135deg, var(--white) 0%, rgba(255,255,255,0.95) 100%);
}

.fact-content::before {
  content: '';
  position: absolute;
  top: 2rem;
  left: 0;
  width: 5px;
  height: calc(100% - 4rem);
  background: linear-gradient(to bottom, var(--primary-color), var(--accent-color));
  border-radius: 0 3px 3px 0;
}

.fact-content h3 {
  font-size: 2.2rem;
  color: var(--primary-color);
  margin-bottom: 1.5rem;
  position: relative;
  padding-bottom: 1rem;
  font-weight: 700;
}

.fact-content h3::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 80px;
  height: 4px;
  background: linear-gradient(to right, var(--accent-color), transparent);
}

.fact-content p {
  font-size: 1.1rem;
  line-height: 1.8;
  color: var(--text-color);
  margin-bottom: 2rem;
}

.fact-navigation {
  position: absolute;
  bottom: -3rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 2rem;
  z-index: 2;
}

.nav-btn {
  background: var(--white);
  border: none;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  color: var(--primary-color);
}

.nav-btn:hover {
  background: var(--primary-color);
  color: var(--white);
  transform: translateY(-3px);
}

.fact-indicators {
  display: flex;
  gap: 0.8rem;
}

.indicator {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: var(--light-bg);
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.indicator.active {
  background: var(--primary-color);
  transform: scale(1.2);
}

@media (max-width: 1200px) {
  .fact-slide.active {
    grid-template-columns: 1fr;
  }

  .fact-image {
    height: 300px;
  }

  .fact-content {
    padding: 2rem;
  }
}

@media (max-width: 768px) {
  .wildlife-facts {
    padding: 1rem;
  }

  .fact-content h3 {
    font-size: 1.8rem;
  }

  .fact-navigation {
    bottom: -2rem;
  }
}

@media (max-width: 480px) {
  .fact-content {
    padding: 1.5rem;
  }

  .fact-image {
    height: 250px;
  }

  .nav-btn {
    width: 40px;
    height: 40px;
  }
}
