:root {
  /* Primary Colors */
  --primary-color: #ff7b25;
  --primary-dark: #e66000;
  --primary-light: #ffa366;

  /* Existing variables */
  --white: #ffffff;
  --black: #000000;
  --text-primary: #333333;
  --text-light: #666666;
  --background-light: #f5f5f5;
  --background-dark: #333333;
  
  /* Shadows */
  --shadow-sm: 0 2px 4px rgba(0,0,0,0.1);
  --shadow-md: 0 4px 8px rgba(0,0,0,0.1);
  --shadow-lg: 0 8px 16px rgba(0,0,0,0.1);
  
  /* Border */
  --border-radius: 8px;
  
  /* Transitions */
  --transition: all 0.3s ease;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
