/* Root Variables */
:root {
  --primary-color: #ff7b25;
  --primary-dark: #e66000;
  --primary-light: #ffa366;
  --accent-color: #ff7b25;
  --text-color: #333333;
  --text-light: #666666;
  --background-light: #f5f5f5;
  --white: #ffffff;
  --shadow-sm: 0 2px 4px rgba(0,0,0,0.1);
  --shadow-md: 0 4px 8px rgba(0,0,0,0.1);
  --shadow-lg: 0 8px 16px rgba(0,0,0,0.1);
  --border-radius: 12px;
  --transition: all 0.3s ease;
}

.park-page {
  padding-top: 80px;
}

/* Hero Section */
.park-hero {
  height: 70vh;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
}

.park-hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.6));
}

.hero-content {
  position: relative;
  z-index: 2;
  max-width: 800px;
  padding: 0 2rem;
}

.hero-content h1 {
  font-size: 4rem;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
}

/* Park-specific hero backgrounds using CSS gradients */
.gombe-hero {
  background-color: var(--primary-dark);
}

.mahale-hero {
  background-color: var(--primary-color);
}

.rubondo-hero {
  background-color: var(--primary-dark);
}

.burigi-hero {
  background-color: var(--primary-color);
}

.saanane-hero {
  background-color: var(--primary-dark);
}

.ugalla-hero {
  background-color: var(--primary-color);
}

/* Overview Section */
.park-overview {
  padding: 4rem 0;
  background: var(--white);
}

.overview-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.highlights-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.highlight-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background: var(--background-light);
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.highlight-item:hover {
  transform: translateY(-5px);
}

/* Activities Section */
.activities-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  padding: 2rem 0;
}

.activity-card {
  background: white;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  transition: transform 0.3s ease;
}

.activity-card:hover {
  transform: translateY(-5px);
}

.activity-meta {
  display: flex;
  gap: 2rem;
  margin-top: 1rem;
  color: #666;
}

/* Practical Info Section */
.info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.info-card {
  background: var(--background-light);
  padding: 2rem;
  border-radius: 12px;
}

/* Conservation Section */
.conservation {
  background: var(--background-light);
  padding: 4rem 0;
}

.conservation-initiatives {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.initiative-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

/* New sections */
.park-gallery {
  padding: 4rem 0;
  background: var(--background-light);
}

.gallery-filters {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
  flex-wrap: wrap;
}

.filter-btn {
  padding: 0.5rem 1.5rem;
  border: none;
  background: var(--white);
  border-radius: 25px;
  cursor: pointer;
  transition: var(--transition);
  box-shadow: var(--shadow-sm);
}

.filter-btn.active {
  background: var(--primary-color);
  color: var(--white);
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
  padding: 1rem;
}

.gallery-item {
  position: relative;
  border-radius: var(--border-radius);
  overflow: hidden;
  cursor: pointer;
  aspect-ratio: 3/2;
}

.gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.gallery-item:hover img {
  transform: scale(1.1);
}

.gallery-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(transparent, rgba(0,0,0,0.8));
  color: white;
  padding: 1.5rem;
  transform: translateY(100%);
  transition: transform 0.3s ease;
}

.gallery-item:hover .gallery-overlay {
  transform: translateY(0);
}

/* Modal Styles */
.gallery-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 2rem;
}

.modal-content {
  position: relative;
  max-width: 90vw;
  max-height: 90vh;
}

.modal-content img {
  max-width: 100%;
  max-height: 80vh;
  object-fit: contain;
}

.close-btn {
  position: absolute;
  top: -2rem;
  right: -2rem;
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 1001;
}

.nav-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0,0,0,0.5);
  border: none;
  color: white;
  padding: 1rem;
  cursor: pointer;
  border-radius: 50%;
  transition: var(--transition);
}

.nav-btn:hover {
  background: rgba(0,0,0,0.8);
}

.nav-btn.prev {
  left: -3rem;
}

.nav-btn.next {
  right: -3rem;
}

.modal-info {
  position: absolute;
  bottom: -4rem;
  left: 0;
  right: 0;
  color: white;
  text-align: center;
}

.image-meta {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 0.5rem;
}

.category-tag,
.location-tag,
.date-tag {
  background: rgba(255,255,255,0.2);
  padding: 0.25rem 0.75rem;
  border-radius: 15px;
  font-size: 0.875rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .gallery-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }

  .nav-btn {
    padding: 0.5rem;
  }

  .nav-btn.prev {
    left: -1rem;
  }

  .nav-btn.next {
    right: -1rem;
  }

  .modal-content img {
    max-height: 70vh;
  }
}

/* Wildlife Section */
.wildlife-section {
  padding: 4rem 0;
  background: #fff;
}

.wildlife-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.species-card {
  background: var(--background-light);
  border-radius: 12px;
  padding: 1.5rem;
  text-align: center;
  transition: transform 0.3s ease;
}

.species-card:hover {
  transform: translateY(-5px);
}

/* Booking Section */
.booking-section {
  padding: 6rem 0;
  background: linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)),
              var(--primary-color);
  color: var(--white);
}

.booking-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 3rem;
  max-width: 1200px;
  margin: 0 auto;
}

.booking-form {
  background: rgba(255, 255, 255, 0.1);
  padding: 2rem;
  border-radius: var(--border-radius);
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: var(--border-radius);
  background: rgba(255, 255, 255, 0.1);
  color: var(--white);
}

.form-group input::placeholder,
.form-group textarea::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.form-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.submit-btn {
  width: 100%;
  padding: 1rem;
  background: var(--accent-color);
  border: none;
  border-radius: var(--border-radius);
  color: var(--white);
  font-size: 1.1rem;
  cursor: pointer;
  transition: var(--transition);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.submit-btn:hover {
  background: var(--primary-color);
  transform: translateY(-2px);
}

.quick-contact {
  background: rgba(255, 255, 255, 0.1);
  padding: 2rem;
  border-radius: var(--border-radius);
}

.whatsapp-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 100%;
  padding: 1rem;
  background: #25D366;
  color: white;
  text-decoration: none;
  border-radius: var(--border-radius);
  margin-bottom: 1.5rem;
  transition: var(--transition);
}

.whatsapp-btn:hover {
  background: #128C7E;
  transform: translateY(-2px);
}

.contact-info {
  text-align: center;
}

.contact-info p {
  margin: 0.5rem 0;
}

/* Responsive Design */
@media (max-width: 968px) {
  .booking-container {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 576px) {
  .form-row {
    grid-template-columns: 1fr;
  }
}

/* Testimonials Section */
.testimonials {
  padding: 4rem 0;
  background: var(--background-light);
}

.testimonial-card {
  background: white;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  margin: 1rem;
}

/* Map Section */
.park-map {
  height: 400px;
  position: relative;
}

/* Weather Section */
.weather-section {
  padding: 3rem 0;
  background: #fff;
}

.weather-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.5rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero-content h1 {
    font-size: 3rem;
  }
  
  .activities-grid,
  .info-grid {
    grid-template-columns: 1fr;
  }
  
  .gallery-grid {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
  
  .wildlife-grid {
    grid-template-columns: 1fr;
  }
} 

/* Add more park-specific styles as needed */ 

/* Research Programs Section */
.research-programs {
  padding: 4rem 0;
  background: linear-gradient(to bottom, var(--background-light) 0%, var(--white) 100%);
}

.research-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.research-card {
  background: var(--white);
  border-radius: var(--border-radius);
  padding: 2rem;
  box-shadow: var(--shadow-md);
  transition: var(--transition);
  border: 1px solid rgba(0,0,0,0.1);
}

.research-card:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-lg);
}

.research-meta {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid rgba(0,0,0,0.1);
  color: var(--text-light);
}

/* Visitor Experience Section */
.visitor-experience {
  padding: 4rem 0;
  background: var(--white);
}

.experience-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.best-practices, .packing-list {
  background: var(--background-light);
  padding: 2rem;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-sm);
}

/* Seasonal Highlights */
.seasonal-highlights {
  padding: 4rem 0;
  background: linear-gradient(rgba(0,0,0,0.02), rgba(0,0,0,0.05));
}

.seasons-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.season-card {
  background: var(--white);
  padding: 2rem;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-md);
  transition: var(--transition);
}

.season-card:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-lg);
}

/* Park Statistics */
.park-statistics {
  padding: 4rem 0;
  background: var(--white);
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
}

.stat-card {
  text-align: center;
  padding: 2rem;
  background: var(--background-light);
  border-radius: var(--border-radius);
  transition: var(--transition);
}

.stat-card:hover {
  transform: translateY(-5px);
  background: var(--white);
  box-shadow: var(--shadow-md);
}

/* Educational Programs */
.educational-programs {
  padding: 4rem 0;
  background: linear-gradient(to bottom, var(--white) 0%, var(--background-light) 100%);
}

.programs-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.program-card {
  background: var(--white);
  padding: 2rem;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-md);
  transition: var(--transition);
}

.program-card:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-lg);
}

/* Virtual Tour Section */
.virtual-tour {
  padding: 4rem 0;
  background: var(--background-light);
}

.tour-content {
  max-width: 1200px;
  margin: 0 auto;
}

.video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  margin-bottom: 2rem;
  background: #000;
  border-radius: var(--border-radius);
  overflow: hidden;
}

.video-container video,
.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.video-controls {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 1rem;
  z-index: 10;
}

.video-controls button {
  background: rgba(0, 0, 0, 0.7);
  color: white;
  border: none;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: var(--transition);
}

.video-controls button:hover {
  background: rgba(0, 0, 0, 0.9);
  transform: scale(1.1);
}

.tour-highlights {
  margin: 4rem 0;
}

.highlights-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.highlight-card {
  background: var(--white);
  border-radius: var(--border-radius);
  overflow: hidden;
  box-shadow: var(--shadow-sm);
  transition: var(--transition);
  position: relative;
}

.highlight-card:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-lg);
}

.highlight-image {
  position: relative;
  height: 200px;
  overflow: hidden;
}

.highlight-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.highlight-card:hover .highlight-image img {
  transform: scale(1.1);
}

.highlight-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.highlight-card:hover .highlight-overlay {
  opacity: 1;
}

.view-icon {
  font-size: 2rem;
  color: var(--white);
  margin-bottom: 0.5rem;
}

.highlight-content {
  padding: 1.5rem;
}

.highlight-content h4 {
  color: var(--primary-color);
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
}

.highlight-features {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 1rem;
}

.feature-tag {
  background: var(--background-light);
  padding: 0.25rem 0.75rem;
  border-radius: 15px;
  font-size: 0.9rem;
  color: var(--text-light);
}

.tour-navigation {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 3rem;
}

.nav-card {
  background: var(--background-light);
  padding: 2rem;
  border-radius: var(--border-radius);
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}

.nav-icon {
  font-size: 2rem;
  color: var(--primary-color);
}

.nav-content h4 {
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.nav-content ul {
  list-style: none;
  padding: 0;
}

.nav-content ul li {
  margin-bottom: 0.5rem;
  padding-left: 1.5rem;
  position: relative;
}

.nav-content ul li::before {
  content: "•";
  color: var(--accent-color);
  position: absolute;
  left: 0;
}

@media (max-width: 768px) {
  .highlights-grid {
    grid-template-columns: 1fr;
  }
  
  .tour-navigation {
    grid-template-columns: 1fr;
  }
}

/* Trail Map Section */
.trail-map {
  padding: 4rem 0;
}

.trail-map-container {
  height: 500px;
  border-radius: var(--border-radius);
  overflow: hidden;
  box-shadow: var(--shadow-md);
}

.trail-list {
  margin-top: 2rem;
}

.trail-item {
  background: var(--white);
  padding: 1.5rem;
  border-radius: var(--border-radius);
  margin-bottom: 1rem;
  box-shadow: var(--shadow-sm);
  transition: var(--transition);
}

.trail-item:hover {
  transform: translateX(5px);
  box-shadow: var(--shadow-md);
}

/* Booking Calendar */
.booking-calendar {
  padding: 4rem 0;
  background: var(--white);
}

.calendar-container {
  background: var(--background-light);
  padding: 2rem;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-md);
}

/* Interactive Features */
.interactive-features {
  padding: 4rem 0;
}

.feature-tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.feature-tab {
  padding: 1rem 2rem;
  background: none;
  border: none;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  transition: var(--transition);
}

.feature-tab.active {
  border-bottom-color: var(--primary-color);
  color: var(--primary-color);
}

/* Responsive Design */
@media (max-width: 768px) {
  .research-grid,
  .experience-grid,
  .seasons-grid,
  .stats-grid,
  .programs-grid {
    grid-template-columns: 1fr;
  }

  .trail-map-container {
    height: 300px;
  }
} 

/* Add these styles to your existing CSS */
.map-error {
  background: var(--background-light);
  padding: 2rem;
  border-radius: var(--border-radius);
  text-align: center;
  color: var(--text-color);
}

.map-error svg {
  color: var(--accent-color);
  font-size: 2rem;
  margin-bottom: 1rem;
} 

/* Book Your Visit Section */
.book-visit {
  padding: 6rem 0;
  background: linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)),
              var(--primary-color);
  color: var(--white);
}

.booking-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  align-items: center;
}

.booking-info {
  padding-right: 2rem;
}

.booking-info h3 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
}

.booking-highlights {
  margin: 2rem 0;
  list-style: none;
}

.booking-highlights li {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  font-size: 1.1rem;
}

.booking-highlights svg {
  color: var(--accent-color);
}

.booking-cta {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
}

.btn-primary,
.btn-secondary {
  padding: 1rem 2rem;
  border: none;
  border-radius: var(--border-radius);
  font-size: 1.1rem;
  cursor: pointer;
  transition: var(--transition);
}

.btn-primary {
  background: var(--accent-color);
  color: var(--white);
}

.btn-secondary {
  background: transparent;
  border: 2px solid var(--white);
  color: var(--white);
}

.btn-primary:hover,
.btn-secondary:hover {
  transform: translateY(-3px);
  box-shadow: var(--shadow-md);
}

.booking-features {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}

.feature-card {
  background: rgba(255,255,255,0.1);
  padding: 2rem;
  border-radius: var(--border-radius);
  text-align: center;
  transition: var(--transition);
}

.feature-card:hover {
  transform: translateY(-5px);
  background: rgba(255,255,255,0.15);
}

.feature-card svg {
  font-size: 2rem;
  color: var(--accent-color);
  margin-bottom: 1rem;
}

.feature-card h4 {
  margin-bottom: 0.5rem;
}

/* Responsive Design */
@media (max-width: 968px) {
  .booking-grid {
    grid-template-columns: 1fr;
    gap: 3rem;
  }

  .booking-info {
    padding-right: 0;
    text-align: center;
  }

  .booking-highlights li {
    justify-content: center;
  }

  .booking-cta {
    justify-content: center;
  }
}

@media (max-width: 576px) {
  .booking-features {
    grid-template-columns: 1fr;
  }

  .booking-cta {
    flex-direction: column;
  }

  .btn-primary,
  .btn-secondary {
    width: 100%;
  }
} 

/* Plan Your Safari Section */
.plan-safari {
  padding: 6rem 0;
  background: linear-gradient(rgba(0,0,0,0.8), rgba(0,0,0,0.8)),
              url('https://source.unsplash.com/1600x900/?safari,tanzania') center/cover no-repeat;
  color: var(--white);
}

.safari-planning-grid {
  max-width: 1200px;
  margin: 0 auto;
}

.safari-info h3 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  color: var(--accent-color);
}

.safari-options {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin: 3rem 0;
}

.option-card {
  background: rgba(255, 255, 255, 0.1);
  padding: 2rem;
  border-radius: var(--border-radius);
  text-align: center;
  transition: var(--transition);
}

.option-card:hover {
  transform: translateY(-5px);
  background: rgba(255, 255, 255, 0.15);
}

.option-icon {
  font-size: 2.5rem;
  color: var(--accent-color);
  margin-bottom: 1rem;
}

.safari-packages {
  margin-top: 4rem;
}

.package-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.package-card {
  background: rgba(255, 255, 255, 0.1);
  padding: 2rem;
  border-radius: var(--border-radius);
  transition: var(--transition);
}

.package-card:hover {
  transform: translateY(-5px);
  background: rgba(255, 255, 255, 0.15);
}

.package-card h5 {
  color: var(--accent-color);
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.package-card ul {
  list-style: none;
  margin: 1.5rem 0;
}

.package-card ul li {
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.package-card ul li::before {
  content: "•";
  color: var(--accent-color);
}

.package-price {
  display: block;
  font-size: 1.25rem;
  color: var(--accent-color);
  margin-top: 1rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .safari-info h3 {
    font-size: 2rem;
    text-align: center;
  }
  
  .safari-options {
    grid-template-columns: 1fr;
  }
  
  .package-grid {
    grid-template-columns: 1fr;
  }
} 

/* Update input placeholder styles in booking section */
.booking-form input::placeholder,
.booking-form select::placeholder,
.booking-form textarea::placeholder {
  color: rgba(255, 255, 255, 0.7);
  opacity: 1; /* Firefox needs this */
}

/* For Edge browser */
.booking-form input::-ms-input-placeholder,
.booking-form select::-ms-input-placeholder,
.booking-form textarea::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

/* For Internet Explorer */
.booking-form input:-ms-input-placeholder,
.booking-form select:-ms-input-placeholder,
.booking-form textarea:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

/* Style the select options */
.booking-form select option {
  background: var(--primary-color);
  color: var(--white);
}

/* Update the select element color */
.booking-form select {
  color: rgba(255, 255, 255, 0.7);
}

/* When an option is selected */
.booking-form select:focus,
.booking-form input:focus,
.booking-form textarea:focus {
  color: var(--white);
  border-color: var(--accent-color);
  outline: none;
} 

/* Park Trails & Points of Interest */
.park-trails {
  padding: 4rem 0;
  background: var(--background-light);
}

.trails-grid {
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 2rem;
  margin-bottom: 2rem;
}

.trail-cards {
  display: grid;
  gap: 1rem;
}

.trail-card {
  background: var(--white);
  padding: 1.5rem;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-sm);
}

.trail-card h4 {
  color: var(--primary-color);
  margin-bottom: 0.5rem;
}

.trail-info {
  display: flex;
  gap: 1rem;
  margin-bottom: 0.5rem;
  color: var(--text-light);
  font-size: 0.9rem;
}

.trail-features {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.feature-tag {
  background: var(--background-light);
  padding: 0.25rem 0.75rem;
  border-radius: 15px;
  font-size: 0.8rem;
  color: var(--text-light);
}

.poi-cards {
  display: grid;
  gap: 1rem;
}

.poi-card {
  background: var(--white);
  padding: 1.25rem;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-sm);
}

.poi-card h4 {
  color: var(--primary-color);
  margin-bottom: 0.25rem;
}

.poi-type {
  display: inline-block;
  background: var(--primary-color);
  color: var(--white);
  padding: 0.25rem 0.5rem;
  border-radius: 12px;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
}

.map-container {
  height: 400px;
  border-radius: var(--border-radius);
  overflow: hidden;
  box-shadow: var(--shadow-md);
}

@media (max-width: 768px) {
  .trails-grid {
    grid-template-columns: 1fr;
  }
} 