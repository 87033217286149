:root {
  --primary-color: #ff7b25;
  --primary-dark: #e66000;
  --primary-light: #ffa366;
  --accent-color: #333;
  --text-color: #333;
  --light-bg: #f8f9fa;
  --white: #ffffff;
  --shadow: 0 10px 30px rgba(0,0,0,0.1);
  --transition: all 0.3s ease;
}

/* Main Container */
.camping-page {
  overflow-x: hidden;
}

/* Hero Section */
.camping-hero {
  height: 85vh;
  background: linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.3)), 
              url('https://img.freepik.com/premium-photo/ai-image-generator-bonfire-with-firewood-chairs-camping_977285-39288.jpg') center/cover no-repeat;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
    text-align: center;
  color: var(--white);
  margin-top: 0;
  padding-top: 60px;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.6) 100%);
  z-index: 1;
}

.hero-content {
  max-width: 800px;
  padding: 2rem;
  position: relative;
  z-index: 2;
  animation: fadeIn 1.5s ease-out forwards;
  opacity: 1;
  visibility: visible;
}

.hero-content h1 {
  font-size: 4.5rem;
  font-weight: 800;
  margin-bottom: 1.5rem;
  text-shadow: 2px 2px 8px rgba(0,0,0,0.5);
  letter-spacing: 2px;
}

.hero-content p {
  font-size: 1.8rem;
  margin-bottom: 2.5rem;
  text-shadow: 1px 1px 4px rgba(0,0,0,0.5);
}

.hero-buttons {
  display: flex;
  gap: 1.5rem;
  justify-content: center;
}

.primary-btn, .secondary-btn {
  padding: 1rem 2rem;
  border-radius: 50px;
  font-weight: 600;
  font-size: 1.1rem;
  text-decoration: none;
  transition: var(--transition);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 180px;
}

.primary-btn {
  background: var(--primary-color);
  color: var(--white);
  box-shadow: 0 4px 15px rgba(255, 123, 37, 0.4);
}

.primary-btn:hover {
  background: var(--primary-dark);
  transform: translateY(-5px);
  box-shadow: 0 8px 25px rgba(255, 123, 37, 0.5);
}

.secondary-btn {
  background: transparent;
  color: var(--white);
  border: 2px solid var(--white);
}

.secondary-btn:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: translateY(-5px);
}

/* Container */
.container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 2rem;
}

/* Enhanced Intro Section */
.camping-intro {
  padding: 8rem 0;
  background: linear-gradient(to bottom, #fff, #f8f9fa);
    position: relative;
  overflow: hidden;
}

.camping-intro::before {
    content: '';
    position: absolute;
    top: 0;
  right: 0;
  width: 300px;
  height: 300px;
    background: var(--primary-light);
  opacity: 0.05;
    border-radius: 50%;
  transform: translate(100px, -150px);
}

.intro-content {
  text-align: center;
  position: relative;
  z-index: 2;
}

.intro-content h2 {
  font-size: 3.2rem;
  color: var(--accent-color);
  margin-bottom: 2rem;
    position: relative;
    display: inline-block;
    font-weight: 800;
}

.intro-content h2::after {
    content: '';
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 4px;
    background: linear-gradient(to right, var(--primary-light), var(--primary-color), var(--primary-light));
  border-radius: 4px;
}

.intro-content > p {
  font-size: 1.3rem;
    color: #555;
  line-height: 1.9;
  max-width: 900px;
  margin: 2.5rem auto 5rem;
  padding: 0 1rem;
}

.intro-features {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 3rem;
  margin-top: 3rem;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.feature {
  background: white;
  padding: 2.5rem 2rem;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0,0,0,0.05);
  transition: all 0.4s ease;
  position: relative;
  overflow: hidden;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid rgba(0,0,0,0.05);
}

.feature:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 40px rgba(255, 123, 37, 0.15);
  border-color: rgba(255, 123, 37, 0.1);
}

.feature::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background: linear-gradient(to right, var(--primary-color), var(--primary-light));
}

.feature-icon {
  width: 70px;
  height: 70px;
  background: linear-gradient(135deg, var(--primary-light), var(--primary-color));
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
  color: white;
  font-size: 1.8rem;
  box-shadow: 0 10px 20px rgba(255, 123, 37, 0.2);
}

.feature h3 {
  font-size: 1.5rem;
  color: var(--accent-color);
  margin-bottom: 1rem;
  font-weight: 700;
}

.feature p {
  color: #666;
  line-height: 1.7;
  font-size: 1.05rem;
}

/* Completely redesigned Experience Section */
.camping-experience {
  padding: 4rem 0;
  background: #f8f9fa;
  position: relative;
  overflow: hidden;
}

.camping-experience::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('https://img.freepik.com/free-photo/crossroad-car-safari-scene_23-2151822336.jpg') center/cover no-repeat;
  opacity: 0.05;
  z-index: 0;
}

.experience-title {
  font-size: 2.8rem;
  color: var(--accent-color);
  text-align: center;
  margin-bottom: 0.5rem;
  position: relative;
  z-index: 1;
}

.experience-subtitle {
    font-size: 1.2rem;
  color: #666;
  text-align: center;
  margin-bottom: 3rem;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 1;
}

.experience-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  max-width: 1200px;
    margin: 0 auto;
  position: relative;
  z-index: 1;
}

.experience-card {
    background: white;
  border-radius: 12px;
    overflow: hidden;
  box-shadow: 0 10px 25px rgba(0,0,0,0.08);
  transition: all 0.3s ease;
  position: relative;
  height: 380px;
  display: flex;
  flex-direction: column;
}

.experience-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 35px rgba(0,0,0,0.15);
}

.card-icon {
  width: 60px;
  height: 60px;
  background: var(--primary-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.5rem;
  margin: -30px auto 15px;
  box-shadow: 0 5px 15px rgba(255, 123, 37, 0.3);
  z-index: 2;
    position: relative;
}

.experience-card h3 {
  font-size: 1.4rem;
  color: var(--accent-color);
  margin-bottom: 0.8rem;
  text-align: center;
  padding: 0 1.5rem;
}

.experience-card p {
  font-size: 0.95rem;
  color: #666;
  line-height: 1.6;
  padding: 0 1.5rem;
  margin-bottom: 1.5rem;
  flex-grow: 1;
}

.experience-card img {
  width: 100%;
  height: 160px;
  object-fit: cover;
  margin-top: auto;
}

/* Card-specific styling */
.experience-card.morning .card-icon {
  background: linear-gradient(135deg, #ff9966, #ff5e62);
}

.experience-card.daytime .card-icon {
  background: linear-gradient(135deg, #36d1dc, #5b86e5);
}

.experience-card.evening .card-icon {
  background: linear-gradient(135deg, #614385, #516395);
}

/* Tours Section */
.camping-tours {
  padding: 6rem 0;
  background: var(--light-bg);
}

.camping-tours h2 {
  font-size: 2.8rem;
  color: var(--accent-color);
  text-align: center;
  margin-bottom: 1rem;
}

.section-intro {
  text-align: center;
  color: #666;
  font-size: 1.2rem;
  max-width: 800px;
  margin: 0 auto 3rem;
}

.tour-categories {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 3rem;
  flex-wrap: wrap;
}

.tour-categories button {
  padding: 0.8rem 1.5rem;
  background: var(--white);
  border: none;
  border-radius: 30px;
  color: #555;
  font-weight: 600;
  cursor: pointer;
  transition: var(--transition);
  box-shadow: 0 3px 10px rgba(0,0,0,0.05);
}

.tour-categories button:hover {
  background: var(--primary-light);
  color: var(--white);
}

.tour-categories button.active {
  background: var(--primary-color);
  color: var(--white);
  box-shadow: 0 5px 15px rgba(255, 123, 37, 0.3);
}

.tours-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 2.5rem;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 2rem;
}

.tour-card {
  background: var(--white);
  border-radius: 15px;
  overflow: hidden;
  box-shadow: var(--shadow);
  transition: var(--transition);
  animation: fadeInUp 0.6s ease-out forwards;
  opacity: 0;
}

.tour-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 35px rgba(0,0,0,0.15);
}

.tour-image {
  height: 250px;
    position: relative;
    overflow: hidden;
}

.tour-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  transition: transform 0.5s ease;
}

.tour-card:hover .tour-image img {
  transform: scale(1.1);
}

.tour-badges {
    position: absolute;
  top: 15px;
  left: 15px;
  display: flex;
  gap: 0.5rem;
}

.tour-badge {
  padding: 0.4rem 0.8rem;
  border-radius: 20px;
  font-size: 0.8rem;
  font-weight: 600;
  text-transform: uppercase;
}

.bestseller-badge {
    background: var(--primary-color);
  color: var(--white);
}

.duration-badge {
  background: rgba(0, 0, 0, 0.6);
  color: var(--white);
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.tour-content {
  padding: 2rem;
}

.tour-title {
  font-size: 1.6rem;
  color: var(--accent-color);
    margin-bottom: 1rem;
}

.tour-highlights {
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
  flex-wrap: wrap;
}

.tour-highlight {
  background: var(--light-bg);
  padding: 0.4rem 0.8rem;
  border-radius: 20px;
  font-size: 0.9rem;
  color: #666;
}

.tour-description {
  color: #666;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.tour-features {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.tour-feature {
    display: flex;
    align-items: center;
  gap: 0.5rem;
    color: #555;
    font-size: 0.95rem;
}

.tour-feature svg {
    color: var(--primary-color);
}

.tour-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 1.5rem;
  border-top: 1px solid #eee;
}

.tour-price {
    font-size: 1.8rem;
  font-weight: 700;
  color: var(--accent-color);
}

.tour-price span {
  font-size: 1rem;
  color: #999;
}

.tour-button {
  padding: 0.8rem 1.5rem;
    background: var(--primary-color);
  color: var(--white);
  border: none;
    border-radius: 30px;
  font-weight: 600;
    text-decoration: none;
  transition: var(--transition);
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
}

.tour-button:hover {
    background: var(--primary-dark);
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(255, 123, 37, 0.3);
}

/* Testimonials Section */
.testimonials-section {
  padding: 6rem 0;
  background: var(--white);
}

.testimonials-section h2 {
  font-size: 2.8rem;
  color: var(--accent-color);
  text-align: center;
  margin-bottom: 3rem;
  position: relative;
}

.testimonials-section h2::after {
  content: '';
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 3px;
  background: var(--primary-color);
  border-radius: 2px;
}

.testimonials-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
        gap: 2rem;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 2rem;
}

.testimonial-card {
  background: var(--light-bg);
  border-radius: 15px;
  padding: 2rem;
  box-shadow: var(--shadow);
  transition: var(--transition);
}

.testimonial-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 35px rgba(0,0,0,0.15);
}

.testimonial-rating {
  display: flex;
  gap: 0.3rem;
  margin-bottom: 1.5rem;
}

.testimonial-rating svg {
  color: #ffc107;
  font-size: 1.2rem;
}

.testimonial-text {
  font-size: 1.1rem;
  color: #555;
  line-height: 1.8;
  margin-bottom: 2rem;
  font-style: italic;
}

.testimonial-author {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.testimonial-avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
}

.testimonial-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.testimonial-info h3 {
  font-size: 1.2rem;
  color: var(--accent-color);
  margin-bottom: 0.3rem;
}

.testimonial-info p {
  color: #999;
  font-size: 0.9rem;
}

/* CTA Section */
.camping-cta {
  padding: 6rem 0;
  background: linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)), 
              url('https://img.freepik.com/free-photo/tourist-watching-zebras-savanna_23-2149058076.jpg') center/cover no-repeat fixed;
  color: var(--white);
  text-align: center;
}

.cta-content {
  max-width: 800px;
        margin: 0 auto;
    }

.cta-content h2 {
  font-size: 3rem;
  margin-bottom: 1.5rem;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.3);
}

.cta-content p {
  font-size: 1.3rem;
  margin-bottom: 3rem;
  opacity: 0.9;
}

.cta-buttons {
  display: flex;
  gap: 1.5rem;
  justify-content: center;
}

.cta-primary, .cta-secondary {
  padding: 1rem 2rem;
  border-radius: 50px;
  font-weight: 600;
  font-size: 1.1rem;
  text-decoration: none;
  transition: var(--transition);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 200px;
}

.cta-primary {
  background: var(--primary-color);
  color: var(--white);
  box-shadow: 0 4px 15px rgba(255, 123, 37, 0.4);
}

.cta-primary:hover {
  background: var(--primary-dark);
  transform: translateY(-5px);
  box-shadow: 0 8px 25px rgba(255, 123, 37, 0.5);
}

.cta-secondary {
  background: transparent;
  color: var(--white);
  border: 2px solid var(--white);
}

.cta-secondary:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: translateY(-5px);
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(30px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.tour-card:nth-child(1) {
  animation-delay: 0.1s;
}

.tour-card:nth-child(2) {
  animation-delay: 0.2s;
}

.tour-card:nth-child(3) {
  animation-delay: 0.3s;
}

.tour-card:nth-child(4) {
  animation-delay: 0.4s;
}

.tour-card:nth-child(5) {
  animation-delay: 0.5s;
}

.tour-card:nth-child(6) {
  animation-delay: 0.6s;
}

/* Responsive adjustments */
@media (max-width: 992px) {
  .experience-cards {
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
  }
  
  .experience-card {
    height: 350px;
  }
  
  .experience-card img {
    height: 140px;
  }
}

@media (max-width: 768px) {
  .experience-cards {
    grid-template-columns: repeat(1, 1fr);
    max-width: 500px;
    margin: 0 auto;
  }
  
  .experience-card {
    height: auto;
    max-width: 100%;
  }
  
  .experience-card img {
    height: 200px;
  }
  
  .experience-title {
    font-size: 2.4rem;
  }
}

@media (max-width: 576px) {
  .camping-experience {
    padding: 3rem 0;
  }
  
  .experience-title {
    font-size: 2.2rem;
  }
  
  .experience-subtitle {
    font-size: 1.1rem;
    margin-bottom: 2.5rem;
  }
  
  .experience-card img {
    height: 180px;
  }
}

/* Enhanced Tour Modal Styles */
.tour-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.85);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 20px;
  overflow-y: auto;
  animation: fadeIn 0.3s ease-out;
}

.tour-modal {
  background: white;
  border-radius: 20px;
  width: 100%;
  max-width: 1100px;
  max-height: 90vh;
  overflow-y: auto;
  display: grid;
  grid-template-columns: 45% 55%;
  position: relative;
  animation: scaleIn 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  box-shadow: 0 30px 60px rgba(0,0,0,0.4);
  border: 1px solid rgba(255,255,255,0.1);
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(0.9) translateY(20px);
  }
  to {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

.modal-close {
  position: absolute;
  top: 20px;
  right: 25px;
  background: rgba(255,255,255,0.2);
  border: none;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  font-size: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  transition: all 0.3s;
  color: white;
  box-shadow: 0 5px 15px rgba(0,0,0,0.2);
}

.modal-close:hover {
  background: var(--primary-color);
  transform: rotate(90deg);
}

.modal-image {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.modal-image::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0,0,0,0.2), rgba(0,0,0,0.6));
  pointer-events: none;
}

.modal-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px 0 0 20px;
  transition: transform 10s ease;
}

.tour-modal:hover .modal-image img {
  transform: scale(1.1);
}

.modal-badges {
  position: absolute;
  top: 25px;
  left: 25px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  z-index: 2;
}

.modal-badges .tour-duration,
.modal-badges .tour-bestseller {
  background: rgba(255,255,255,0.9);
  color: var(--accent-color);
  padding: 8px 16px;
  border-radius: 50px;
  font-weight: 600;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 8px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.15);
  animation: fadeInRight 0.5s ease forwards;
  animation-delay: 0.2s;
  opacity: 0;
}

.modal-badges .tour-bestseller {
  background: var(--primary-color);
  color: white;
  animation-delay: 0.4s;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.modal-content {
  padding: 45px;
  overflow-y: auto;
  background: linear-gradient(to bottom, #fff, #f9f9f9);
}

.modal-content h2 {
  font-size: 2.4rem;
  color: var(--accent-color);
  margin-bottom: 30px;
  position: relative;
  padding-bottom: 20px;
  font-weight: 800;
  animation: fadeInUp 0.5s ease forwards;
}

.modal-content h2::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 80px;
  height: 4px;
  background: linear-gradient(to right, var(--primary-color), var(--primary-light));
  border-radius: 4px;
}

.modal-content h3 {
  font-size: 1.5rem;
  color: var(--accent-color);
  margin-bottom: 18px;
  margin-top: 35px;
  display: flex;
  align-items: center;
  gap: 10px;
  animation: fadeInUp 0.5s ease forwards;
  animation-delay: 0.1s;
}

.modal-content h3::before {
  content: '';
  display: block;
  width: 6px;
  height: 20px;
  background: var(--primary-color);
  border-radius: 3px;
}

.highlights-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-bottom: 25px;
  animation: fadeInUp 0.5s ease forwards;
    animation-delay: 0.2s;
}

.highlights-grid .highlight {
  background: linear-gradient(to right, var(--primary-light), var(--primary-color));
  color: white;
  padding: 8px 16px;
  border-radius: 50px;
  font-size: 0.95rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  box-shadow: 0 5px 15px rgba(255, 123, 37, 0.15);
  transition: all 0.3s;
}

.highlights-grid .highlight:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 20px rgba(255, 123, 37, 0.25);
}

.modal-description p {
  line-height: 1.9;
  color: #555;
  margin-bottom: 25px;
  font-size: 1.05rem;
  animation: fadeInUp 0.5s ease forwards;
  animation-delay: 0.3s;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 18px;
  margin-bottom: 25px;
  animation: fadeInUp 0.5s ease forwards;
    animation-delay: 0.4s;
}

.modal-feature {
  display: flex;
  align-items: center;
  gap: 12px;
  background: white;
  padding: 15px 18px;
  border-radius: 12px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.05);
  transition: all 0.3s;
  border: 1px solid rgba(0,0,0,0.05);
}

.modal-feature:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(0,0,0,0.08);
  border-color: rgba(255, 123, 37, 0.2);
}

.modal-feature svg {
  color: var(--primary-color);
  font-size: 1.3rem;
  background: rgba(255, 123, 37, 0.1);
  padding: 8px;
  border-radius: 50%;
  box-sizing: content-box;
}

.modal-feature span {
  font-weight: 500;
  color: var(--accent-color);
}

.itinerary-days {
  display: flex;
  flex-direction: column;
  gap: 18px;
  animation: fadeInUp 0.5s ease forwards;
  animation-delay: 0.5s;
}

.itinerary-day {
  display: flex;
  gap: 18px;
  padding: 20px;
  background: white;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.05);
  transition: all 0.3s;
  border: 1px solid rgba(0,0,0,0.05);
  position: relative;
  overflow: hidden;
}

.itinerary-day:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(0,0,0,0.08);
  border-color: rgba(255, 123, 37, 0.2);
}

.itinerary-day::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 5px;
  height: 100%;
  background: linear-gradient(to bottom, var(--primary-color), var(--primary-light));
}

.day-number {
  background: linear-gradient(135deg, var(--primary-color), var(--primary-light));
  color: white;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 1.1rem;
  flex-shrink: 0;
  box-shadow: 0 8px 20px rgba(255, 123, 37, 0.2);
}

.day-content h4 {
  font-size: 1.2rem;
  margin-bottom: 8px;
  color: var(--accent-color);
  font-weight: 700;
}

.day-content p {
  font-size: 1rem;
  color: #666;
  line-height: 1.7;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  padding-top: 25px;
  border-top: 1px solid #eee;
  animation: fadeInUp 0.5s ease forwards;
    animation-delay: 0.6s;
}

.modal-price {
  display: flex;
  flex-direction: column;
}

.modal-price span:first-child {
  font-size: 2rem;
  font-weight: 800;
  color: var(--primary-color);
  line-height: 1;
}

.modal-price .price-per {
  font-size: 0.95rem;
  color: #777;
  margin-top: 5px;
}

.modal-book-btn {
  background: linear-gradient(to right, var(--primary-color), var(--primary-dark));
  color: white;
  padding: 14px 30px;
  border-radius: 50px;
  text-decoration: none;
  font-weight: 600;
  font-size: 1.1rem;
  transition: all 0.3s;
  box-shadow: 0 8px 20px rgba(255, 123, 37, 0.3);
  display: inline-flex;
  align-items: center;
  gap: 10px;
}

.modal-book-btn::after {
  content: '→';
  font-size: 1.2rem;
  transition: transform 0.3s;
}

.modal-book-btn:hover {
  background: linear-gradient(to right, var(--primary-dark), var(--primary-color));
  transform: translateY(-5px);
  box-shadow: 0 12px 30px rgba(255, 123, 37, 0.4);
}

.modal-book-btn:hover::after {
  transform: translateX(5px);
}

/* Responsive modal */
@media (max-width: 992px) {
  .tour-modal {
    grid-template-columns: 1fr;
    max-width: 650px;
  }
  
  .modal-image {
    height: 350px;
  }
  
  .modal-image img {
    border-radius: 20px 20px 0 0;
  }
  
  .modal-content {
    padding: 35px;
  }
}

@media (max-width: 576px) {
  .tour-modal-overlay {
    padding: 15px;
  }
  
  .modal-image {
    height: 250px;
  }
  
  .modal-content {
    padding: 25px 20px;
  }
  
  .modal-content h2 {
    font-size: 1.8rem;
    margin-bottom: 20px;
    padding-bottom: 15px;
  }
  
  .modal-content h3 {
    font-size: 1.3rem;
    margin-top: 25px;
  }
  
  .features-grid {
    grid-template-columns: 1fr;
  }
  
  .modal-footer {
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;
  }
  
  .modal-price {
    align-items: center;
  }
  
  .modal-book-btn {
    width: 100%;
    justify-content: center;
  }
}