:root {
  --primary-color: #ff7b25;
  --primary-dark: #e66000;
  --primary-light: #ffa366;
}

.park-page {
  min-height: 100vh;
}

/* Hero Section with Parallax */
.park-hero {
  height: 80vh;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.hero-content {
  text-align: center;
  color: white;
  z-index: 2;
  padding: 2rem;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  max-width: 800px;
  margin: 0 auto;
}

.hero-content h1 {
  font-size: 3.5rem;
  margin-bottom: 1.5rem;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.3);
}

.hero-content p {
  font-size: 1.4rem;
  line-height: 1.6;
  text-shadow: 1px 1px 3px rgba(0,0,0,0.3);
}

/* Story Section */
.park-story {
  padding: 4rem 0;
  background: #f9f9f9;
}

.story-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  margin-top: 2rem;
}

.historical-timeline {
  margin-top: 2rem;
  padding: 2rem;
  background: white;
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0,0,0,0.1);
}

.historical-timeline ul {
  list-style: none;
  padding: 0;
}

.historical-timeline li {
  padding: 1rem 0;
  border-left: 2px solid var(--primary-color);
  margin-left: 20px;
  padding-left: 20px;
  position: relative;
}

.historical-timeline li::before {
  content: '';
  width: 12px;
  height: 12px;
  background: var(--primary-color);
  border-radius: 50%;
  position: absolute;
  left: -7px;
  top: 50%;
  transform: translateY(-50%);
}

/* Package Section */
.safari-packages {
  padding: 4rem 0;
  background: #fff;
}

.packages-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.package-card {
  background: white;
  border-radius: 15px;
  padding: 2rem;
  box-shadow: 0 4px 15px rgba(0,0,0,0.1);
  transition: transform 0.3s ease;
}

.package-card:hover {
  transform: translateY(-5px);
}

.package-card .price {
  color: var(--primary-color);
  font-size: 1.5rem;
  font-weight: bold;
  margin: 1rem 0;
}

.highlights {
  list-style: none;
  padding: 0;
  margin: 1rem 0;
}

.highlights li {
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
}

.highlights li::before {
  content: '✓';
  color: var(--primary-color);
  margin-right: 10px;
}

/* Itinerary Modal */
.itinerary-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 2rem;
  border-radius: 15px;
  max-width: 800px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
}

.itinerary-day {
  margin: 2rem 0;
  padding: 1rem;
  background: #f9f9f9;
  border-radius: 10px;
}

.book-now-button,
.close-button {
  padding: 1rem 2rem;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-weight: bold;
  margin-top: 1rem;
}

.book-now-button {
  background: var(--primary-color);
  color: white;
  margin-right: 1rem;
}

.close-button {
  background: #f1f1f1;
  color: #333;
}

/* Responsive Design */
@media (max-width: 768px) {
  .story-grid {
    grid-template-columns: 1fr;
  }
  
  .modal-content {
    width: 95%;
    padding: 1rem;
  }
} 