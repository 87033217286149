:root {
  --primary-color: #ff7b25;
  --primary-dark: #e66000;
  --primary-light: #ffa366;
  --footer-bg: #1a1a1a;
  --footer-text: #b3b3b3;
}

.footer {
  background: var(--footer-bg);
  color: #fff;
  position: relative;
}

.footer-top {
  padding: 5rem 2rem 3rem;
  position: relative;
  background: linear-gradient(rgba(26, 26, 26, 0.95), rgba(26, 26, 26, 0.95)),
              url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSmLLBARcL8XTjrDtc61MaO0FNGXhZ2uxw0F9vQHfI5MYTIEgffb38BgB3SgrRucP4yCg&usqp=CAU') center/cover no-repeat;
}

.footer-content {
  max-width: 1400px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr 1.5fr;
  gap: 4rem;
}

.footer-section {
  padding: 0 1rem;
}

.footer-section h3 {
  color: #fff;
  font-size: 1.4rem;
  margin-bottom: 1.8rem;
  font-weight: 600;
  position: relative;
  padding-bottom: 0.8rem;
}

.footer-section h3::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50px;
  height: 2px;
  background: var(--primary-color);
}

.footer-about p {
  color: var(--footer-text);
  line-height: 1.8;
  margin-bottom: 2rem;
  font-size: 1rem;
}

.social-links {
  display: flex;
  gap: 1rem;
}

.social-links a {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  font-size: 1.1rem;
}

.social-links .facebook {
  background: #3b5998;
  color: white;
}

.social-links .twitter {
  background: #1da1f2;
  color: white;
}

.social-links .instagram {
  background: #e1306c;
  color: white;
}

.social-links .whatsapp {
  background: #25d366;
  color: white;
}

.social-links a:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
}

.footer-links {
  list-style: none;
  padding: 0;
}

.footer-links li {
  margin-bottom: 1rem;
}

.footer-links a {
  color: var(--footer-text);
  text-decoration: none;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.footer-links a:hover {
  color: var(--primary-color);
  transform: translateX(8px);
}

.footer-links svg {
  font-size: 0.8rem;
  color: var(--primary-color);
}

.contact-info {
  list-style: none;
  padding: 0;
}

.contact-info li {
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
  color: var(--footer-text);
}

.contact-info svg {
  color: var(--primary-color);
  font-size: 1.2rem;
  margin-top: 0.3rem;
}

.contact-info strong {
  color: #fff;
  display: block;
  margin-bottom: 0.3rem;
}

.contact-info p {
  margin: 0;
}

.footer-bottom {
  background: #111;
  padding: 1.5rem 2rem;
}

.footer-bottom-content {
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-bottom p {
  color: var(--footer-text);
  margin: 0;
}

.footer-bottom-links {
  display: flex;
  gap: 2rem;
}

.footer-bottom-links a {
  color: var(--footer-text);
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-bottom-links a:hover {
  color: var(--primary-color);
}

.footer-contact-link {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease;
  display: block;
}

.footer-contact-link:hover {
  color: var(--primary-color);
}

@media (max-width: 1200px) {
  .footer-content {
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
  }
}

@media (max-width: 768px) {
  .footer-content {
    grid-template-columns: 1fr;
    gap: 2.5rem;
  }

  .footer-section {
    text-align: center;
  }

  .social-links,
  .footer-links {
    justify-content: center;
  }

  .footer-bottom-content {
    flex-direction: column;
    gap: 1rem;
  }

  .footer-contact-link {
    padding: 5px 0;
  }
}

@media (max-width: 480px) {
  .footer-top {
    padding: 3rem 1rem 2rem;
  }

  .footer-bottom {
    padding: 1rem;
  }

  .footer-bottom-links {
    flex-direction: column;
    gap: 0.5rem;
  }
} 