.travel-advice-container {
  padding: 0;
  margin: 0;
  width: 100%;
}

.travel-advice-hero {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    var(--hero-bg);
  background-size: cover;
  background-position: center;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  padding: 2rem;
}

.travel-advice-hero h1 {
  font-size: 3.5rem;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.travel-advice-hero p {
  font-size: 1.5rem;
  max-width: 800px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.travel-advice-content {
  max-width: 1200px;
  margin: 4rem auto;
  padding: 0 2rem;
}

.advice-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 2rem;
  margin-bottom: 4rem;
}

.advice-card {
  background: white;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.advice-card:hover {
  transform: translateY(-5px);
}

.advice-image {
  height: 200px;
  overflow: hidden;
}

.advice-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.advice-content {
  padding: 2rem;
}

.advice-content h3 {
  color: #333;
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

.advice-content p {
  color: #666;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.advice-list {
  list-style: none;
  padding: 0;
}

.advice-list li {
  color: #555;
  padding: 0.5rem 0;
  padding-left: 1.5rem;
  position: relative;
}

.advice-list li::before {
  content: '✓';
  color: #4CAF50;
  position: absolute;
  left: 0;
}

.important-info {
  background: #f9f9f9;
  padding: 3rem 2rem;
  border-radius: 15px;
  margin-top: 4rem;
}

.important-info h2 {
  color: #333;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-align: center;
}

.info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.info-item {
  text-align: center;
  padding: 1.5rem;
}

.info-item i {
  font-size: 2.5rem;
  color: #4CAF50;
  margin-bottom: 1rem;
}

.info-item h4 {
  color: #333;
  font-size: 1.3rem;
  margin-bottom: 1rem;
}

.info-item p {
  color: #666;
  line-height: 1.6;
}

.contact-support {
  background: #f5f5f5;
  padding: 3rem 2rem;
  text-align: center;
  margin-top: 4rem;
  border-radius: 15px;
}

.contact-support h3 {
  color: #333;
  font-size: 2rem;
  margin-bottom: 1rem;
}

.contact-support p {
  color: #666;
  margin-bottom: 2rem;
  font-size: 1.1rem;
}

.contact-button {
  display: inline-block;
  padding: 1rem 2rem;
  background: #4CAF50;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-weight: 500;
  transition: background-color 0.3s ease;
}

.contact-button:hover {
  background: #388E3C;
}

@media (max-width: 768px) {
  .travel-advice-hero h1 {
    font-size: 2.5rem;
  }

  .travel-advice-hero p {
    font-size: 1.2rem;
  }

  .advice-grid {
    grid-template-columns: 1fr;
  }

  .important-info {
    padding: 2rem 1rem;
  }

  .important-info h2 {
    font-size: 2rem;
  }

  .info-grid {
    grid-template-columns: 1fr;
  }
} 