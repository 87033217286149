:root {
  --primary-color: #ff7b25;
  --primary-dark: #e66000;
  --primary-light: #ffa366;
}

.contact-page {
  padding-top: 80px;
  background: linear-gradient(135deg, #f8f9fa 0%, #ffffff 100%);
}

/* Enhanced Hero Section */
.contact-hero {
  height: 60vh;
  background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)),
              url('https://img.freepik.com/premium-photo/ai-image-generator-bonfire-with-firewood-chairs-camping_977285-39288.jpg?semt=ais_hybrid') center/cover fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  position: relative;
  overflow: hidden;
}

.contact-hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at center, transparent 0%, rgba(0,0,0,0.6) 100%);
}

.contact-hero h1 {
  font-size: 4rem;
  margin-bottom: 1.5rem;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 3px;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.3);
  position: relative;
  z-index: 1;
  animation: fadeInDown 1s ease-out;
}

.contact-hero p {
  font-size: 1.5rem;
  max-width: 600px;
  line-height: 1.6;
  position: relative;
  z-index: 1;
  animation: fadeInUp 1s ease-out;
}

/* Quick Services Section */
.quick-services {
  margin-top: -50px;
  margin-bottom: 4rem;
  position: relative;
  z-index: 2;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  padding: 0 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.service-item {
  background: white;
  padding: 2.5rem;
  border-radius: 20px;
  text-align: center;
  box-shadow: 0 15px 30px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.service-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, var(--primary-color), var(--primary-light));
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 1;
}

.service-item:hover {
  transform: translateY(-10px);
}

.service-item:hover::before {
  opacity: 0.95;
}

.service-item svg {
  font-size: 3rem;
  color: var(--primary-color);
  margin-bottom: 1.5rem;
  position: relative;
  z-index: 2;
  transition: all 0.3s ease;
}

.service-item h3 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 1rem;
  position: relative;
  z-index: 2;
  transition: all 0.3s ease;
}

.service-item p {
  color: #666;
  line-height: 1.6;
  position: relative;
  z-index: 2;
  transition: all 0.3s ease;
}

.service-item:hover svg,
.service-item:hover h3,
.service-item:hover p {
  color: white;
}

/* Contact Info Section */
.contact-info-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  max-width: 1200px;
  margin: -50px auto 4rem;
  padding: 0 2rem;
  position: relative;
  z-index: 10;
}

.contact-form-container,
.contact-card {
  background: white;
  border-radius: 15px;
  box-shadow: 0 10px 25px rgba(0,0,0,0.08);
  overflow: hidden;
  height: fit-content;
}

.contact-form-container:hover,
.contact-card:hover {
  transform: translateY(-5px);
}

.form-header,
.card-header {
  background: linear-gradient(45deg, var(--primary-color), var(--primary-dark));
  padding: 1.2rem;
  color: white;
}

.form-header h2,
.card-header h2 {
  font-size: 1.4rem;
  margin-bottom: 0.2rem;
  color: white;
}

.form-header p,
.card-header p {
  opacity: 0.9;
  font-size: 0.85rem;
}

.contact-form {
  padding: 1.2rem;
}

.form-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-bottom: 1rem;
}

.form-group {
  margin-bottom: 0.8rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.3rem;
  color: #555;
  font-size: 0.85rem;
  font-weight: 500;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 0.6rem 0.8rem;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 0.9rem;
  transition: all 0.3s ease;
  background: #f8f9fa;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: var(--primary-color);
  background: white;
  outline: none;
  box-shadow: 0 0 0 3px rgba(255,123,37,0.1);
}

.form-group textarea {
  height: 80px;
  resize: none;
}

.submit-btn {
  width: 100%;
  padding: 0.8rem;
  background: var(--primary-color);
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 0.95rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 0.5rem;
}

.submit-btn:hover {
  background: var(--primary-dark);
  transform: translateY(-2px);
}

.btn-icon {
  transition: transform 0.3s ease;
}

.submit-btn:hover .btn-icon {
  transform: translateX(5px);
}

.contact-card {
  padding: 0;
}

.card-header {
  background: linear-gradient(45deg, var(--primary-dark), var(--primary-color));
  padding: 2rem;
  color: white;
}

.card-header h2 {
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
  color: white;
}

.contact-details {
  padding: 1rem;
}

.contact-item {
  display: flex;
  gap: 0.8rem;
  padding: 0.6rem;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.contact-item:hover {
  background: rgba(255,123,37,0.05);
  transform: translateX(10px);
}

.contact-item svg {
  color: var(--primary-color);
  font-size: 1.2rem;
  margin-top: 0.2rem;
}

.contact-item h3 {
  font-size: 0.9rem;
  margin-bottom: 0.2rem;
  color: #444;
}

.contact-item p {
  font-size: 0.85rem;
  color: #666;
  margin: 0;
  line-height: 1.4;
}

.license-info {
  margin-top: 1rem;
  padding: 1rem;
  background: linear-gradient(135deg, rgba(255,123,37,0.1), rgba(255,123,37,0.05));
  border: 2px solid var(--primary-color);
  border-radius: 10px;
  text-align: center;
  position: relative;
  overflow: hidden;
  animation: shine 3s infinite;
}

.license-info p {
  color: var(--primary-dark);
  font-size: 1.1rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0;
  position: relative;
  z-index: 2;
  text-shadow: 0 1px 2px rgba(0,0,0,0.1);
}

.license-info::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    45deg,
    transparent,
    rgba(255,123,37,0.1),
    transparent
  );
  transform: rotate(45deg);
  animation: shine-effect 3s infinite;
}

@keyframes shine-effect {
  0% {
    transform: translateX(-100%) rotate(45deg);
  }
  50% {
    transform: translateX(100%) rotate(45deg);
  }
  100% {
    transform: translateX(-100%) rotate(45deg);
  }
}

@keyframes shine {
  0% {
    box-shadow: 0 0 5px rgba(255,123,37,0.2);
  }
  50% {
    box-shadow: 0 0 20px rgba(255,123,37,0.4);
  }
  100% {
    box-shadow: 0 0 5px rgba(255,123,37,0.2);
  }
}

/* Social Connect Section */
.social-connect {
  padding: 4rem 2rem;
}

.social-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  max-width: 1400px;
  margin: 0 auto;
}

.social-card {
  background: white;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 8px 25px rgba(0,0,0,0.1);
  height: 800px;
  display: flex;
  flex-direction: column;
}

.social-header {
  background: linear-gradient(45deg, var(--primary-color), var(--primary-dark));
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  color: white;
  height: 60px;
  flex-shrink: 0;
}

.social-header svg {
  font-size: 1.8rem;
}

.social-header h3 {
  font-size: 1.4rem;
  margin: 0;
}

/* Common styles for all social cards */
.social-card .social-content,
.social-card .instagram-feed,
.social-card .youtube-feed {
  flex: 1;
  position: relative;
  height: calc(100% - 60px);
  overflow: hidden;
}

/* Facebook Card */
.social-card.facebook .fb-page,
.social-card.facebook .fb-page > span,
.social-card.facebook iframe,
.social-card.instagram iframe,
.social-card.youtube iframe {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  border: none !important;
  margin: 0 !important;
  padding: 0 !important;
  display: block !important;
}

/* Responsive Design */
@media (max-width: 1200px) {
  .social-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .social-card {
    height: 700px;
  }
}

@media (max-width: 992px) {
  .social-card {
    height: 600px;
  }
}

@media (max-width: 768px) {
  .social-grid {
    grid-template-columns: 1fr;
  }

  .social-card {
    height: 600px;
  }
}

@media (max-width: 480px) {
  .social-connect {
    padding: 2rem 1rem;
  }

  .social-card {
    height: 500px;
  }
}

/* Map Section */
.map-section {
  position: relative;
  margin: 4rem auto;
  max-width: 1200px;

  padding: 0 2rem;
  display: grid;
  
  grid-template-columns: 1fr 400px;
  gap: 2rem;
  align-items: start;
}

.map-overlay {
  position: relative;
  top: 2rem;
  background: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 15px 35px rgba(0,0,0,0.2);
  z-index: 6;
  max-width: 400px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255,255,255,0.2);
  overflow-y: auto;
  height: 750px;
  order: 2;
}

.map-overlay h2 {
  color: var(--primary-dark);
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
  border-bottom: 2px solid var(--primary-color);
  padding-bottom: 0.5rem;
}

.map-overlay p {
  color: #666;
  font-size: 1rem;
  margin-bottom: 1.5rem;
}

.directions-info {
  margin-top: 1.5rem;
}

.direction-item {
  margin-bottom: 1.5rem;
  padding: 1rem;
  background: rgba(255,123,37,0.05);
  border-radius: 8px;
  border-left: 3px solid var(--primary-color);
}

.direction-item h3 {
  color: var(--primary-dark);
  font-size: 1.1rem;
  margin-bottom: 0.8rem;
  font-weight: 600;
}

.direction-item ul {
  list-style: none;
  padding-left: 0;
}

.direction-item ul li {
  color: #555;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  padding-left: 1.2rem;
  position: relative;
}

.direction-item ul li::before {
  content: '→';
  position: absolute;
  left: 0;
  color: var(--primary-color);
}

.transport-options {
  margin-top: 1.5rem;
  padding: 1rem;
  background: #f8f9fa;
  border-radius: 8px;
}

.transport-options h3 {
  color: var(--primary-dark);
  font-size: 1.1rem;
  margin-bottom: 0.8rem;
}

.transport-options ul {
  list-style: none;
  padding-left: 0;
}

.transport-options ul li {
  color: #555;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  padding-left: 1.2rem;
  position: relative;
}

.transport-options ul li::before {
  content: '•';
  position: absolute;
  left: 0;
  color: var(--primary-color);
}

.landmark-info {
  margin-top: 1.5rem;
  padding: 1rem;
  background: rgba(255,123,37,0.05);
  border-radius: 8px;
  text-align: center;
}

.landmark-info h3 {
  color: var(--primary-dark);
  font-size: 1.1rem;
  margin-bottom: 0.8rem;
}

.landmark-info p {
  color: #555;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.map-container {
  position: relative;
  height: 600px;
  height: 350px;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 20px 40px rgba(0,0,0,0.15);
  border: 3px solid white;
  order: 1;
}

.map-container iframe {
  width: 100%;
  height: 100%;
  transition: all 0.3s ease;
}

.map-container:hover iframe {
  transform: scale(1.02);
}

/* Animations */
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Design */
@media (max-width: 992px) {
  .contact-info-wrapper {
    grid-template-columns: 1fr;
  }
  
  .form-row {
    grid-template-columns: 1fr;
  }
  
  .map-section {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .map-overlay {
    position: relative;
    top: 0;
    order: 1;
    max-width: 100%;
    margin-bottom: 1rem;
  }

  .map-container {
    order: 2;
    height: 400px;
  }

  .direction-item, .transport-options, .landmark-info {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 768px) {
  .contact-info-wrapper {
    margin-top: 2rem;
    padding: 0 1rem;
  }
  
  .social-grid {
    grid-template-columns: 1fr;
  }

  .map-section {
    padding: 0 1rem;
    margin: 2rem auto;
  }

  .map-container {
    height: 350px;
  }
}

@media (max-width: 480px) {
  .contact-info-wrapper {
    margin-top: 2rem;
    padding: 0 1rem;
  }
  
  .social-grid {
    grid-template-columns: 1fr;
  }

  .map-container {
    height: 300px;
    border-radius: 15px;
  }

  .map-overlay {
    padding: 1rem;
  }

  .map-overlay h2 {
    font-size: 1.5rem;
  }

  .direction-item h3,
  .transport-options h3,
  .landmark-info h3 {
    font-size: 1rem;
  }
}

/* Enhanced FAQ Section */
.faq-section {
  padding: 4rem 0;
  background: linear-gradient(to bottom, #f8f9fa, #fff);
}

.faq-section h2 {
  text-align: center;
  margin-bottom: 3rem;
  color: var(--primary-dark);
  font-size: 2.2rem;
  position: relative;
}

.faq-section h2::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 3px;
  background: linear-gradient(to right, var(--primary-light), var(--primary-color));
  border-radius: 2px;
}

.faq-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 1.5rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.faq-item {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 8px 20px rgba(0,0,0,0.06);
  transition: all 0.3s ease;
  border: 1px solid rgba(0,0,0,0.05);
  position: relative;
  overflow: hidden;
}

.faq-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  background: linear-gradient(to bottom, var(--primary-color), var(--primary-light));
  opacity: 0;
  transition: opacity 0.3s ease;
}

.faq-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 25px rgba(0,0,0,0.1);
}

.faq-item:hover::before {
  opacity: 1;
}

.faq-question {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: 1rem;
}

.faq-question svg {
  color: var(--primary-color);
  font-size: 1.2rem;
  margin-top: 0.2rem;
}

.faq-question h3 {
  color: var(--primary-dark);
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1.4;
}

.faq-item p {
  color: #666;
  line-height: 1.6;
  font-size: 0.95rem;
  margin-left: 2.2rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .faq-grid {
    grid-template-columns: 1fr;
    padding: 0 1rem;
  }

  .form-row {
    grid-template-columns: 1fr;
  }

  .contact-form {
    padding: 1rem;
  }
}

@media (max-width: 480px) {
  .faq-section h2 {
    font-size: 1.8rem;
  }

  .faq-item {
    padding: 1.2rem;
  }
}

/* Responsive adjustments for map overlay */
@media (max-width: 992px) {
  .map-overlay {
    position: relative;
    top: 0;
    right: 0;
    max-width: 100%;
    margin-bottom: 1rem;
  }

  .direction-item, .transport-options, .landmark-info {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 480px) {
  .map-overlay {
    padding: 1rem;
  }

  .map-overlay h2 {
    font-size: 1.5rem;
  }

  .direction-item h3,
  .transport-options h3,
  .landmark-info h3 {
    font-size: 1rem;
  }
}

.form-status {
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 8px;
  text-align: center;
}

.form-status.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.form-status.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

.submit-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/* Enhanced Carousel Design for Google Reviews */
.reviews-carousel-section {
  padding: 5rem 0;
  background: linear-gradient(to right, #f8f9fa, #fff, #f8f9fa);
  position: relative;
  overflow: hidden;
}

.reviews-carousel-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ff7b25' fill-opacity='0.05'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  opacity: 0.5;
}

.section-subtitle {
  text-align: center;
  max-width: 700px;
  margin: 0 auto 3rem;
  color: #666;
  font-size: 1.1rem;
  line-height: 1.6;
}

.reviews-carousel-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 2rem;
  background: white;
  border-radius: 20px;
  box-shadow: 
    0 15px 40px rgba(0,0,0,0.08),
    0 0 30px rgba(255, 123, 37, 0.1);
  position: relative;
  overflow: hidden;
  border: 1px solid rgba(255, 123, 37, 0.1);
}

/* Carousel Navigation Controls */
.carousel-controls {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  gap: 1rem;
}

.carousel-control {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
  color: var(--primary-color);
  font-size: 1.2rem;
}

.carousel-control:hover {
  background: var(--primary-color);
  color: white;
  transform: translateY(-3px);
  box-shadow: 0 8px 20px rgba(255, 123, 37, 0.3);
}

.carousel-dots {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 1rem;
}

.carousel-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #ddd;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.carousel-dot.active {
  background: var(--primary-color);
  transform: scale(1.3);
}

/* Carousel Animation */
.reviews-carousel-inner {
  position: relative;
  overflow: hidden;
  min-height: 400px;
}

.carousel-slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.5s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-slide.active {
  opacity: 1;
  z-index: 1;
}

/* Decorative elements */
.reviews-carousel-container::before,
.reviews-carousel-container::after {
  content: '"';
  font-family: Georgia, serif;
  font-size: 120px;
  position: absolute;
  color: rgba(255, 123, 37, 0.1);
  line-height: 1;
  z-index: 0;
}

.reviews-carousel-container::before {
  top: -20px;
  left: 20px;
}

.reviews-carousel-container::after {
  bottom: -70px;
  right: 20px;
  transform: rotate(180deg);
}

/* Ensure the Elfsight widget has enough space */
.reviews-carousel-section .elfsight-app-83a82b91-c1f2-471f-89cf-d1d12175f139 {
  width: 100%;
  min-height: 400px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .reviews-carousel-section {
    padding: 4rem 1.5rem;
  }
  
  .reviews-carousel-container {
    padding: 1.5rem;
  }
  
  .carousel-control {
    width: 40px;
    height: 40px;
    font-size: 1rem;
  }
  
  .reviews-carousel-container::before,
  .reviews-carousel-container::after {
    font-size: 80px;
  }
}

@media (max-width: 576px) {
  .reviews-carousel-section {
    padding: 3rem 1rem;
  }
  
  .reviews-carousel-container {
    padding: 1rem;
    border-radius: 15px;
  }
  
  .section-subtitle {
    font-size: 1rem;
    margin-bottom: 2rem;
  }
  
  .carousel-controls {
    margin-top: 1.5rem;
  }
  
  .carousel-control {
    width: 36px;
    height: 36px;
  }
  
  .reviews-carousel-container::before,
  .reviews-carousel-container::after {
    display: none;
  }
} 