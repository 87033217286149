.park-hero {
  height: 85vh;
  background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)),
              url('/public/images/tripBanner.jpeg') center/cover fixed;
  position: relative;
  overflow: hidden;
}

.park-hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at center, transparent 0%, rgba(0,0,0,0.6) 100%);
}

.hero-content {
  max-width: 900px;
  text-align: center;
  padding: 2rem;
  background: rgba(88, 81, 81, 0.098);
  backdrop-filter: blur(8px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.hero-content h1 {
  font-size: 4.5rem;
  margin-bottom: 1.5rem;
  color: white;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
  font-weight: 800;
  letter-spacing: 2px;
}

/* Overview Section */
.overview-content {
  margin-top: 2rem;
}

.lead-text {
  font-size: 1.2rem;
  line-height: 1.8;
  color: #333;
  margin-bottom: 2rem;
}

.park-facts {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  margin-top: 2rem;
  padding: 2rem;
  background: #f8f9fa;
  border-radius: 15px;
}

.fact-column h3 {
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.fact-column ul {
  list-style: none;
  padding: 0;
}

.fact-column ul li {
  padding: 0.5rem 0;
  border-bottom: 1px solid #eee;
}

/* Key Features */
.key-features {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 3rem;
}

.feature {
  background: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0,0,0,0.1);
  transition: transform 0.3s ease;
  text-align: center;
}

.feature:hover {
  transform: translateY(-10px);
}

.feature svg {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

/* Activities Section */
.activities-section {
  background: linear-gradient(to bottom, #fff, #f8f9fa);
  padding: 8rem 0;
}

.activities-section .container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.activities-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
  margin-top: 3rem;
}

.activity-card {
  position: relative;
  background: white;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.activity-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 30px rgba(0,0,0,0.15);
}

.activity-image {
  height: 280px;
  position: relative;
  overflow: hidden;
  background-color: #f8f9fa;
}

.activity-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.activity-image.loaded img {
  opacity: 1;
}

/* Loading state */
.activity-image::after {
  content: 'Loading...';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #666;
  font-size: 1rem;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.activity-image.loaded::after {
  opacity: 0;
}

.activity-card:hover .activity-image img {
  transform: scale(1.1);
}

.activity-badge {
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 2;
}

.duration, .difficulty {
  background: rgba(255, 123, 37, 0.9);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 25px;
  font-size: 0.9rem;
  font-weight: 600;
  backdrop-filter: blur(5px);
}

.difficulty {
  background: rgba(52, 152, 219, 0.9);
}

.activity-content {
  padding: 2rem;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.activity-header {
  margin-bottom: 1.5rem;
}

.activity-header h3 {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.season {
  color: #666;
  font-size: 0.9rem;
}

.activity-description {
  color: #666;
  line-height: 1.6;
  margin-bottom: 2rem;
}

.activity-highlights {
  background: #f8f9fa;
  padding: 1.5rem;
  border-radius: 15px;
  margin-bottom: 2rem;
}

.activity-highlights h4 {
  color: var(--primary-color);
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

.activity-highlights ul {
  list-style: none;
  padding: 0;
}

.activity-highlights li {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  padding: 0.5rem 0;
  color: #555;
}

.highlight-icon {
  color: var(--primary-color);
  font-size: 1.1rem;
}

.book-activity-btn {
  margin-top: auto;
  background: var(--primary-color);
  color: white;
  border: none;
  padding: 1rem 2rem;
  border-radius: 10px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
}

.book-activity-btn:hover {
  background: var(--primary-dark);
  transform: translateY(-2px);
}

.arrow-icon {
  transition: transform 0.3s ease;
}

.book-activity-btn:hover .arrow-icon {
  transform: translateX(5px);
}

/* Wildlife Section */
.wildlife-section {
  background: #f8f9fa;
  padding: 6rem 0;
}

.wildlife-section .section-intro {
  max-width: 800px;
  margin: 0 auto 3rem;
  text-align: center;
  color: #666;
}

.wildlife-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
  margin-top: 3rem;
}

.wildlife-card {
  background: white;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.wildlife-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 30px rgba(0,0,0,0.15);
}

.wildlife-image {
  height: 250px;
  overflow: hidden;
}

.wildlife-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.wildlife-card:hover .wildlife-image img {
  transform: scale(1.1);
}

.wildlife-content {
  padding: 2rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.wildlife-content h3 {
  color: var(--primary-color);
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.wildlife-content p {
  color: #666;
  line-height: 1.6;
}

/* Visit Info Section */
.visit-info {
  padding: 6rem 0;
  background: white;
}

.info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 3rem;
}

.info-card {
  background: white;
  padding: 2.5rem;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0,0,0,0.1);
  text-align: center;
  transition: transform 0.3s ease;
}

.info-card:hover {
  transform: translateY(-10px);
}

.info-card svg {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 1.5rem;
}

/* Responsive Design */
@media (max-width: 1200px) {
  .activities-grid {
    grid-template-columns: repeat(2, minmax(300px, 1fr));
  }
  
  .park-facts {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}

@media (max-width: 768px) {
  .hero-content h1 {
    font-size: 3rem;
  }
  
  .activities-grid,
  .wildlife-grid {
    grid-template-columns: 1fr;
  }
  
  .activity-card {
    min-width: 280px;
    max-width: 100%;
  }
  
  .activities-section .container {
    padding: 0 1rem;
  }
  
  .activity-image {
    height: 200px;
  }
  
  .info-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
} 

/* Migration Tracker */
.migration-tracker {
  background: #f8f9fa;
  padding: 6rem 0;
}

.migration-map {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: 3rem;
  margin-top: 3rem;
}

.map-container {
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0,0,0,0.1);
}

.map-container iframe {
  display: block;
}

.migration-info {
  padding: 2rem;
  background: white;
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0,0,0,0.1);
}

.current-location {
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid #eee;
}

.current-location h3 {
  color: var(--primary-color);
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.migration-timeline {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.timeline-item {
  padding: 1rem;
  border-radius: 10px;
  background: #f8f9fa;
  transition: all 0.3s ease;
}

.timeline-item.active {
  background: var(--primary-color);
  color: white;
  transform: scale(1.05);
}

.timeline-item .month {
  display: block;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.timeline-item .location {
  display: block;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.timeline-item p {
  font-size: 0.9rem;
  opacity: 0.9;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .migration-map {
    grid-template-columns: 1fr;
  }
  
  .map-container {
    height: 400px;
  }
}

@media (max-width: 768px) {
  .migration-tracker {
    padding: 4rem 0;
  }
  
  .map-container {
    height: 300px;
  }
  
  .migration-info {
    padding: 1.5rem;
  }
  
  .timeline-item {
    padding: 0.75rem;
  }
} 

/* Landscape Gallery */
.landscape-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-top: 3rem;
}

.landscape-card {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  height: 400px;
  cursor: pointer;
}

.landscape-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.landscape-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(transparent, rgba(0,0,0,0.8));
  padding: 2rem;
  color: white;
  transform: translateY(100%);
  transition: transform 0.3s ease;
}

.landscape-card:hover img {
  transform: scale(1.1);
}

.landscape-card:hover .landscape-overlay {
  transform: translateY(0);
}

/* Wildlife Carousel */
.wildlife-carousel {
  position: relative;
  margin: 3rem auto;
  max-width: 1000px;
}

.wildlife-slide {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  height: 500px;
}

.wildlife-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slide-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 2rem;
  background: linear-gradient(transparent, rgba(0,0,0,0.8));
  color: white;
}

/* 360° Virtual Experience styles */
.experience-360 {
  background: #f8f9fa;
  padding: 6rem 0;
}

.virtual-tour {
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0,0,0,0.1);
  margin-top: 2rem;
}

.virtual-tour iframe {
  display: block;
}

.virtual-tour-info {
  text-align: center;
  margin-top: 2rem;
  color: #666;
}

.tour-controls {
  margin-top: 1rem;
  padding: 1rem;
  background: white;
  border-radius: 10px;
  display: inline-block;
}

.tour-controls span {
  font-size: 0.9rem;
  color: #666;
}

@media (max-width: 768px) {
  .virtual-tour iframe {
    height: 300px;
  }
}

/* Enhanced Animations */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.section-intro {
  animation: fadeInUp 0.6s ease forwards;
}

/* Responsive adjustments */
@media (max-width: 992px) {
  .landscape-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .current-location {
    position: relative;
    bottom: auto;
    right: auto;
    margin-top: 2rem;
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .landscape-grid {
    grid-template-columns: 1fr;
  }
  
  .landscape-card {
    height: 300px;
  }
  
  .wildlife-slide {
    height: 400px;
  }
} 

/* Wildlife Carousel Enhancements */
.wildlife-carousel {
  position: relative;
  margin: 3rem auto;
  max-width: 1000px;
}

.carousel-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.8);
  border: none;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 2;
  transition: all 0.3s ease;
}

.carousel-btn:hover {
  background: white;
  transform: translateY(-50%) scale(1.1);
}

.carousel-btn.prev {
  left: 20px;
}

.carousel-btn.next {
  right: 20px;
}

.carousel-dots {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
  z-index: 2;
}

.dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5);
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.dot.active {
  background: var(--primary-color);
  transform: scale(1.2);
}

/* Booking Section */
.booking-section {
  padding: 6rem 0;
  background: linear-gradient(rgba(0,0,0,0.8), rgba(0,0,0,0.8)),
              url('https://img.freepik.com/free-photo/safari-camp-serengeti-sunset_181624-48856.jpg') center/cover fixed;
  color: white;
}

.booking-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 3rem;
  margin-top: 3rem;
}

.booking-form {
  background: rgba(255, 255, 255, 0.1);
  padding: 2.5rem;
  border-radius: 20px;
  backdrop-filter: blur(10px);
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.booking-form input,
.booking-form select,
.booking-form textarea {
  width: 100%;
  padding: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  color: white;
}

.submit-btn {
  width: 100%;
  padding: 1rem;
  background: var(--primary-color);
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.quick-contact {
  background: rgba(255, 255, 255, 0.1);
  padding: 2.5rem;
  border-radius: 20px;
  backdrop-filter: blur(10px);
}

.whatsapp-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background: #25D366;
  color: white;
  padding: 1rem;
  border-radius: 10px;
  text-decoration: none;
  margin-bottom: 1.5rem;
  transition: all 0.3s ease;
}

/* Enhanced Migration Map */
.map-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  border-radius: 15px;
}

.map-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.migration-timeline {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1.5rem;
}

.timeline-item {
  background: rgba(255, 255, 255, 0.1);
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
}

.timeline-item.active {
  background: var(--primary-color);
}

/* Responsive adjustments */
@media (max-width: 992px) {
  .booking-container {
    grid-template-columns: 1fr;
  }
} 

/* Add more wildlife images for the activities section */
.activity-card:nth-child(1) .activity-image img {
  content: url('https://img.freepik.com/free-photo/wildebeest-migration-serengeti_181624-48857.jpg');
}

.activity-card:nth-child(2) .activity-image img {
  content: url('https://img.freepik.com/free-photo/big-five-animals-serengeti_181624-48858.jpg');
}

.activity-card:nth-child(3) .activity-image img {
  content: url('https://img.freepik.com/free-photo/hot-air-balloon-safari-serengeti_181624-48859.jpg');
} 

/* Enhanced Wide Card Styles */
.wildlife-card.wide-card {
  grid-column: span 2;
  display: grid;
  grid-template-columns: 1.2fr 0.8fr;
  gap: 0;
  background: linear-gradient(to right, white, #f8f9fa);
}

.wide-card .wildlife-image {
  height: 100%;
  position: relative;
  overflow: hidden;
}

.wide-card .wildlife-image::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 50px;
  background: linear-gradient(to right, transparent, white);
}

.wide-card .wildlife-content {
  padding: 3.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Enhanced ecosystem stats */
.ecosystem-stats {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
  margin-top: 2.5rem;
  padding-top: 2.5rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;
}

.ecosystem-stats::before {
  content: '';
  position: absolute;
  top: -1px;
  left: 0;
  width: 50px;
  height: 3px;
  background: var(--primary-color);
}

.stat {
  text-align: center;
  padding: 1.5rem;
  border-radius: 15px;
  background: white;
  box-shadow: 0 4px 15px rgba(0,0,0,0.05);
  transition: all 0.3s ease;
}

.stat:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 25px rgba(0,0,0,0.1);
}

.stat .number {
  display: block;
  font-size: 3rem;
  font-weight: 800;
  color: var(--primary-color);
  margin-bottom: 0.75rem;
  line-height: 1;
  background: linear-gradient(45deg, var(--primary-color), var(--primary-dark));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.stat .label {
  font-size: 1rem;
  color: #444;
  line-height: 1.4;
  font-weight: 500;
}

/* Enhanced animations */
.wildlife-card {
  animation: fadeInUp 0.6s ease forwards;
  opacity: 0;
}

.wildlife-card:nth-child(1) { animation-delay: 0.1s; }
.wildlife-card:nth-child(2) { animation-delay: 0.2s; }
.wildlife-card:nth-child(3) { animation-delay: 0.3s; }
.wildlife-card:nth-child(4) { animation-delay: 0.4s; }
.wildlife-card:nth-child(5) { animation-delay: 0.5s; }
.wildlife-card:nth-child(6) { animation-delay: 0.6s; }
.wildlife-card.wide-card { animation-delay: 0.7s; }

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Enhanced responsive behavior */
@media (max-width: 1400px) {
  .wildlife-grid {
    gap: 2rem;
  }
  
  .wide-card .wildlife-content {
    padding: 2.5rem;
  }
}

@media (max-width: 1200px) {
  .wildlife-card.wide-card {
    grid-template-columns: 1fr;
  }
  
  .wide-card .wildlife-image {
    height: 400px;
  }
  
  .wide-card .wildlife-image::after {
    display: none;
  }
  
  .ecosystem-stats {
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
  }
  
  .stat .number {
    font-size: 2.5rem;
  }
}

@media (max-width: 768px) {
  .wildlife-card.wide-card {
    grid-column: span 1;
  }
  
  .wide-card .wildlife-image {
    height: 300px;
  }
  
  .wide-card .wildlife-content {
    padding: 2rem;
  }
  
  .ecosystem-stats {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
  
  .stat {
    padding: 1.25rem;
  }
  
  .stat .number {
    font-size: 2.25rem;
  }
}

/* Add scroll reveal animation */
.wildlife-section {
  overflow: hidden;
}

.wildlife-grid {
  opacity: 0;
  transform: translateY(30px);
  animation: revealSection 0.8s ease forwards;
  animation-delay: 0.3s;
}

@keyframes revealSection {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
} 

/* Enhanced Activity Cards */
.activity-card {
  position: relative;
  background: white;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.gallery-trigger {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  z-index: 2;
  background: rgba(255, 255, 255, 0.9);
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 25px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.3s ease;
}

.gallery-trigger:hover {
  background: white;
  transform: translateY(-2px);
}

/* Price Information */
.price-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin: 1.5rem 0;
}

.price-option {
  background: #f8f9fa;
  padding: 1rem;
  border-radius: 10px;
  text-align: center;
}

.price-option.luxury {
  background: linear-gradient(45deg, var(--primary-color), var(--primary-dark));
}

.price-option .label {
  display: block;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.price-option .price {
  font-size: 1.2rem;
  font-weight: 700;
}

/* Update: Make sure both label and price are white in luxury option */
.price-option.luxury .label,
.price-option.luxury .price {
  color: white;
}

/* Activity Actions */
.activity-actions {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 1rem;
  margin-top: 2rem;
}

.inquiry-btn {
  background: transparent;
  border: 2px solid var(--primary-color);
  color: var(--primary-color);
  padding: 1rem;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.inquiry-btn:hover {
  background: var(--primary-color);
  color: white;
}

/* Gallery Modal */
.gallery-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.9);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gallery-content {
  max-width: 90%;
  max-height: 90vh;
  position: relative;
}

.gallery-content img {
  max-width: 100%;
  max-height: 80vh;
  object-fit: contain;
}

.gallery-caption {
  color: white;
  text-align: center;
  margin-top: 1rem;
  font-size: 1.1rem;
}

.gallery-nav {
  position: absolute;
  bottom: -3rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 2rem;
  color: white;
}

.close-gallery {
  position: absolute;
  top: 2rem;
  right: 2rem;
  background: none;
  border: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
}

/* Enhanced Mobile Layout */
@media (max-width: 768px) {
  .activities-grid {
    grid-template-columns: 1fr;
    padding: 1rem;
  }

  .activity-card {
    max-width: 100%;
  }

  .price-info,
  .activity-actions {
    grid-template-columns: 1fr;
  }

  .gallery-trigger {
    bottom: 0.5rem;
    left: 0.5rem;
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }

  .activity-content {
    padding: 1.5rem;
  }

  .activity-header h3 {
    font-size: 1.5rem;
  }
} 

/* Update gallery navigation styles */
.gallery-nav button {
  background: rgba(255, 255, 255, 0.2);
  border: none;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;
}

.gallery-nav button:hover {
  background: rgba(255, 255, 255, 0.3);
  transform: scale(1.1);
}

.gallery-nav span {
  font-size: 1.1rem;
  font-weight: 500;
}

/* Add loading state for images */
.activity-image img {
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.5s ease;
}

.activity-image img.loaded {
  opacity: 1;
}

/* Add image error handling */
.activity-image.error::before {
  content: 'Image not available';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #666;
  font-size: 1rem;
} 

.activity-card:hover .activity-image {
  transform: scale(1.05);
} 

/* Enhanced image handling */
.activity-image {
  height: 280px;
  position: relative;
  overflow: hidden;
  background-color: #f8f9fa;
}

.activity-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.activity-image.loaded img {
  opacity: 1;
}

/* Loading state */
.activity-image::after {
  content: 'Loading...';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #666;
  font-size: 1rem;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.activity-image.loaded::after {
  opacity: 0;
}

/* Enhanced mobile layout */
@media (max-width: 768px) {
  .activities-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
    padding: 1rem;
  }

  .activity-image {
    height: 220px;
  }

  .activity-content {
    padding: 1.25rem;
  }

  .price-info {
    grid-template-columns: 1fr;
    gap: 0.75rem;
  }

  .activity-actions {
    grid-template-columns: 1fr;
    gap: 0.75rem;
  }

  .gallery-trigger {
    font-size: 0.9rem;
    padding: 0.5rem 1rem;
  }
}

/* Smooth transitions */
.activity-card {
  transform: translateY(0);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.activity-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 30px rgba(0,0,0,0.15);
}

.activity-image img {
  transform: scale(1);
  transition: transform 0.5s ease;
}

.activity-card:hover .activity-image img {
  transform: scale(1.1);
} 

/* Enhanced Gallery Modal Styles */
.gallery-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gallery-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.95);
  backdrop-filter: blur(8px);
  animation: fadeIn 0.3s ease;
}

.gallery-container {
  position: relative;
  width: 90%;
  max-width: 1200px;
  background: #1a1a1a;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 25px 50px rgba(0,0,0,0.25);
  z-index: 1;
  animation: modalSlideUp 0.4s ease;
}

.gallery-image-container {
  position: relative;
  width: 100%;
  height: 60vh;
  background: #000;
  overflow: hidden;
  touch-action: none; /* Prevent default touch actions */
}

.gallery-image-container.zoomed {
  cursor: zoom-out;
}

.gallery-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: transform 0.3s ease;
  will-change: transform; /* Optimize performance */
}

.gallery-image-container.zoomed .gallery-image {
  transform: scale(2.5);
}

.zoom-instructions {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.gallery-image-container:hover .zoom-instructions {
  opacity: 1;
}

.gallery-thumbnails {
  display: flex;
  gap: 0.5rem;
  padding: 1rem;
  background: #222;
  overflow-x: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--primary-color) #333;
}

.thumbnail {
  flex: 0 0 80px;
  height: 60px;
  border-radius: 8px;
  overflow: hidden;
  opacity: 0.6;
  transition: all 0.3s ease;
  cursor: pointer;
}

.thumbnail:hover {
  opacity: 0.8;
}

.thumbnail.active {
  opacity: 1;
  box-shadow: 0 0 0 2px var(--primary-color);
}

.thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Enhanced Animations */
@keyframes modalSlideUp {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Keyboard Navigation Hint */
.keyboard-hint {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(255, 255, 255, 0.1);
  padding: 0.5rem 1rem;
  border-radius: 20px;
  color: white;
  font-size: 0.9rem;
  display: flex;
  gap: 1rem;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.gallery-modal:hover .keyboard-hint {
  opacity: 1;
}

.keyboard-hint span {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

/* Mobile Adjustments */
@media (max-width: 768px) {
  .gallery-thumbnails {
    padding: 0.5rem;
  }
  
  .thumbnail {
    flex: 0 0 60px;
    height: 45px;
  }
  
  .gallery-image-container.zoomed .gallery-image {
    transform: scale(1.5);
  }
} 

/* Enhanced Gallery Styles */
.gallery-image-container {
  position: relative;
  width: 100%;
  height: 60vh;
  background: #000;
  overflow: hidden;
  touch-action: none; /* Prevent default touch actions */
}

.gallery-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: transform 0.3s ease;
  will-change: transform; /* Optimize performance */
}

.gallery-controls {
  position: absolute;
  bottom: 1rem;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  z-index: 3;
}

.keyboard-shortcuts {
  display: flex;
  gap: 1rem;
  background: rgba(0, 0, 0, 0.7);
  padding: 0.75rem 1.5rem;
  border-radius: 30px;
  color: white;
  font-size: 0.9rem;
}

.keyboard-shortcuts span {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  opacity: 0.8;
}

/* Enhanced Mobile Styles */
@media (max-width: 768px) {
  .gallery-image-container {
    height: 50vh;
  }

  .keyboard-shortcuts {
    display: none; /* Hide keyboard shortcuts on mobile */
  }

  .gallery-thumbnails {
    height: 70px;
  }

  .thumbnail {
    flex: 0 0 50px;
    height: 50px;
  }

  .zoom-instructions {
    font-size: 0.8rem;
    padding: 0.5rem 0.75rem;
  }

  .gallery-container {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  .gallery-image-container.zoomed .gallery-image {
    transform: scale(2);
  }
}

/* Touch Gesture Indicators */
.touch-indicator {
  position: absolute;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.touch-indicator.visible {
  opacity: 1;
}

/* Smooth Animations */
.gallery-image-container {
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.gallery-image.dragging {
  transition: none;
} 

.gallery-image.error {
  opacity: 0.7;
  filter: grayscale(1);
}

.gallery-image.error::after {
  content: 'Image not available';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 0.9rem;
} 

/* Enhanced Virtual Tour Styles */
.virtual-tour-selector {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
}

.viewpoint-btn {
  padding: 0.8rem 1.5rem;
  border: 2px solid var(--primary-color);
  background: transparent;
  color: var(--primary-color);
  border-radius: 25px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.viewpoint-btn:hover {
  background: var(--primary-color);
  color: white;
}

.viewpoint-btn.active {
  background: var(--primary-color);
  color: white;
}

.viewpoint-description {
  margin: 1.5rem 0;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  text-align: center;
  font-style: italic;
  color: #666;
}

.virtual-tour {
  position: relative;
  transition: opacity 0.3s ease;
}

.virtual-tour iframe {
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0,0,0,0.15);
  transition: transform 0.3s ease;
}

.virtual-tour:hover iframe {
  transform: scale(1.01);
}

/* Mobile Responsive */
@media (max-width: 768px) {
  .virtual-tour-selector {
    flex-wrap: wrap;
  }
  
  .viewpoint-btn {
    width: calc(50% - 0.5rem);
    padding: 0.6rem 1rem;
    font-size: 0.9rem;
  }
  
  .virtual-tour iframe {
    height: 300px;
  }
} 