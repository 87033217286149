.custom-trip-builder {
  position: fixed;
  top: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f8fafc;
  z-index: 1000;
  overflow-y: auto;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  box-shadow: 0 -4px 20px rgba(0, 0, 0, 0.1);
}

.builder-header {
  background: linear-gradient(135deg, #1a2b3c 0%, #2c3e50 100%);
  padding: 40px 30px;
  color: white;
  text-align: center;
  position: relative;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
}

.builder-header h1 {
  font-size: 2.5rem;
  font-weight: 800;
  margin-bottom: 15px;
  background: linear-gradient(to right, #fff, #e2e8f0);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.builder-header p {
  font-size: 1.1rem;
  color: #cbd5e1;
  max-width: 600px;
  margin: 0 auto;
  line-height: 1.6;
}

.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  backdrop-filter: blur(5px);
}

.close-btn:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: rotate(90deg);
}

.destination-circuit {
  margin: 30px auto;
  max-width: 1400px;
  padding: 0 30px;
}

.circuit-header {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 30px;
  background: white;
  padding: 20px;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
}

.circuit-icon {
  width: 60px;
  height: 60px;
  background: linear-gradient(135deg, #4CAF50 0%, #45a049 100%);
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 24px;
  box-shadow: 0 8px 20px rgba(76, 175, 80, 0.2);
}

.circuit-title h3 {
  font-size: 1.5rem;
  font-weight: 700;
  color: #1a2b3c;
  margin: 0 0 8px 0;
}

.circuit-title p {
  color: #64748b;
  margin: 0;
  font-size: 1rem;
}

.destination-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 12px;
  padding: 12px;
}

.destination-card {
  min-height: 140px;
  padding: 10px;
}

.card-content {
  gap: 6px;
}

.destination-title {
  font-size: 0.9rem;
  line-height: 1.2;
  padding-right: 35px;
}

.park-info {
  padding: 6px;
  margin: 2px 0;
  gap: 4px;
}

.highlights {
  padding: 6px;
  margin: 2px 0;
}

.highlight-content {
  gap: 3px;
  font-size: 0.8rem;
}

.highlight-item {
  gap: 2px;
}

.destination-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 6px;
  background: linear-gradient(90deg, #4CAF50, #45a049);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.destination-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-color: #4CAF50;
}

.destination-card:hover::before {
  opacity: 1;
}

.destination-card.selected {
  border: 2px solid #4CAF50;
  background: linear-gradient(to bottom right, #ffffff, #f0fdf4);
}

.destination-card.selected::before {
  opacity: 1;
}

.card-status {
  position: absolute;
  top: 12px;
  right: 12px;
  background: #f0fdf4;
  padding: 4px 8px;
  border-radius: 8px;
  font-size: 0.9rem;
  font-weight: 600;
  color: #4CAF50;
  display: flex;
  align-items: center;
  gap: 4px;
  border: 1px solid #dcfce7;
  z-index: 3;
}

.card-status svg {
  font-size: 0.9rem;
}

.select-indicator {
  position: absolute;
  top: -8px;
  left: -8px;
  width: 28px;
  height: 28px;
  background: #4CAF50;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 0.9rem;
  opacity: 0;
  transform: scale(0.8);
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px rgba(76, 175, 80, 0.4);
  z-index: 3;
}

.destination-card.selected {
  border: 2px solid #4CAF50;
  background: linear-gradient(to bottom right, #ffffff, #f0fdf4);
  box-shadow: 0 8px 16px rgba(76, 175, 80, 0.15);
  transform: translateY(-2px);
}

.destination-card.selected .select-indicator {
  opacity: 1;
  transform: scale(1);
}

.builder-actions {
  position: sticky;
  bottom: 0;
  background: white;
  padding: 20px;
  box-shadow: 0 -4px 20px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  z-index: 10;
}

.next-btn {
  background: #4CAF50;
  color: white;
  border: none;
  padding: 12px 30px;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.3s ease;
}

.next-btn:hover {
  background: #45a049;
  transform: translateY(-2px);
}

.next-btn:disabled {
  background: #ccc;
  cursor: not-allowed;
  transform: none;
}

@media (max-width: 1200px) {
  .destination-grid {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }
}

@media (max-width: 768px) {
  .custom-trip-builder {
    top: 64px;
  }
  
  .destination-grid {
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    gap: 15px;
    padding: 15px;
  }
  
  .builder-header h1 {
    font-size: 2rem;
  }

  .destination-card {
    height: 160px;
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .custom-trip-builder {
    top: 56px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
  
  .builder-header {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    padding: 30px 20px;
  }
  
  .destination-grid {
    grid-template-columns: 1fr;
  }
  
  .builder-header h1 {
    font-size: 1.8rem;
  }
}

.builder-progress {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  background: white;
  position: sticky;
  top: 0;
  z-index: 100;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
}

.progress-step {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 40px;
  font-size: 0.95rem;
  font-weight: 500;
  color: #94a3b8;
  transition: all 0.3s ease;
}

.progress-step::before {
  content: '';
  width: 32px;
  height: 32px;
  background: #f1f5f9;
  border-radius: 50%;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  font-size: 14px;
  border: 2px solid #e2e8f0;
}

.progress-step:not(:last-child)::after {
  content: '';
  position: absolute;
  right: -15px;
  top: 50%;
  transform: translateY(-50%);
  width: 70px;
  height: 2px;
  background: #e2e8f0;
  z-index: 1;
}

.progress-step.active {
  color: #4CAF50;
}

.progress-step.active::before {
  background: #4CAF50;
  border-color: #4CAF50;
  color: white;
  box-shadow: 0 0 0 4px rgba(76, 175, 80, 0.2);
}

.progress-step.completed {
  color: #4CAF50;
}

.progress-step.completed::before {
  background: #4CAF50;
  border-color: #4CAF50;
  color: white;
}

.progress-step:nth-child(1)::before {
  content: '1';
}

.progress-step:nth-child(2)::before {
  content: '2';
}

.progress-step:nth-child(3)::before {
  content: '3';
}

.progress-step:nth-child(4)::before {
  content: '4';
}

.progress-step.completed::before {
  content: '✓';
}

@media (max-width: 768px) {
  .builder-progress {
    padding: 20px 15px;
    overflow-x: auto;
    justify-content: flex-start;
  }

  .progress-step {
    padding: 0 25px;
    font-size: 0.85rem;
    flex-shrink: 0;
  }

  .progress-step::before {
    width: 28px;
    height: 28px;
    margin-right: 8px;
  }

  .progress-step:not(:last-child)::after {
    width: 40px;
    right: -5px;
  }
}

@media (max-width: 480px) {
  .builder-progress {
    padding: 15px 10px;
  }

  .progress-step {
    padding: 0 20px;
    font-size: 0.8rem;
  }

  .progress-step::before {
    width: 24px;
    height: 24px;
    margin-right: 6px;
  }

  .progress-step:not(:last-child)::after {
    width: 30px;
    right: -5px;
  }
}

/* Responsive adjustments */
@media (min-width: 1400px) {
  .destination-grid {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }
}

@media (min-width: 1600px) {
  .destination-grid {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  }
}

@media (max-width: 768px) {
  .destination-grid {
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    gap: 10px;
  }

  .card-image {
    height: 120px;
  }

  .card-content {
    padding: 8px;
  }
}

/* Separator dots between highlights */
.highlight-item:not(:last-child)::after {
  content: "•";
  margin-left: 8px;
  color: #cbd5e1;
}

@media (max-width: 1400px) {
  .destination-grid {
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  }
}

@media (max-width: 768px) {
  .destination-grid {
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    gap: 15px;
    padding: 15px;
  }

  .destination-card {
    padding: 15px;
  }
}

/* Separator between highlights */
.highlight-item:not(:last-child)::after {
  content: "•";
  margin: 0 4px;
  color: #cbd5e1;
}

/* Responsive layouts */
@media (min-width: 1600px) {
  .destination-grid {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  .destination-grid {
    grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .destination-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

@media (max-width: 767px) {
  .destination-grid {
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    gap: 10px;
    padding: 10px;
  }

  .destination-card {
    padding: 12px;
    min-height: 150px;
  }

  .card-content {
    gap: 6px;
  }
}

/* Responsive layouts */
@media (min-width: 1800px) {
  .destination-grid {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media (min-width: 1400px) and (max-width: 1799px) {
  .destination-grid {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (min-width: 1024px) and (max-width: 1399px) {
  .destination-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .destination-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 767px) {
  .destination-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    padding: 12px;
  }

  .destination-card {
    min-height: 160px;
    padding: 12px;
  }
}

/* Responsive layouts */
@media (min-width: 2000px) {
  .destination-grid {
    grid-template-columns: repeat(8, 1fr);
  }
}

@media (min-width: 1600px) and (max-width: 1999px) {
  .destination-grid {
    grid-template-columns: repeat(7, 1fr);
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  .destination-grid {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .destination-grid {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .destination-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .destination-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 575px) {
  .destination-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    padding: 8px;
  }

  .destination-card {
    min-height: 150px;
    padding: 10px;
  }
}

@media (max-width: 767px) {
  .destination-card[data-park-id="arusha"],
  .destination-card[data-park-id="kilimanjaro"] {
    grid-column: span 1;
    min-height: 180px;
    padding: 16px;
  }
}

.destination-card.large-card {
  grid-column: span 3;
  min-height: 240px;
  padding: 24px;
  background: linear-gradient(to bottom right, #ffffff, #f8fafc);
}

.destination-card.large-card .destination-title {
  font-size: 1.4rem;
  padding-right: 70px;
  margin-bottom: 8px;
  line-height: 1.4;
}

.destination-card.large-card .destination-title svg {
  font-size: 1.4rem;
}

.destination-card.large-card .park-info {
  padding: 16px;
  margin: 16px 0;
  gap: 16px;
  background: white;
}

.destination-card.large-card .info-row {
  font-size: 1.1rem;
  gap: 8px;
}

.destination-card.large-card .info-row svg {
  font-size: 1.2rem;
}

.destination-card.large-card .highlights {
  padding: 16px;
  margin: 16px 0;
  background: white;
}

.destination-card.large-card .highlight-content {
  font-size: 1.1rem;
  gap: 12px;
}

.destination-card.large-card .highlight-item {
  gap: 6px;
}

.destination-card.large-card .highlight-item svg {
  font-size: 1.1rem;
}

.destination-card.large-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.12);
}

@media (max-width: 1200px) {
  .destination-card.large-card {
    grid-column: span 2;
    min-height: 220px;
    padding: 20px;
  }
}

@media (max-width: 767px) {
  .destination-card.large-card {
    grid-column: span 2;
    min-height: 200px;
    padding: 16px;
  }
  
  .destination-card.large-card .destination-title {
    font-size: 1.2rem;
    padding-right: 50px;
  }
  
  .destination-card.large-card .park-info {
    padding: 12px;
    margin: 12px 0;
    gap: 10px;
  }
  
  .destination-card.large-card .info-row {
    font-size: 1rem;
  }
  
  .destination-card.large-card .highlights {
    padding: 12px;
    margin: 12px 0;
  }
  
  .destination-card.large-card .highlight-content {
    font-size: 1rem;
    gap: 8px;
  }
}

@media (max-width: 575px) {
  .destination-card.large-card {
    grid-column: span 2;
    min-height: 180px;
  }
}

.destination-card[data-circuit="western"] {
  min-height: 180px;
  padding: 16px;
  background: linear-gradient(to bottom right, #ffffff, #f8fafc);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

.destination-card[data-circuit="western"] .destination-title {
  font-size: 1.1rem;
  padding-right: 50px;
  margin-bottom: 8px;
  line-height: 1.3;
}

.destination-card[data-circuit="western"] .park-info {
  padding: 12px;
  margin: 10px 0;
  gap: 10px;
  background: white;
  border-radius: 8px;
}

.destination-card[data-circuit="western"] .info-row {
  font-size: 0.95rem;
  gap: 6px;
}

.destination-card[data-circuit="western"] .highlights {
  padding: 12px;
  margin: 10px 0;
  background: white;
  border-radius: 8px;
}

.destination-card[data-circuit="western"] .highlight-content {
  font-size: 0.95rem;
  gap: 8px;
}

.destination-card[data-circuit="western"]:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.12);
}

/* Responsive adjustments for western circuit */
@media (min-width: 1800px) {
  .destination-grid .destination-card[data-circuit="western"] {
    grid-column: span 2;
  }
}

@media (max-width: 1799px) {
  .destination-grid .destination-card[data-circuit="western"] {
    grid-column: span 2;
  }
}

@media (max-width: 767px) {
  .destination-card[data-circuit="western"] {
    min-height: 160px;
    padding: 12px;
  }
  
  .destination-card[data-circuit="western"] .destination-title {
    font-size: 1rem;
    padding-right: 40px;
  }
  
  .destination-card[data-circuit="western"] .park-info,
  .destination-card[data-circuit="western"] .highlights {
    padding: 8px;
    margin: 8px 0;
  }
  
  .destination-card[data-circuit="western"] .info-row,
  .destination-card[data-circuit="western"] .highlight-content {
    font-size: 0.9rem;
  }
} 

/* Accommodation Section Styles */
.section-header {
  text-align: center;
  margin-bottom: 40px;
  padding: 0 20px;
}

.section-header h2 {
  font-size: 2.2rem;
  font-weight: 800;
  color: #1a2b3c;
  margin-bottom: 12px;
  background: linear-gradient(135deg, #1a2b3c 0%, #2c3e50 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section-header p {
  font-size: 1.1rem;
  color: #64748b;
  max-width: 600px;
  margin: 0 auto;
}

.accommodation-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 24px;
  padding: 30px;
  max-width: 1400px;
  margin: 0 auto;
}

.accommodation-card {
  background: white;
  border-radius: 16px;
  padding: 24px;
  position: relative;
  transition: all 0.3s ease;
  border: 2px solid transparent;
  cursor: pointer;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.accommodation-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 6px;
  background: linear-gradient(90deg, #4CAF50, #45a049);
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 16px 16px 0 0;
}

.accommodation-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
}

.accommodation-card:hover::before {
  opacity: 1;
}

.accommodation-card.selected {
  border-color: #4CAF50;
  background: linear-gradient(to bottom right, #ffffff, #f0fdf4);
  box-shadow: 0 12px 24px rgba(76, 175, 80, 0.15);
}

.accommodation-card.selected::before {
  opacity: 1;
}

.accommodation-title {
  font-size: 1.4rem;
  font-weight: 700;
  color: #1a2b3c;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.accommodation-title svg {
  color: #4CAF50;
  font-size: 1.6rem;
}

.accommodation-description {
  color: #64748b;
  margin-bottom: 20px;
  line-height: 1.6;
}

.features-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  gap: 12px;
  margin-top: 20px;
  background: #f8fafc;
  padding: 16px;
  border-radius: 12px;
}

.feature-item {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #1a2b3c;
  font-size: 0.95rem;
  padding: 8px;
  background: white;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.feature-item:hover {
  transform: translateX(4px);
  background: #f0fdf4;
}

.feature-item svg {
  color: #4CAF50;
}

.trip-details-section {
  max-width: 800px;
  margin: 0 auto;
  padding: 30px;
}

.trip-details-form {
  background: white;
  border-radius: 16px;
  padding: 30px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
}

.form-row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 24px;
}

.form-group {
  position: relative;
}

.form-group label {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 600;
  color: #1a2b3c;
  margin-bottom: 8px;
}

.form-group label svg {
  color: #4CAF50;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 12px 16px;
  border: 2px solid #e2e8f0;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.3s ease;
  background: #ffffff;
  color: #1a2b3c;
  font-weight: 500;
}

.form-group input::placeholder,
.form-group textarea::placeholder {
  color: #94a3b8;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  border-color: #4CAF50;
  box-shadow: 0 0 0 3px rgba(76, 175, 80, 0.1);
  outline: none;
  background: #ffffff;
}

.form-group select {
  appearance: none;
  background-color: #ffffff;
  color: #1a2b3c;
  font-weight: 500;
}

.form-group select option {
  background: #ffffff;
  color: #1a2b3c;
}

.input-with-icon span {
  color: #1a2b3c;
  font-weight: 500;
}

/* Summary Screen Styles */
.trip-summary {
  max-width: 1000px;
  margin: 0 auto;
  padding: 40px 30px;
}

.summary-section {
  background: white;
  border-radius: 16px;
  padding: 30px;
  margin-bottom: 30px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  animation: slideIn 0.5s ease forwards;
}

.summary-section h3 {
  font-size: 1.6rem;
  font-weight: 700;
  color: #1a2b3c;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.summary-section h3 svg {
  color: #4CAF50;
  font-size: 1.8rem;
}

.selected-destinations {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
}

.selected-destination-card {
  background: #f8fafc;
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.3s ease;
  border: 2px solid transparent;
  position: relative;
}

.selected-destination-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
  border-color: #4CAF50;
}

.destination-details {
  padding: 20px;
}

.destination-details h4 {
  font-size: 1.2rem;
  font-weight: 600;
  color: #1a2b3c;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.destination-details p {
  color: #64748b;
  font-size: 0.95rem;
  display: flex;
  align-items: center;
  gap: 6px;
}

.destination-details svg {
  color: #4CAF50;
}

.details-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.detail-item {
  background: #f8fafc;
  padding: 16px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  gap: 12px;
  transition: all 0.3s ease;
}

.detail-item:hover {
  background: #f0fdf4;
  transform: translateX(4px);
}

.detail-item svg {
  color: #4CAF50;
  font-size: 1.2rem;
}

.detail-item span {
  color: #64748b;
  font-size: 0.95rem;
}

.detail-item strong {
  color: #1a2b3c;
  font-weight: 600;
  margin-left: auto;
}

.submit-btn {
  background: linear-gradient(135deg, #4CAF50 0%, #45a049 100%);
  color: white;
  border: none;
  padding: 16px 40px;
  border-radius: 12px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 40px auto 0;
  transition: all 0.3s ease;
  box-shadow: 0 8px 20px rgba(76, 175, 80, 0.2);
}

.submit-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 12px 24px rgba(76, 175, 80, 0.3);
}

.submit-btn svg {
  font-size: 1.2rem;
}

.trip-cost {
  text-align: center;
  margin-top: 30px;
  padding: 20px;
  background: linear-gradient(135deg, #1a2b3c 0%, #2c3e50 100%);
  border-radius: 12px;
  color: white;
}

.trip-cost h4 {
  font-size: 1.2rem;
  margin-bottom: 8px;
  opacity: 0.9;
}

.trip-cost .price {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 8px;
}

.trip-cost .price-note {
  font-size: 0.9rem;
  opacity: 0.8;
}

@media (max-width: 768px) {
  .trip-summary {
    padding: 20px;
  }

  .summary-section {
    padding: 20px;
  }

  .selected-destinations {
    grid-template-columns: 1fr;
  }

  .details-grid {
    grid-template-columns: 1fr;
  }

  .submit-btn {
    width: 100%;
    justify-content: center;
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease-out;
  backdrop-filter: blur(5px);
}

.popup-content {
  background: #ffffff;
  border-radius: 12px;
  padding: 32px;
  width: 90%;
  max-width: 400px;
  position: relative;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  animation: slideUp 0.4s ease-out;
  text-align: center;
}

.popup-close {
  position: absolute;
  top: 16px;
  right: 16px;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #64748b;
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
}

.popup-close:hover {
  color: #1e293b;
  transform: scale(1.1);
}

.popup-header {
  text-align: center;
  margin-bottom: 24px;
}

.popup-header.success {
  padding: 20px 0;
  position: relative;
}

.success-icon {
  width: 64px;
  height: 64px;
  background: #4CAF50;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 20px;
  color: white;
  font-size: 32px;
  animation: scaleIn 0.5s ease-out;
  box-shadow: none;
}

.success-icon::after {
  display: none;
}

.popup-header h2 {
  font-size: 24px;
  color: #1a2b3c;
  margin-bottom: 12px;
  font-weight: 600;
  background: none;
  -webkit-text-fill-color: initial;
}

.popup-header p {
  font-size: 16px;
  line-height: 1.5;
  color: #64748b;
  margin: 0 auto;
}

.popup-form {
  margin-top: 24px;
}

.popup-form .form-group {
  margin-bottom: 24px;
}

.popup-form label {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #1e293b;
  font-weight: 500;
  margin-bottom: 8px;
}

.popup-form input {
  width: 100%;
  padding: 12px 16px;
  border: 2px solid #e2e8f0;
  border-radius: 8px;
  font-size: 1rem;
  color: #1e293b;
  transition: all 0.2s;
}

.popup-form input:focus {
  border-color: #10b981;
  outline: none;
  box-shadow: 0 0 0 3px rgba(16, 185, 129, 0.1);
}

.popup-form input::placeholder {
  color: #94a3b8;
}

.popup-form .submit-btn {
  width: 100%;
  padding: 12px 24px;
  background: #4CAF50;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  margin-top: 24px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.popup-form .submit-btn:hover {
  background: #45a049;
  transform: none;
  box-shadow: none;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0.8);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@media (max-width: 640px) {
  .popup-content {
    padding: 24px;
    width: 85%;
  }
  
  .success-icon {
    width: 56px;
    height: 56px;
    font-size: 28px;
    margin-bottom: 16px;
  }
  
  .popup-header h2 {
    font-size: 20px;
  }
  
  .popup-header p {
    font-size: 14px;
  }
}

.popup-content.success {
  background: linear-gradient(135deg, #ffffff 0%, #f0fdf4 100%);
  padding: 40px;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.popup-content.success::before,
.popup-content.success::after {
  content: '🎉';
  position: absolute;
  font-size: 2rem;
  animation: celebrate 1.5s ease-out infinite;
}

.popup-content.success::before {
  left: 20px;
  top: 20px;
  animation-delay: 0.2s;
}

.popup-content.success::after {
  right: 20px;
  top: 20px;
  animation-delay: 0.5s;
}

.popup-header.success {
  position: relative;
  padding: 30px 0;
  margin-bottom: 30px;
}

.success-icon {
  width: 80px;
  height: 80px;
  background: linear-gradient(135deg, #4CAF50 0%, #45a049 100%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 25px;
  color: white;
  font-size: 40px;
  animation: successPop 0.5s cubic-bezier(0.17, 0.89, 0.32, 1.49);
  box-shadow: 0 10px 20px rgba(76, 175, 80, 0.3);
  position: relative;
}

.success-icon::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 3px solid #4CAF50;
  animation: ripple 1.5s linear infinite;
}

.popup-header.success h3 {
  font-size: 2rem;
  color: #2c3e50;
  margin-bottom: 15px;
  font-weight: 700;
  background: linear-gradient(135deg, #1a2b3c 0%, #2c3e50 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: slideIn 0.5s ease-out;
}

.popup-header.success p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #64748b;
  max-width: 500px;
  margin: 0 auto;
  animation: fadeIn 0.5s ease-out 0.2s both;
}

.popup-btn {
  background: linear-gradient(135deg, #4CAF50 0%, #45a049 100%);
  color: white;
  border: none;
  padding: 15px 30px;
  border-radius: 12px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 30px auto 0;
  transition: all 0.3s ease;
  box-shadow: 0 8px 20px rgba(76, 175, 80, 0.2);
  animation: bounceIn 0.5s cubic-bezier(0.17, 0.89, 0.32, 1.49) 0.5s both;
}

.popup-btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 12px 24px rgba(76, 175, 80, 0.3);
}

@keyframes celebrate {
  0% {
    transform: translateY(0) rotate(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-50px) rotate(360deg);
    opacity: 0;
  }
}

@keyframes successPop {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes ripple {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bounceIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Confetti animation elements */
.confetti {
  position: absolute;
  width: 10px;
  height: 10px;
  background: #4CAF50;
  opacity: 0;
}

.confetti:nth-child(1) { animation: confetti-1 3s ease-out infinite; }
.confetti:nth-child(2) { animation: confetti-2 3s ease-out infinite; background: #FFC107; }
.confetti:nth-child(3) { animation: confetti-3 3s ease-out infinite; background: #2196F3; }
.confetti:nth-child(4) { animation: confetti-4 3s ease-out infinite; background: #FF5722; }

@keyframes confetti-1 {
  0% { transform: translate(0, 0) rotate(0); opacity: 1; }
  100% { transform: translate(-50px, 100px) rotate(360deg); opacity: 0; }
}

@keyframes confetti-2 {
  0% { transform: translate(0, 0) rotate(0); opacity: 1; }
  100% { transform: translate(50px, 100px) rotate(-360deg); opacity: 0; }
}

@keyframes confetti-3 {
  0% { transform: translate(0, 0) rotate(0); opacity: 1; }
  100% { transform: translate(-30px, 80px) rotate(180deg); opacity: 0; }
}

@keyframes confetti-4 {
  0% { transform: translate(0, 0) rotate(0); opacity: 1; }
  100% { transform: translate(30px, 80px) rotate(-180deg); opacity: 0; }
}

@media (max-width: 640px) {
  .popup-content.success {
    padding: 30px 20px;
  }
  
  .success-icon {
    width: 60px;
    height: 60px;
    font-size: 30px;
  }
  
  .popup-header.success h3 {
    font-size: 1.6rem;
  }
  
  .popup-header.success p {
    font-size: 1rem;
  }
  
  .popup-btn {
    width: 100%;
    padding: 12px 20px;
    font-size: 1rem;
  }
}


