.blog-container {
  padding: 0;
  margin: 0;
  width: 100%;
}

.blog-hero {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    var(--hero-bg);
  background-size: cover;
  background-position: center;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  padding: 2rem;
}

.blog-hero h1 {
  font-size: 3.5rem;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.blog-hero p {
  font-size: 1.5rem;
  max-width: 800px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.blog-content {
  max-width: 1200px;
  margin: 4rem auto;
  padding: 0 2rem;
  display: grid;
  grid-template-columns: 1fr 300px;
  gap: 3rem;
}

.blog-main {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
}

.blog-card {
  background: white;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.blog-card:hover {
  transform: translateY(-5px);
}

.blog-image {
  height: 200px;
  overflow: hidden;
}

.blog-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.blog-card:hover .blog-image img {
  transform: scale(1.05);
}

.blog-text {
  padding: 1.5rem;
}

.blog-category {
  color: #4CAF50;
  font-size: 0.9rem;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}

.blog-text h3 {
  color: #333;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  line-height: 1.4;
}

.blog-text p {
  color: #666;
  line-height: 1.6;
  margin-bottom: 1rem;
}

.blog-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #888;
  font-size: 0.9rem;
}

.blog-author {
  display: flex;
  align-items: center;
}

.blog-author img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.blog-sidebar {
  position: sticky;
  top: 2rem;
  height: fit-content;
}

.sidebar-section {
  background: white;
  border-radius: 15px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.sidebar-section h3 {
  color: #333;
  font-size: 1.3rem;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #4CAF50;
}

.category-list {
  list-style: none;
  padding: 0;
}

.category-list li {
  margin-bottom: 0.5rem;
}

.category-list a {
  color: #666;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
  transition: color 0.3s ease;
}

.category-list a:hover {
  color: #4CAF50;
}

.category-count {
  background: #f0f0f0;
  padding: 0.2rem 0.6rem;
  border-radius: 15px;
  font-size: 0.9rem;
}

.popular-posts {
  list-style: none;
  padding: 0;
}

.popular-post {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #eee;
}

.popular-post:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.popular-post img {
  width: 60px;
  height: 60px;
  border-radius: 10px;
  object-fit: cover;
  margin-right: 1rem;
}

.popular-post-content h4 {
  color: #333;
  font-size: 1rem;
  margin-bottom: 0.3rem;
}

.popular-post-content span {
  color: #888;
  font-size: 0.9rem;
}

.search-form {
  display: flex;
  gap: 0.5rem;
}

.search-input {
  flex: 1;
  padding: 0.8rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
}

.search-button {
  padding: 0.8rem 1.5rem;
  background: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.search-button:hover {
  background: #388E3C;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  gap: 0.5rem;
}

.pagination button {
  padding: 0.8rem 1.2rem;
  border: 1px solid #ddd;
  background: white;
  color: #666;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.pagination button:hover {
  background: #4CAF50;
  color: white;
  border-color: #4CAF50;
}

.pagination button.active {
  background: #4CAF50;
  color: white;
  border-color: #4CAF50;
}

@media (max-width: 1024px) {
  .blog-content {
    grid-template-columns: 1fr;
  }

  .blog-sidebar {
    position: static;
  }
}

@media (max-width: 768px) {
  .blog-hero h1 {
    font-size: 2.5rem;
  }

  .blog-hero p {
    font-size: 1.2rem;
  }

  .blog-main {
    grid-template-columns: 1fr;
  }

  .blog-content {
    margin: 2rem auto;
  }
} 