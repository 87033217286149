/* Cultural Tours Page Styling */
.cultural {
  padding-top: 0;
  background: #fff;
}

/* Hero Banner */
.hero-banner {
  height: 70vh;
  background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)),
              url('/public/images/culturalTours/tanzania-cultural-experience-6.jpg') center/cover;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  position: relative;
}

.hero-content {
  max-width: 800px;
  padding: 2rem;
  z-index: 2;
  background: rgba(0,0,0,0.3);
  border-radius: 15px;
  backdrop-filter: blur(5px);
}

.hero-content h1 {
  font-size: 3.5rem;
  margin-bottom: 1.5rem;
  font-weight: 700;
}

.hero-content p {
  font-size: 1.2rem;
  line-height: 1.6;
}

/* Cultural Intro */
.cultural-intro {
  padding: 4rem 2rem;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.cultural-intro h2 {
  color: var(--primary-color);
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  position: relative;
  display: inline-block;
}

.cultural-intro h2::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 3px;
  background: var(--primary-color);
}

.cultural-intro p {
  font-size: 1.1rem;
  color: #555;
  line-height: 1.8;
  max-width: 900px;
  margin: 0 auto;
}

/* Maasai Gallery */
.maasai-gallery {
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding: 0 2rem;
  margin: 2rem auto;
  max-width: 1200px;
  overflow-x: auto;
}

.maasai-item {
  flex: 0 0 auto;
  width: 250px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
  transition: transform 0.3s ease;
}

.maasai-item:hover {
  transform: translateY(-10px);
}

.maasai-item img {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.maasai-info {
  padding: 1rem;
  background: white;
}

.maasai-info h3 {
  color: var(--primary-dark);
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.maasai-info p {
  font-size: 0.9rem;
  color: #666;
}

/* Cultural Heritage */
.cultural-heritage {
  padding: 4rem 2rem;
  background: #f9f9f9;
  text-align: center;
}

.cultural-heritage h2 {
  color: var(--primary-color);
  font-size: 2.5rem;
  margin-bottom: 3rem;
  position: relative;
  display: inline-block;
}

.cultural-heritage h2::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 3px;
  background: var(--primary-color);
}

.heritage-grid {
  display: flex;
  justify-content: center;
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.heritage-card {
  flex: 1;
  max-width: 300px;
  padding: 2rem;
  background: white;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.05);
  transition: transform 0.3s ease;
}

.heritage-card:hover {
  transform: translateY(-10px);
}

.heritage-icon {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 1.5rem;
}

.heritage-card h3 {
  color: var(--primary-dark);
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.heritage-card p {
  color: #666;
  line-height: 1.6;
}

/* Popular Experiences */
.popular-experiences {
  padding: 4rem 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.popular-experiences h2 {
  color: var(--primary-color);
  font-size: 2.5rem;
  margin-bottom: 3rem;
  text-align: center;
  position: relative;
  display: inline-block;
}

.popular-experiences h2::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 3px;
  background: var(--primary-color);
}

.experiences-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.experience-card {
  display: flex;
  background: white;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 5px 20px rgba(0,0,0,0.08);
  transition: transform 0.3s ease;
}

.experience-card:hover {
  transform: translateY(-5px);
}

.experience-card:nth-child(even) {
  flex-direction: row-reverse;
}

.experience-image {
  flex: 0 0 40%;
}

.experience-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.experience-content {
  flex: 1;
  padding: 2rem;
}

.experience-content h3 {
  color: var(--primary-color);
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

.experience-content p {
  color: #555;
  line-height: 1.7;
}

/* Responsive Adjustments */
@media (max-width: 992px) {
  .hero-content h1 {
    font-size: 3rem;
  }
  
  .heritage-grid {
    flex-wrap: wrap;
  }
  
  .heritage-card {
    flex: 0 0 calc(50% - 2rem);
    max-width: none;
  }
  
  .experience-card,
  .experience-card:nth-child(even) {
    flex-direction: column;
  }
  
  .experience-image {
    flex: 0 0 250px;
  }
}

@media (max-width: 768px) {
  .hero-content h1 {
    font-size: 2.5rem;
  }
  
  .cultural-intro h2,
  .cultural-heritage h2,
  .popular-experiences h2 {
    font-size: 2rem;
  }
  
  .heritage-card {
    flex: 0 0 100%;
  }
  
  .maasai-gallery {
    flex-wrap: nowrap;
    justify-content: flex-start;
    padding-bottom: 1rem;
  }
}

@media (max-width: 576px) {
  .hero-content h1 {
    font-size: 2rem;
  }
  
  .hero-content p {
    font-size: 1rem;
  }
  
  .cultural-intro,
  .cultural-heritage,
  .popular-experiences {
    padding: 3rem 1rem;
  }
} 