.enquiry-page {
  min-height: 100vh;
  padding: 4rem 2rem;
  position: relative;
  background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: calc(150px + 6rem);
  margin-top: 80px;
}

.enquiry-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('/public/images/tripBanner.jpeg');
  background-size: cover;
  background-position: center;
  opacity: 0.3;
  z-index: 1;
}

.logo-container {
  position: fixed;
  top: calc(80px + 2rem);
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
  margin-bottom: 0;
  background: rgba(255, 255, 255, 0.9);
  padding: 1rem;
  border-radius: 50%;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(10px);
  transition: all 0.3s ease;
}

.logo-container:hover {
  transform: translateX(-50%) scale(1.05);
}

.circle-avatar {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  transition: transform 0.3s ease;
}

.circle-avatar:hover {
  transform: scale(1.05);
}

.logo-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 50%;
}

.enquiry-container {
  position: relative;
  z-index: 2;
  background: rgba(255, 255, 255, 0.95);
  padding: 3rem;
  border-radius: 20px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 800px;
  backdrop-filter: blur(10px);
  animation: fadeIn 0.5s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.enquiry-container h1 {
  text-align: center;
  color: #2c3e50;
  margin-bottom: 0.5rem;
  font-size: 2.5rem;
  font-weight: 700;
}

.subtitle {
  text-align: center;
  color: #6c757d;
  margin-bottom: 2rem;
  font-size: 1.1rem;
}

.enquiry-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.form-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  width: 100%;
}

.form-group.half {
  margin: 0;
}

.form-group select {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1em;
  padding-right: 2.5rem;
}

.form-group select:hover {
  border-color: #3498db;
}

.form-group label .icon.fa-dollar-sign,
.form-group label .icon.fa-hotel {
  font-size: 1.1em;
}

.form-group label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #2c3e50;
  font-weight: 500;
}

.icon {
  color: #3498db;
}

.form-group input,
.form-group select,
.form-group textarea,
.date-picker {
  padding: 0.8rem 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.3s ease;
  background: white;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus,
.date-picker:focus {
  border-color: #3498db;
  box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
  outline: none;
}

.form-group textarea {
  resize: vertical;
  min-height: 120px;
}

.submit-button {
  background: #3498db;
  color: white;
  padding: 1rem 2rem;
  border: none;
  border-radius: 8px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 1rem;
}

.submit-button:hover {
  background: #2980b9;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(52, 152, 219, 0.2);
}

.submit-button:active {
  transform: translateY(0);
}

@media (max-width: 768px) {
  .enquiry-page {
    padding: 2rem 1rem;
    padding-top: calc(100px + 5rem);
    margin-top: 60px;
  }

  .logo-container {
    top: calc(60px + 1rem);
  }

  .circle-avatar {
    width: 90px;
    height: 90px;
  }

  .enquiry-container h1 {
    font-size: 2rem;
  }

  .form-row {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .enquiry-page {
    padding-top: calc(80px + 4rem);
    margin-top: 50px;
  }

  .logo-container {
    top: calc(50px + 0.5rem);
  }

  .circle-avatar {
    width: 70px;
    height: 70px;
    padding: 0.5rem;
  }

  .enquiry-container h1 {
    font-size: 1.8rem;
  }

  .submit-button {
    padding: 0.8rem 1.5rem;
  }
}

.readonly-input {
  background-color: #f8f9fa;
  border: 1px solid #e0e0e0;
  color: #495057;
  cursor: not-allowed;
  opacity: 0.9;
  padding: 0.8rem 1rem;
  border-radius: 8px;
  font-size: 1rem;
  width: 100%;
  font-weight: 500;
  position: relative;
}

.readonly-input:focus {
  outline: none;
  box-shadow: none;
  border-color: #e0e0e0;
}

.readonly-wrapper {
  position: relative;
  width: 100%;
}

.readonly-tooltip {
  visibility: hidden;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: calc(100% + 10px);
  background: #e74c3c;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  font-size: 0.9rem;
  white-space: nowrap;
  z-index: 10;
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.readonly-tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: #e74c3c transparent transparent transparent;
}

.readonly-wrapper:hover .readonly-tooltip,
.readonly-wrapper:focus-within .readonly-tooltip {
  visibility: visible;
  opacity: 1;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;
  animation: fadeIn 0.3s ease-out;
}

.congrats-modal, .circuits-modal {
  background: white;
  border-radius: 20px;
  padding: 2rem;
  max-width: 90%;
  width: 600px;
  position: relative;
  animation: slideIn 0.5s ease-out;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.3);
}

.circuits-modal {
  width: 900px;
  max-height: 90vh;
  overflow-y: auto;
}

.modal-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #666;
  cursor: pointer;
  transition: all 0.3s;
  padding: 0.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-close:hover {
  background: #f0f0f0;
  transform: rotate(90deg);
}

.congrats-content {
  text-align: center;
  padding: 1rem;
}

.congrats-icon {
  font-size: 4rem;
  margin-bottom: 1rem;
  animation: bounce 1s ease infinite;
}

.congrats-content h2 {
  color: #2c3e50;
  margin-bottom: 1rem;
  font-size: 2rem;
}

.congrats-content p {
  color: #666;
  line-height: 1.6;
  margin-bottom: 1.5rem;
}

.explore-btn {
  background: linear-gradient(135deg, #3498db 0%, #2980b9 100%);
  color: white;
  border: none;
  padding: 1rem 2rem;
  border-radius: 50px;
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  box-shadow: 0 4px 15px rgba(52, 152, 219, 0.3);
}

.explore-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(52, 152, 219, 0.4);
}

.circuits-content {
  text-align: center;
  padding: 1rem;
}

.circuits-content h2 {
  color: #2c3e50;
  margin-bottom: 0.5rem;
  font-size: 2rem;
}

.circuits-content > p {
  color: #666;
  margin-bottom: 2rem;
}

.circuits-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  padding: 1rem;
}

.circuit-card {
  background: white;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.3s;
  position: relative;
}

.circuit-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
}

.circuit-image {
  height: 200px;
  overflow: hidden;
}

.circuit-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s;
}

.circuit-card:hover .circuit-image img {
  transform: scale(1.1);
}

.circuit-info {
  padding: 1.5rem;
  position: relative;
}

.circuit-info h3 {
  color: #2c3e50;
  margin-bottom: 0.5rem;
  font-size: 1.3rem;
}

.circuit-info p {
  color: #666;
  font-size: 0.95rem;
  line-height: 1.5;
}

.circuit-icon {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  color: #3498db;
  font-size: 1.5rem;
  opacity: 0.8;
  transition: all 0.3s;
}

.circuit-card:hover .circuit-icon {
  transform: translateX(5px);
  opacity: 1;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@media (max-width: 768px) {
  .circuits-grid {
    grid-template-columns: 1fr;
  }
  
  .congrats-modal, .circuits-modal {
    width: 95%;
    padding: 1.5rem;
  }
  
  .congrats-content h2,
  .circuits-content h2 {
    font-size: 1.5rem;
  }
  
  .explore-btn {
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
  }
}

.phone-input-container {
  position: relative;
  width: 100%;
}

.tooltip-icon {
  display: inline-flex;
  align-items: center;
  margin-left: 0.5rem;
  position: relative;
}

.info-icon {
  color: #3498db;
  font-size: 0.9rem;
  cursor: help;
}

.tooltip-text {
  visibility: hidden;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 100%;
  background: #2c3e50;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  font-size: 0.9rem;
  white-space: nowrap;
  z-index: 10;
  opacity: 0;
  transition: opacity 0.3s ease;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  margin-bottom: 5px;
}

.tooltip-text::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: #2c3e50 transparent transparent transparent;
}

.tooltip-icon:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.phone-input-container input {
  padding-left: 1rem;
  letter-spacing: 0.5px;
  font-family: monospace;
}

.phone-input-container input:invalid {
  border-color: #e74c3c;
}

.phone-input-container input:invalid:focus {
  border-color: #e74c3c;
  box-shadow: 0 0 0 2px rgba(231, 76, 60, 0.2);
}

.subject-input-group {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.subject-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.generate-subject-btn {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.4rem 0.8rem;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.3rem;
  z-index: 2;
}

.generate-subject-btn:hover {
  background: #2980b9;
  transform: translateY(-50%) scale(1.05);
}

.generate-subject-btn .icon {
  font-size: 0.8rem;
  color: white;
}

.subject-suggestions-dropdown {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  right: 0;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  z-index: 100;
  max-height: 200px;
  overflow-y: auto;
  animation: slideDown 0.3s ease-out;
}

.subject-suggestion-item {
  padding: 0.8rem 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
  border-bottom: 1px solid #f0f0f0;
}

.subject-suggestion-item:last-child {
  border-bottom: none;
}

.subject-suggestion-item:hover {
  background: #f8f9fa;
  padding-left: 1.2rem;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Adjust input padding for generate button */
.subject-input-group input {
  padding-right: 100px;
}

.suggested-input {
  border-left: 4px solid #3498db !important;
  background-color: #f8f9fa !important;
  padding-left: 1.2rem !important;
}

.suggested-input::placeholder {
  color: #3498db !important;
  font-style: italic;
}

.tooltip-text {
  max-width: 300px;
  white-space: normal;
  text-align: center;
  line-height: 1.4;
}
