/* Basic styling for Experience page */
.experience-page {
  background: linear-gradient(to bottom, #f8fafc, #ffffff);
}

/* Hero Section */
.hero-section {
  position: relative;
  min-height: 60vh;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
              url('/public/images/tripBanner.jpeg') center/cover no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  padding: 6rem 2rem;
  overflow: hidden;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5));
}

.hero-content {
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-bottom: 6rem;
}

.hero-content h1 {
  font-size: 4rem;
  font-weight: 800;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  animation: fadeInUp 1s ease-out;
}

.hero-content p {
  font-size: 1.5rem;
  line-height: 1.6;
  margin-bottom: 1rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  animation: fadeInUp 1s ease-out 0.2s backwards;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

/* Featured Experience Section */
.featured-experience {
  padding: 6rem 2rem;
  background: white;
}

.featured-content {
  max-width: 1200px;
  margin: 0 auto;
}

.featured-content h2 {
  font-size: 2.8rem;
  text-align: center;
  margin-bottom: 4rem;
  color: #1a2b3c;
  font-weight: 800;
  background: linear-gradient(135deg, #1a2b3c 0%, #2c3e50 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 3rem;
}

.feature {
  text-align: center;
  padding: 2rem;
  background: white;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.feature:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.15);
}

.feature-icon {
  font-size: 3rem;
  margin-bottom: 1.5rem;
  display: inline-block;
  padding: 1.5rem;
  background: linear-gradient(135deg, #f0f9ff 0%, #e6f7ff 100%);
  border-radius: 50%;
}

.feature h3 {
  font-size: 1.5rem;
  color: #1a2b3c;
  margin-bottom: 1rem;
}

.feature p {
  color: #64748b;
  line-height: 1.6;
}

/* Experiences Section */
.experiences-section {
  padding: 6rem 2rem;
  background: linear-gradient(135deg, #f8fafc 0%, #f1f5f9 100%);
}

.experiences-section h2 {
  font-size: 2.8rem;
  text-align: center;
  margin-bottom: 3rem;
  color: #1a2b3c;
  font-weight: 800;
}

.experiences-section h2::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 4px;
  background: linear-gradient(to right, #4CAF50, #45a049);
  border-radius: 2px;
}

.experiences-grid {
  display: flex;
  gap: 2rem;
  max-width: 100%;
  margin: 0 auto;
  overflow-x: auto;
  padding: 2rem 1rem;
  scroll-snap-type: x mandatory;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.experiences-grid::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.experience-card {
  min-width: 380px;
  scroll-snap-align: start;
  background: white;
  border-radius: 25px;
  overflow: hidden;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  isolation: isolate;
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
}

.experience-card:hover {
  transform: translateY(-15px) scale(1.02);
  box-shadow: 0 30px 60px rgba(0, 0, 0, 0.15);
}

.card-image {
  height: 300px;
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
}

.card-image::before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(
    to bottom,
    transparent 0%,
    transparent 50%,
    rgba(0, 0, 0, 0.8) 100%
  );
  opacity: 0;
  transition: opacity 0.5s ease;
}

.experience-card:hover .card-image::before {
  opacity: 1;
}

.card-overlay {
  position: absolute;
  inset: 0;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0.4) 50%,
    transparent 100%
  );
  display: flex;
  align-items: flex-end;
  padding: 2rem;
  transform: translateY(20%);
  opacity: 0;
  transition: all 0.5s ease;
}

.experience-card:hover .card-overlay {
  transform: translateY(0);
  opacity: 1;
}

.card-icon {
  font-size: 3rem;
  color: white;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
  transform: scale(0.8);
  transition: transform 0.5s ease;
}

.experience-card:hover .card-icon {
  transform: scale(1.1);
}

.card-quick-info {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  transform: translateX(100%);
  transition: transform 0.5s ease;
}

.experience-card:hover .card-quick-info {
  transform: translateX(0);
}

.card-quick-info span {
  background: rgba(255, 255, 255, 0.95);
  padding: 0.8rem 1.2rem;
  border-radius: 50px;
  font-size: 0.9rem;
  color: #1a2b3c;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.card-quick-info span:hover {
  transform: translateX(-5px);
  background: #4CAF50;
  color: white;
}

.card-content {
  padding: 2.5rem;
  position: relative;
}

.card-content::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  height: 3px;
  background: linear-gradient(90deg, transparent, #4CAF50, transparent);
  opacity: 0;
  transition: all 0.5s ease;
}

.experience-card:hover .card-content::before {
  opacity: 1;
  width: 80%;
}

.card-content h3 {
  font-size: 1.8rem;
  color: #1a2b3c;
  margin-bottom: 1rem;
  font-weight: 700;
  background: linear-gradient(135deg, #1a2b3c 0%, #2c3e50 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  transform: translateY(10px);
  transition: transform 0.5s ease;
}

.experience-card:hover .card-content h3 {
  transform: translateY(0);
}

.rating {
  color: #ffd700;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  display: flex;
  gap: 0.2rem;
}

.rating span {
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.3s ease;
}

.experience-card:hover .rating span {
  opacity: 1;
  transform: translateY(0);
}

.rating span:nth-child(1) { transition-delay: 0.1s; }
.rating span:nth-child(2) { transition-delay: 0.2s; }
.rating span:nth-child(3) { transition-delay: 0.3s; }
.rating span:nth-child(4) { transition-delay: 0.4s; }
.rating span:nth-child(5) { transition-delay: 0.5s; }

.card-content p {
  color: #64748b;
  line-height: 1.8;
  margin-bottom: 1.5rem;
  font-size: 1.1rem;
  opacity: 0.9;
  transform: translateY(10px);
  transition: all 0.5s ease;
}

.experience-card:hover .card-content p {
  transform: translateY(0);
}

.toggle-details {
  width: 100%;
  padding: 1rem;
  border: none;
  background: linear-gradient(135deg, #f8fafc 0%, #f1f5f9 100%);
  color: #4CAF50;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  font-weight: 600;
  font-size: 1rem;
  border-radius: 12px;
  transition: all 0.3s ease;
  margin-top: 1.5rem;
  position: relative;
  overflow: hidden;
}

.toggle-details::before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(45deg, #4CAF50, #45a049);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.toggle-details:hover::before {
  opacity: 1;
}

.toggle-details span {
  position: relative;
  z-index: 1;
  transition: all 0.3s ease;
}

.toggle-details:hover {
  color: white;
  transform: translateY(-2px);
  box-shadow: 0 8px 20px rgba(76, 175, 80, 0.2);
}

.toggle-details svg {
  transition: transform 0.3s ease;
}

.toggle-details:hover svg {
  transform: translateY(2px);
}

/* Expanded Card Content */
.expanded-content {
  margin-top: 2.5rem;
  padding-top: 2.5rem;
  border-top: 2px solid #f1f5f9;
  animation: expandContent 0.5s ease-out;
}

.highlights-section,
.included-section,
.schedule-section,
.pricing-section {
  margin-bottom: 2.5rem;
}

.highlights-section h4,
.included-section h4,
.schedule-section h4,
.pricing-section h4 {
  color: #1a2b3c;
  margin-bottom: 1.5rem;
  font-size: 1.4rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.highlights-section ul,
.included-section ul {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
}

.highlights-section li,
.included-section li {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  padding: 1rem;
  background: #f8fafc;
  border-radius: 12px;
  transition: all 0.3s ease;
}

.highlights-section li:hover,
.included-section li:hover {
  background: #f0fdf4;
  transform: translateX(10px);
}

.schedule-item {
  display: flex;
  gap: 1.5rem;
  margin-bottom: 1rem;
  padding: 1.2rem;
  background: #f8fafc;
  border-radius: 12px;
  transition: all 0.3s ease;
}

.schedule-item:hover {
  background: #f0fdf4;
  transform: translateX(10px);
}

.schedule-item .time {
  font-weight: 700;
  color: #4CAF50;
  min-width: 120px;
}

.price-option {
  background: #f8fafc;
  padding: 1.5rem;
  border-radius: 15px;
  margin-bottom: 1.5rem;
  transition: all 0.3s ease;
}

.price-option:hover {
  background: #f0fdf4;
  transform: translateX(10px);
}

.option-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.option-header h5 {
  font-size: 1.2rem;
  color: #1a2b3c;
  font-weight: 700;
}

.option-header .price {
  font-size: 1.4rem;
  font-weight: 700;
  color: #4CAF50;
}

.booking-actions {
  display: flex;
  gap: 1.5rem;
  margin-top: 2.5rem;
}

.book-now,
.inquire {
  flex: 1;
  padding: 1.2rem;
  border-radius: 12px;
  font-weight: 600;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
}

.book-now {
  background: linear-gradient(45deg, #4CAF50, #45a049);
  color: white;
  border: none;
  box-shadow: 0 8px 20px rgba(76, 175, 80, 0.2);
}

.book-now:hover {
  transform: translateY(-3px);
  box-shadow: 0 12px 30px rgba(76, 175, 80, 0.3);
}

.inquire {
  background: white;
  color: #4CAF50;
  border: 2px solid #4CAF50;
}

.inquire:hover {
  background: #f0fdf4;
  transform: translateY(-3px);
  box-shadow: 0 8px 20px rgba(76, 175, 80, 0.1);
}

/* Testimonials Section */
.testimonials {
  padding: 6rem 2rem;
  background: white;
}

.testimonials h2 {
  font-size: 2.8rem;
  text-align: center;
  margin-bottom: 4rem;
  color: #1a2b3c;
  font-weight: 800;
}

.testimonials-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 3rem;
  max-width: 1200px;
  margin: 0 auto;
}

.testimonial-card {
  background: white;
  border-radius: 20px;
  padding: 2rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.testimonial-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.15);
}

.testimonial-content {
  position: relative;
}

.testimonial-content::before {
  content: '"';
  font-size: 5rem;
  color: #4CAF50;
  opacity: 0.2;
  position: absolute;
  top: -2rem;
  left: -1rem;
}

.rating {
  color: #ffd700;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.testimonial-content p {
  color: #1a2b3c;
  line-height: 1.8;
  margin-bottom: 2rem;
  font-style: italic;
}

.testimonial-author {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.testimonial-author img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}

.testimonial-author h4 {
  color: #1a2b3c;
  margin-bottom: 0.2rem;
}

.testimonial-author p {
  color: #64748b;
  font-size: 0.9rem;
}

.experience-date {
  color: #4CAF50;
  font-size: 0.9rem;
  display: block;
  margin-top: 0.5rem;
}

/* CTA Section */
.cta-section {
  position: relative;
  padding: 6rem 2rem;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
              url('https://images.unsplash.com/photo-1516426122078-c23e76319801?auto=format&fit=crop&w=1920') center/cover no-repeat fixed;
  color: white;
  text-align: center;
}

.cta-content {
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

.cta-content h2 {
  font-size: 3rem;
  margin-bottom: 1.5rem;
  font-weight: 800;
}

.cta-content p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  opacity: 0.9;
}

.cta-buttons {
  display: flex;
  gap: 1.5rem;
  justify-content: center;
}

.cta-button {
  padding: 1rem 2.5rem;
  border-radius: 50px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.cta-button.primary {
  background: #4CAF50;
  color: white;
  border: none;
}

.cta-button.primary:hover {
  background: #45a049;
  transform: translateY(-3px);
}

.cta-button.secondary {
  background: transparent;
  color: white;
  border: 2px solid white;
}

.cta-button.secondary:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: translateY(-3px);
}

/* Animations */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero-content h1 {
    font-size: 2.5rem;
  }
  
  .hero-content p {
    font-size: 1.2rem;
  }
  
  .hero-stats {
    flex-direction: column;
    gap: 1rem;
  }
  
  .stat-item {
    width: 100%;
  }
  
  .featured-content h2,
  .experiences-section h2,
  .fun-facts h2,
  .kids-corner h2,
  .interactive-map h2,
  .photo-gallery h2,
  .cta-content h2 {
    font-size: 2rem;
  }
  
  .filter-btn {
    padding: 0.8rem 1.5rem;
    font-size: 0.9rem;
  }
  
  .experiences-grid,
  .facts-container,
  .kids-activities,
  .gallery-container {
    grid-template-columns: 1fr;
  }
  
  .cta-buttons {
    flex-direction: column;
  }
  
  .cta-button {
    width: 100%;
  }
}

/* Hero Stats */
.hero-stats {
  display: flex;
  justify-content: center;
  gap: 4rem;
  margin-top: 4rem;
  padding: 2rem;
  position: relative;
  z-index: 3;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.stat-item {
  text-align: center;
  padding: 1.5rem 2rem;
  border-radius: 15px;
  transition: transform 0.3s ease;
  flex: 1;
  min-width: 200px;
  position: relative;
}

.stat-item::after {
  content: '';
  position: absolute;
  right: -2rem;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 60%;
  background: rgba(255, 255, 255, 0.2);
}

.stat-item:last-child::after {
  display: none;
}

.stat-item:hover {
  transform: translateY(-5px);
}

.stat-number {
  font-size: 2.8rem;
  font-weight: 800;
  color: #ffffff;
  margin-bottom: 0.5rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  background: linear-gradient(135deg, #ffffff 0%, #e0e0e0 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.stat-label {
  font-size: 1.1rem;
  color: rgba(255, 255, 255, 0.9);
  font-weight: 500;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

@media (max-width: 1200px) {
  .hero-stats {
    gap: 2rem;
    padding: 1.5rem;
  }

  .stat-item {
    padding: 1rem;
    min-width: 150px;
  }

  .stat-number {
    font-size: 2.2rem;
  }

  .stat-label {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .hero-stats {
    flex-direction: column;
    gap: 1rem;
    margin-top: 3rem;
    padding: 1rem;
    max-width: 90%;
  }

  .stat-item {
    width: 100%;
    padding: 1rem;
  }

  .stat-item::after {
    display: none;
  }

  .stat-number {
    font-size: 2rem;
  }
}

/* Category Filter */
.category-filter {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 3rem;
  flex-wrap: wrap;
}

.filter-btn {
  padding: 1rem 2rem;
  border: none;
  border-radius: 50px;
  background: white;
  color: #64748b;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
}

.filter-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
}

.filter-btn.active {
  background: #4CAF50;
  color: white;
}

/* Expanded Card Content */
.experience-card.expanded {
  grid-column: span 2;
}

.expanded-content {
  margin-top: 2rem;
  padding-top: 2rem;
  border-top: 1px solid #eee;
  animation: expandContent 0.3s ease-out;
}

@keyframes expandContent {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.included-section,
.schedule-section,
.pricing-section {
  margin-bottom: 2rem;
}

.included-section h4,
.schedule-section h4,
.pricing-section h4 {
  color: #333;
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

.schedule-item {
  display: flex;
  gap: 1rem;
  margin-bottom: 0.8rem;
  padding: 0.5rem;
  background: #f8f9fa;
  border-radius: 5px;
}

.schedule-item .time {
  font-weight: 600;
  color: #4CAF50;
  min-width: 100px;
}

.price-option {
  background: #f8f9fa;
  padding: 1rem;
  border-radius: 10px;
  margin-bottom: 1rem;
}

.option-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.option-header h5 {
  margin: 0;
  color: #333;
}

.option-header .price {
  font-weight: 600;
  color: #4CAF50;
}

.booking-actions {
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
}

.book-now,
.inquire {
  flex: 1;
  padding: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.book-now {
  background: #4CAF50;
  color: white;
}

.book-now:hover {
  background: #45a049;
  transform: translateY(-2px);
}

.inquire {
  background: white;
  color: #4CAF50;
  border: 2px solid #4CAF50;
}

.inquire:hover {
  background: #f0f8f0;
  transform: translateY(-2px);
}

.toggle-details {
  width: 100%;
  padding: 0.8rem;
  margin-top: 1rem;
  border: none;
  background: transparent;
  color: #4CAF50;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-weight: 500;
  transition: all 0.3s ease;
}

.toggle-details:hover {
  color: #45a049;
  background: #f0f8f0;
}

/* Rating Stars */
.rating {
  color: #ffd700;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

/* CTA Buttons */
.cta-buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 2rem;
}

.cta-button.primary {
  background: #4CAF50;
  color: white;
}

.cta-button.secondary {
  background: transparent;
  color: white;
  border: 2px solid white;
}

.cta-button.secondary:hover {
  background: rgba(255, 255, 255, 0.1);
}

/* Responsive Design Updates */
@media (max-width: 768px) {
  .experience-card.expanded {
    grid-column: span 1;
  }
  
  .hero-stats {
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .booking-actions {
    flex-direction: column;
  }
  
  .card-quick-info {
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-start;
  }
}

/* Fun Facts Section */
.fun-facts {
  padding: 6rem 2rem;
  background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
  position: relative;
  overflow: hidden;
}

.fun-facts::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: linear-gradient(135deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0.2) 100%);
  opacity: 0.05;
  z-index: 1;
}

.fun-facts h2 {
  position: relative;
  z-index: 2;
  text-align: center;
  font-size: 2.8rem;
  margin-bottom: 4rem;
  color: #1a2b3c;
  font-weight: 800;
}

.facts-container {
  position: relative;
  z-index: 2;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 3rem;
  max-width: 1400px;
  margin: 0 auto;
}

.fact-card {
  background: white;
  padding: 3rem 2rem;
  border-radius: 20px;
  text-align: center;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.fact-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(90deg, #4CAF50, #45a049);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.fact-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
}

.fact-card:hover::before {
  opacity: 1;
}

.fact-icon {
  font-size: 4rem;
  margin-bottom: 2rem;
  display: inline-block;
  position: relative;
}

.fact-icon::after {
  content: '';
  position: absolute;
  width: 80px;
  height: 80px;
  background: rgba(76, 175, 80, 0.1);
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.fact-card h3 {
  color: #4CAF50;
  margin-bottom: 1rem;
  font-size: 1.8rem;
  font-weight: 700;
}

.fact-card p {
  color: #64748b;
  line-height: 1.8;
  font-size: 1.1rem;
}

/* Kids Corner Section */
.kids-corner {
  padding: 6rem 2rem;
  background: white;
  position: relative;
  overflow: hidden;
}

.kids-activities-container {
  position: relative;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 40px;
}

.kids-activities {
  display: flex;
  gap: 2rem;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding: 2rem 0;
}

.kids-activities::-webkit-scrollbar {
  display: none;
}

.activity-card {
  min-width: 350px;
  scroll-snap-align: start;
  background: white;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  border: 1px solid rgba(76, 175, 80, 0.1);
}

.activity-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
  border-color: rgba(76, 175, 80, 0.3);
}

.activity-card img {
  width: 100%;
  height: 220px;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.activity-card:hover img {
  transform: scale(1.1);
}

.activity-content {
  padding: 2rem;
}

.activity-content h3 {
  color: #1a2b3c;
  font-size: 1.8rem;
  margin-bottom: 1rem;
  font-weight: 700;
  background: linear-gradient(135deg, #1a2b3c 0%, #2c3e50 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.activity-content p {
  color: #64748b;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  font-size: 1.1rem;
}

.activity-features {
  list-style: none;
  padding: 0;
}

.activity-features li {
  color: #1a2b3c;
  margin-bottom: 0.8rem;
  padding: 0.8rem 1.2rem;
  background: #f8fafc;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 1rem;
  transition: all 0.3s ease;
  font-size: 1rem;
}

.activity-features li:hover {
  transform: translateX(10px);
  background: #f0fdf4;
  color: #4CAF50;
}

.kids-corner .scroll-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: white;
  border: none;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4CAF50;
  font-size: 1.2rem;
  transition: all 0.3s ease;
}

.kids-corner .scroll-button:hover {
  background: #4CAF50;
  color: white;
  transform: translateY(-50%) scale(1.1);
}

.kids-corner .scroll-left {
  left: 0;
}

.kids-corner .scroll-right {
  right: 0;
}

@media (max-width: 768px) {
  .kids-corner .scroll-button {
    display: none;
  }
  
  .kids-activities-container {
    padding: 0;
  }
  
  .activity-card {
    min-width: 300px;
  }
}

/* Photo Gallery Section */
.photo-gallery {
  padding: 6rem 2rem;
  background: linear-gradient(135deg, #ffffff 0%, #f8fafc 100%);
  position: relative;
  overflow: hidden;
}

.photo-gallery::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 500px;
  background: url('https://images.unsplash.com/photo-1516426122078-c23e76319801?auto=format&fit=crop&w=800') center/contain no-repeat;
  opacity: 0.03;
  pointer-events: none;
  z-index: 1;
  filter: grayscale(100%);
}

.photo-gallery > * {
  position: relative;
  z-index: 2;
}

.photo-gallery h2 {
  text-align: center;
  font-size: 2.8rem;
  margin-bottom: 1rem;
  color: #1a2b3c;
  font-weight: 800;
  background: linear-gradient(135deg, #1a2b3c 0%, #2c3e50 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gallery-subtitle {
  text-align: center;
  color: #64748b;
  font-size: 1.2rem;
  margin-bottom: 3rem;
}

.gallery-categories {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 3rem;
  flex-wrap: wrap;
}

.gallery-category-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 50px;
  background: white;
  color: #64748b;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
}

.gallery-category-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
}

.gallery-category-btn.active {
  background: #4CAF50;
  color: white;
}

.gallery-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  max-width: 1400px;
  margin: 0 auto;
}

.gallery-item {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  aspect-ratio: 1;
  cursor: pointer;
  transform-origin: center;
  transition: all 0.3s ease;
}

.gallery-item:hover {
  transform: scale(1.02);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
}

.gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.gallery-item:hover img {
  transform: scale(1.1);
}

.gallery-overlay {
  position: absolute;
  inset: 0;
  background: linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.4) 50%, transparent 100%);
  padding: 2rem;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  opacity: 0;
  transition: all 0.3s ease;
}

.gallery-item:hover .gallery-overlay {
  opacity: 1;
}

.gallery-overlay h3 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  transform: translateY(20px);
  transition: transform 0.3s ease;
}

.gallery-item:hover .gallery-overlay h3 {
  transform: translateY(0);
}

.gallery-overlay p {
  opacity: 0.9;
  font-size: 1rem;
  margin-bottom: 1rem;
  transform: translateY(20px);
  transition: transform 0.3s ease 0.1s;
}

.gallery-item:hover .gallery-overlay p {
  transform: translateY(0);
}

.gallery-meta {
  display: flex;
  gap: 1rem;
  font-size: 0.9rem;
  opacity: 0.8;
  transform: translateY(20px);
  transition: transform 0.3s ease 0.2s;
}

.gallery-item:hover .gallery-meta {
  transform: translateY(0);
}

.gallery-meta span {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

/* Gallery Modal */
.gallery-modal {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 2rem;
  opacity: 0;
  animation: modalFadeIn 0.3s ease forwards;
}

.modal-content {
  position: relative;
  max-width: 90vw;
  max-height: 90vh;
  background: white;
  border-radius: 20px;
  overflow: hidden;
  transform: scale(0.9);
  animation: modalScaleIn 0.3s ease forwards;
}

.modal-content img {
  max-width: 100%;
  max-height: 70vh;
  object-fit: contain;
}

.modal-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  background: rgba(255, 255, 255, 0.2);
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.modal-close:hover {
  background: rgba(255, 255, 255, 0.3);
  transform: rotate(90deg);
}

.modal-info {
  padding: 2rem;
  background: white;
}

.modal-info h3 {
  font-size: 1.8rem;
  color: #1a2b3c;
  margin-bottom: 0.5rem;
}

.modal-info p {
  color: #64748b;
  margin-bottom: 1rem;
}

.modal-meta {
  display: flex;
  gap: 2rem;
  color: #64748b;
}

.modal-meta span {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes modalScaleIn {
  from {
    transform: scale(0.9);
  }
  to {
    transform: scale(1);
  }
}

/* Responsive Design for Gallery */
@media (max-width: 768px) {
  .gallery-container {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1rem;
  }

  .gallery-category-btn {
    padding: 0.6rem 1rem;
    font-size: 0.9rem;
  }

  .modal-content {
    width: 95vw;
  }

  .modal-info {
    padding: 1.5rem;
  }

  .modal-info h3 {
    font-size: 1.5rem;
  }

  .modal-meta {
    flex-direction: column;
    gap: 1rem;
  }
}

/* Add smooth transitions for gallery items */
.gallery-item {
  animation: galleryItemAppear 0.6s ease-out forwards;
  opacity: 0;
}

@keyframes galleryItemAppear {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.gallery-container > div:nth-child(1) { animation-delay: 0.1s; }
.gallery-container > div:nth-child(2) { animation-delay: 0.2s; }
.gallery-container > div:nth-child(3) { animation-delay: 0.3s; }
.gallery-container > div:nth-child(4) { animation-delay: 0.4s; }
.gallery-container > div:nth-child(5) { animation-delay: 0.5s; }
.gallery-container > div:nth-child(6) { animation-delay: 0.6s; }

/* Add scroll buttons */
.experiences-container {
  position: relative;
  max-width: 1400px;
  margin: 0 auto;
}

.scroll-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: white;
  border: none;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4CAF50;
  font-size: 1.5rem;
  transition: all 0.3s ease;
}

.scroll-button:hover {
  background: #4CAF50;
  color: white;
  transform: translateY(-50%) scale(1.1);
}

.scroll-left {
  left: -25px;
}

.scroll-right {
  right: -25px;
}

@media (max-width: 768px) {
  .experience-card {
    min-width: 300px;
  }
  
  .scroll-button {
    display: none;
  }
}

/* Stats Section */
.stats-section {
  padding: 4rem 2rem;
  background: linear-gradient(135deg, #f8fafc 0%, #f1f5f9 100%);
  position: relative;
  z-index: 2;
  margin-top: -4rem;
}

.stats-container {
  display: flex;
  justify-content: center;
  gap: 4rem;
  max-width: 1200px;
  margin: 0 auto;
  background: white;
  padding: 3rem 2rem;
  border-radius: 20px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  position: relative;
}

.stats-container .stat-item {
  text-align: center;
  padding: 1.5rem 2rem;
  border-radius: 15px;
  transition: transform 0.3s ease;
  flex: 1;
  min-width: 200px;
  position: relative;
}

.stats-container .stat-item::after {
  content: '';
  position: absolute;
  right: -2rem;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 60%;
  background: rgba(0, 0, 0, 0.1);
}

.stats-container .stat-item:last-child::after {
  display: none;
}

.stats-container .stat-item:hover {
  transform: translateY(-5px);
}

.stats-container .stat-number {
  font-size: 2.8rem;
  font-weight: 800;
  color: #1a2b3c;
  margin-bottom: 0.5rem;
  background: linear-gradient(135deg, #4CAF50 0%, #45a049 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.stats-container .stat-label {
  font-size: 1.1rem;
  color: #64748b;
  font-weight: 500;
}

@media (max-width: 1200px) {
  .stats-container {
    gap: 2rem;
    padding: 2rem;
  }

  .stats-container .stat-item {
    padding: 1rem;
    min-width: 150px;
  }

  .stats-container .stat-number {
    font-size: 2.2rem;
  }

  .stats-container .stat-label {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .stats-section {
    margin-top: -2rem;
    padding: 2rem 1rem;
  }

  .stats-container {
    flex-direction: column;
    gap: 1rem;
    padding: 2rem 1rem;
  }

  .stats-container .stat-item {
    width: 100%;
    padding: 1rem;
  }

  .stats-container .stat-item::after {
    display: none;
  }

  .stats-container .stat-number {
    font-size: 2rem;
  }
}